import styled from 'styled-components';

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fbf9f4;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const TemplateHeaderFooterPlaceholder = () => (
  <StyledDiv>
    This is a placeholder only. When this template is used in a Horizon site, it
    will receive the custom header and footer defined in the site.
  </StyledDiv>
);

export default TemplateHeaderFooterPlaceholder;
