export const DEFAULT_SETTINGS = {
  hasLinkAttributes: false,
  isRichText: true,
  shouldPreserveNewLinesInMarkdown: true,
  showTableOfContents: false,
  tableCellBackgroundColor: true,
  tableCellMerge: true,
} as const;

export const INITIAL_SETTINGS: Record<SettingName, boolean> = {
  ...DEFAULT_SETTINGS,
};

export type SettingName = keyof typeof DEFAULT_SETTINGS;
