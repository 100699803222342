import {
  $createParagraphNode,
  ElementNode,
  LexicalNode,
  NodeKey,
  SerializedElementNode,
  Spread,
  DOMExportOutput,
  DOMConversionOutput,
  DOMConversionMap,
} from 'lexical';

/** The allowed Admonition types. */
export type AdmonitionType =
  | 'note'
  | 'info'
  | 'tip'
  | 'question'
  | 'danger'
  | 'warning';

const ALLOWED_ADMONITION_TYPES: AdmonitionType[] = [
  'note',
  'info',
  'tip',
  'question',
  'danger',
  'warning',
];

/** For serialization: what this node looks like in JSON. */
export type SerializedAdmonitionNode = Spread<
  {
    type: 'admonition';
    version: 1;
    admonitionType: AdmonitionType;
  },
  SerializedElementNode
>;

export class AdmonitionNode extends ElementNode {
  __admonitionType: AdmonitionType;

  static getType(): string {
    return 'admonition';
  }

  static clone(node: AdmonitionNode): AdmonitionNode {
    return new AdmonitionNode(node.__admonitionType, node.__key);
  }

  constructor(admonitionType: AdmonitionType, key?: NodeKey) {
    super(key);
    this.__admonitionType = admonitionType;
  }

  exportJSON(): SerializedAdmonitionNode {
    return {
      ...super.exportJSON(),
      type: 'admonition',
      version: 1,
      admonitionType: this.__admonitionType,
    };
  }

  static importJSON(serializedNode: SerializedAdmonitionNode): AdmonitionNode {
    const { admonitionType, children } = serializedNode;
    const node = $createAdmonitionNode(admonitionType);
    node.setFormat(serializedNode.format);
    node.setIndent(serializedNode.indent);
    node.setDirection(serializedNode.direction);
    const childrenNodes = children.map(() => $createParagraphNode());
    childrenNodes.forEach(child => node.append(child));
    return node;
  }

  createDOM(): HTMLElement {
    const element = document.createElement('div');
    element.setAttribute('class', `admonition ${this.__admonitionType}`);
    return element;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('div');
    element.setAttribute('class', `admonition ${this.__admonitionType}`);
    return { element };
  }

  updateDOM(prevNode: AdmonitionNode, dom: HTMLElement): boolean {
    if (prevNode.__admonitionType !== this.__admonitionType) {
      dom.className = `admonition ${this.__admonitionType}`;
    }
    // Return false because we don't need Lexical to do any deeper updates to the DOM
    return false;
  }

  static importDOM(): DOMConversionMap | null {
    return {
      div: (domNode: HTMLElement) => {
        if (!domNode.classList.contains('admonition')) {
          return null;
        }
        return {
          conversion: $convertAdmonitionElement,
          priority: 2,
        };
      },
    };
  }

  isEmpty(): boolean {
    const children = this.getChildren();
    if (children.length === 0) {
      return true;
    }
    if (children.length === 1) {
      const child = children[0];
      // If it’s a ParagraphNode with zero text, consider AdmonitionNode empty
      return child.getTextContent().length === 0;
    }
    return false;
  }
}

/** Helper to create a new AdmonitionNode. */
export function $createAdmonitionNode(admonitionType: AdmonitionType) {
  return new AdmonitionNode(admonitionType);
}

/** Helper to convert a DOM node into an AdmonitionNode. */
function $convertAdmonitionElement(
  domNode: HTMLElement
): null | DOMConversionOutput {
  let admonitionType = 'note';
  for (const c of domNode.classList) {
    if (ALLOWED_ADMONITION_TYPES.includes(c as AdmonitionType)) {
      admonitionType = c;
      break;
    }
  }
  const node = $createAdmonitionNode(admonitionType as AdmonitionType);
  return { node };
}

/** Type-guard helper. */
export function $isAdmonitionNode(
  node: LexicalNode | null | undefined
): node is AdmonitionNode {
  return node instanceof AdmonitionNode;
}
