import React from 'react';
import styled from 'styled-components';
import { textColor } from '../SiteStylesPanel/SiteStylesPanel';

const StyledDiv = styled.div`
  display: flex;
  padding: 0.5rem;
`;

const StyledBlock = styled.div<{ background: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background-color: ${p => p.background};
  height: 5rem;
`;

const StyledTitle = styled.div<{ textColor: string }>`
  color: ${p => p.textColor};
  font-family: OptumSans, sans-serif;
`;

type ColorPreviewProps = {
  theme: Theme;
};

const ColorPreview: React.FC<ColorPreviewProps> = ({ theme }) => {
  const colors = [
    theme.colors.custom[0],
    theme.colors.custom[1],
    theme.colors.custom[2],
    theme.colors.custom[3],
    theme.colors.custom[4],
  ];

  return (
    <StyledDiv data-test-id="parent-div">
      {colors.map((color, index) => (
        <StyledBlock key={index} background={color.value}>
          <StyledTitle textColor={textColor(color.value)}>
            {color.value}
          </StyledTitle>
        </StyledBlock>
      ))}
    </StyledDiv>
  );
};

export default ColorPreview;
