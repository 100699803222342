import React, { ChangeEvent, useEffect } from 'react';
import { Panel, Label, RadioButton, FormControl } from '@uitk/react';
import {
  Arrow,
  StyledPanelTitle,
} from 'builder/scenes/SiteBuilder/SettingsPanel/SettingsPanel';
import {
  Padding,
  PaddingData,
} from 'builder/components/WidgetConfigurations/Padding';
import {
  Border,
  BorderData,
} from 'builder/components/WidgetConfigurations/Border';
import { StyledPanel, SizeLocationPanel } from '../Text/TextWidgetControl';
import {
  SizeLocationData,
  WidgetSizeLocation,
} from 'builder/components/WidgetSizeLocation';
import { Config } from './types';
import { Input } from 'link-ui-react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

const StyledRadio = styled(RadioButton)`
  & > label {
    font-family: OptumSans, sans-serif;
  }
`;

const StyledInput = styled(Input)`
  font-family: OptumSans, sans-serif;
`;

const StyledURL = styled.i`
  font-family: OptumSans, sans-serif;
  padding: 0.25rem;
  font-size: 0.85rem;
`;

const PanelTop = styled.div`
  .jSWwIc {
    padding: 0;
    border: none;
  }
`;

const HtmlWidgetControl: React.FC<WidgetControlProps<Config>> = (
  props: WidgetControlProps<Config>
) => {
  const { value, onChange, widgetState } = props;
  const { inputUrl, urlPreview } = widgetState.config;
  const [checked, setChecked] = React.useState('web-address');

  useEffect(() => {}, [checked, props.widgetState]);

  const handleOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.value);
    if (checked === 'web-address') {
      onChange({
        ...value,
        inputUrl: '',
        urlPreview: '',
      });
    }
  };

  // const handleCodeChange = (editorName: string, editorValue: string) => {
  //   if (editorName === 'template') {
  //     onChange({
  //       ...value,
  //       htmlValue: editorValue,
  //     });
  //   }
  //   if (editorName === 'script') {
  //     onChange({
  //       ...value,
  //       jsValue: editorValue,
  //     });
  //   }
  //   if (editorName === 'stylesheet') {
  //     onChange({
  //       ...value,
  //       cssValue: editorValue,
  //     });
  //   }
  // };

  const handleUrlData = (e: ChangeEvent<HTMLInputElement>) => {
    const modifiedUrl = modifyUrlData(e.target.value);
    onChange({
      ...value,
      inputUrl: e.target.value,
      urlPreview: 'https://' + modifiedUrl,
    });
  };

  const modifyUrlData = (url: string) => {
    switch (url) {
      case 'google.com':
      case 'www.google.com':
        toast('Google URL was updated', {
          type: 'warning',
          theme: 'colored',
        });
        return 'google.com/webhp?igu=1';
      default:
        return url;
    }
  };

  const handleLineColor = data => {
    onChange({
      ...value,
      lineColor: data,
    });
  };
  const updateWidgetSizeLocation = (sizeLoc: SizeLocationData) => {
    onChange({
      ...value,
      width: sizeLoc.width,
      height: sizeLoc.height,
      xLocation: sizeLoc.xLocation,
      yLocation: sizeLoc.yLocation,
      uniqueId: sizeLoc.uniqueId,
    });
  };
  const updatePaddingData = (data: PaddingData) => {
    onChange({
      ...value,
      paddingData: data,
    });
  };
  const updateBorderData = (data: BorderData) => {
    onChange({
      ...value,
      borderData: data,
    });
  };

  return (
    <Panel.Group>
      <StyledPanel>
        <Panel
          title={<Label style={StyledPanelTitle}>General</Label>}
          icon={Arrow}
          data-test-id="general-panel"
        >
          <PanelTop>
            <FormControl id={'radio-group-controlled'}>
              <StyledRadio
                data-test-id="radio-url"
                name={'radio-group-controlled'}
                value={'web-address'}
                onChange={handleOptionChange}
                checked={checked === 'web-address'}
              >
                Web Address
              </StyledRadio>
              <StyledRadio
                data-test-id="radio-snippet"
                name={'radio-group-controlled'}
                value={'snippet'}
                onChange={handleOptionChange}
                checked={checked === 'snippet'}
                disabled={true}
              >
                Code Snippet
              </StyledRadio>
            </FormControl>
            {checked === 'web-address' && (
              <>
                <StyledInput
                  data-test-id="url-input"
                  type="url"
                  value={inputUrl}
                  onChange={handleUrlData}
                />
                <StyledURL>{urlPreview ? urlPreview : 'https://'}</StyledURL>
              </>
            )}
          </PanelTop>
        </Panel>
      </StyledPanel>
      <StyledPanel>
        <SizeLocationPanel
          title={<Label style={StyledPanelTitle}>Size and Location</Label>}
          initiallyClosed
          icon={Arrow}
          data-test-id="html-size-location-panel"
        >
          <WidgetSizeLocation
            data-test-id="html-widget-size-location"
            sizeLocation={{
              xLocation: value.xLocation,
              yLocation: value.yLocation,
              width: value.width,
              height: value.height,
              uniqueId: value.uniqueId,
            }}
            updateWidgetSizeLoction={updateWidgetSizeLocation}
            activeWidgetId={widgetState.id}
          />
        </SizeLocationPanel>
      </StyledPanel>
      <StyledPanel>
        <Panel
          data-test-id="html-border-panel"
          title={<Label style={StyledPanelTitle}>Border</Label>}
          initiallyClosed
          icon={Arrow}
        >
          <Border
            handleLineColor={handleLineColor}
            data-test-id="html-border"
            borderData={updateBorderData}
            widgetState={widgetState}
            pages={props.pages}
            site={props.site}
            updateSite={props.updateSite}
            saveSite={props.saveSite}
          />
        </Panel>
      </StyledPanel>
      <StyledPanel>
        <Panel
          data-test-id="html-padding-panel"
          title={<Label style={StyledPanelTitle}>Padding</Label>}
          initiallyClosed
          icon={Arrow}
        >
          <Padding
            data-test-id="html-padding"
            paddingData={updatePaddingData}
            widgetState={widgetState}
          />
        </Panel>
      </StyledPanel>
    </Panel.Group>
  );
};

export default HtmlWidgetControl;
