import { TEMPLATE_STARTER_NAME } from './constants';
import {
  FOOTER_PLACEHOLDER_ID,
  HEADER_PLACEHOLDER_ID,
  widgetTypes,
} from '../../../util/constants';

export const templateStarter = {
  // default initialValue.content for creating a new template
  name: TEMPLATE_STARTER_NAME,
  icon: undefined,
  content: {
    root: {
      id: 'root',
      type: 'List',
      config: {
        bgcolor: 'transparent',
      },
      children: [HEADER_PLACEHOLDER_ID],
      permanent: true,
      defaultWidget: true,
    },
    headerPlaceholderSection: {
      id: HEADER_PLACEHOLDER_ID,
      type: widgetTypes.TemplateHeaderFooterPlaceholder,
      defaultWidget: true,
      config: {
        visible: true,
        enableFullScreen: true,
        spacing: {
          top: '',
          right: '',
          bottom: '',
          left: '',
        },
        isHorizonWidget: true,
        backgroundData: {
          style: 'Color',
          color: '#FBF9F4',
        },
        backgroundImage: {
          url: '',
          alt: '',
        },
        boxShadowData: {
          horizontal: 0,
          vertical: 4,
          blurRadius: 5,
          spreadRadius: 0,
        },
        boxShadowColor: 'lightgrey',
        width: 1275,
        height: 102,
        xLocation: 0,
        yLocation: 0,
        minWidth: 10,
        minHeight: 10,
        borderData: {
          thickness: 0,
          radius: 0,
        },
        uniqueId: 'section345',
        marginTop: 0,
      },
      children: [],
      tabIndex: -1,
    },
    footerPlaceholderSection: {
      id: FOOTER_PLACEHOLDER_ID,
      type: widgetTypes.TemplateHeaderFooterPlaceholder,
      defaultWidget: true,
      config: {
        visible: true,
        enableFullScreen: true,
        spacing: {
          top: '',
          right: '',
          bottom: '',
          left: '',
        },
        isHorizonWidget: true,
        backgroundData: {
          style: 'Color',
          color: '#FBF9F4',
        },
        backgroundImage: {
          url: '',
          alt: '',
        },
        boxShadowData: {
          horizontal: 0,
          vertical: 4,
          blurRadius: 5,
          spreadRadius: 0,
        },
        boxShadowColor: 'lightgrey',
        width: 1275,
        height: 231,
        xLocation: 0,
        yLocation: 830,
        minWidth: 10,
        minHeight: 10,
        borderData: {
          thickness: 0,
          radius: 0,
        },
        uniqueId: 'section678',
        marginTop: 0,
      },
      children: [],
      tabIndex: -1,
    },
  },
};
