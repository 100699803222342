import React from 'react';
import { Panel, Label, HelperText } from '@uitk/react';
import { Arrow } from 'builder/scenes/SiteBuilder/SettingsPanel/SettingsPanel';
import {
  SizeLocationData,
  WidgetSizeLocation,
} from '../../../builder/components/WidgetSizeLocation';
import { Config } from './types';
import {
  SizeLocationPanel,
  StyledPanel,
  StyledPanelTitle,
} from '../Text/TextWidgetControl';
import S3UploadFileButton from 'builder/components/S3UploadFileButton/S3UploadFileButton';
import { UploadedFile } from '../shared/types';
import { JSON_REGEX } from 'builder/util/constants';
import { addChild } from 'shared/state/ducks/sites';
import { useDispatch } from 'react-redux';
import uuidv4 from 'uuid/v4';
import { widgetSchema } from 'shared/widgets';
import { toast } from 'react-toastify';

const UploadWidgetControl: React.FC<WidgetControlProps<Config>> = (
  props: WidgetControlProps<Config>
) => {
  const {
    value,
    onChange,
    widgetState,
    site,
    currentPage,
    closeConfigPanel,
  } = props;
  const updateWidgetSizeLoction = (sizeLoc: SizeLocationData) => {
    onChange({
      ...value,
      width: sizeLoc.width,
      height: sizeLoc.height,
      xLocation: sizeLoc.xLocation,
      yLocation: sizeLoc.yLocation,
      uniqueId: sizeLoc.uniqueId,
    });
  };

  const dispatch = useDispatch();

  const handleUpload = (file: UploadedFile) => {
    const { onChange } = props;
    const { config } = props.widgetState;
    onChange(
      {
        ...config,
        uploadedFile: file,
      },
      props.widgetState.id
    );
    addWidget(file);
  };

  const deleteWidget = (widgetId: string) => {
    closeConfigPanel(false);
    props.removeWidget(widgetId);
  };

  const addWidget = async (file: UploadedFile) => {
    const widgetId = uuidv4();
    var Validator = require('jsonschema').Validator;
    var v = new Validator();
    // const ajv = new Ajv();
    try {
      const fetchResponse = await fetch(file.url);
      var widget = await fetchResponse.json();
    } catch (err) {
      console.error('Error - ', err);
    }

    const validate = v.validate(widget, widgetSchema);

    if (!validate.valid || validate.instance === undefined) {
      toast.error('Not a valid widget');
    } else {
      toast.success('Widget Imported!');

      const uploadWidget = {
        width: widgetState.config.width,
        height: widgetState.config.height,
        xLocation: widgetState.config.xLocation,
        yLocation: widgetState.config.yLocation,
      };

      if (
        widget.type === 'Section' ||
        widget.type === 'Card' ||
        widget.type === 'AccordionWidget' ||
        widget.type === 'Tabs' ||
        widget.type === 'Form' ||
        widget.type === 'Banner' ||
        widget.type === 'SlideShow'
      ) {
        let widgetXLocation = widget.config.xLocation;
        let widgetYLocation = widget.config.yLocation;
        widget.config.xLocation = uploadWidget.xLocation;
        widget.config.yLocation = uploadWidget.yLocation;
        dispatch(
          addChild(
            site.id,
            currentPage.pageId,
            'root',
            widget.type,
            widget.config,
            0,
            widgetId
          )
        );
        let childWidgetIds = [];
        widget.childArray.map(child => {
          const childWidgetId = uuidv4();
          childWidgetIds.push(childWidgetId);
          child.config.xLocation =
            uploadWidget.xLocation + (child.config.xLocation - widgetXLocation);
          child.config.yLocation =
            uploadWidget.yLocation + (child.config.yLocation - widgetYLocation);
          dispatch(
            addChild(
              site.id,
              currentPage.pageId,
              widgetId,
              child.type,
              child.config,
              0,
              childWidgetId,
              child.tabIndex
            )
          );
        });
        widget.children = childWidgetIds;
      } else {
        widget.config.width = uploadWidget.width;
        widget.config.height = uploadWidget.height;
        widget.config.xLocation = uploadWidget.xLocation;
        widget.config.yLocation = uploadWidget.yLocation;
        dispatch(
          addChild(
            site.id,
            currentPage.pageId,
            'root',
            widget.type,
            widget.config,
            0,
            widgetId
          )
        );
      }
      deleteWidget(props.widgetState.id);
    }
  };

  return (
    <>
      <Panel.Group>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>General</Label>}
            icon={Arrow}
            data-test-id="general-image-panel"
          >
            <Label style={{ fontFamily: 'OptumSans' }}>
              Select file to Upload
            </Label>
            <HelperText style={{ fontFamily: 'OptumSans' }}>
              Accepted file type: JSON
            </HelperText>
            <div style={{ position: 'relative', zIndex: 1 }}>
              <S3UploadFileButton
                label="Click to Upload"
                accept={'application/json'}
                onUpload={handleUpload}
                file={widgetState.config.uploadedFile}
                icon={'Upload'}
                iconProps={{
                  width: '1.2em',
                  height: '1.3em',
                  viewBox: '0 0 24 24',
                }}
                fileFormatRegex={JSON_REGEX}
                data-test-id={`file-uploader`}
              />
            </div>
            <HelperText>
              {widgetState.config.uploadedFile
                ? widgetState.config.uploadedFile?.name
                : ''}
            </HelperText>
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <SizeLocationPanel
            title={<Label style={StyledPanelTitle}>Size and Location</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="size-and-location-panel"
          >
            <WidgetSizeLocation
              data-test-id="widget-size-location"
              sizeLocation={{
                xLocation: value.xLocation,
                yLocation: value.yLocation,
                width: value.width,
                height: value.height,
                uniqueId: value.uniqueId,
              }}
              updateWidgetSizeLoction={updateWidgetSizeLoction}
              activeWidgetId={widgetState.id}
            />
          </SizeLocationPanel>
        </StyledPanel>
      </Panel.Group>
    </>
  );
};

export default UploadWidgetControl;
