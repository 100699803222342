import { defaultTheme } from 'link-ui-react';
import { createMuiTheme } from '@material-ui/core/styles';

const normalizeTheme = (theme: Theme): Theme => {
  return {
    ...theme,
    font: theme.font || {
      size: {
        body: {
          base: {
            value: 11,
          },
        },
      },
      weight: {
        base: {
          value: 'normal',
        },
      },
      family: {
        base: {
          value: 'OptumSans',
        },
      },
    },
    colors: {
      ...defaultTheme.colors,
      primary: theme.colors.primary,
      secondary: theme.colors.secondary,
      tertiary: theme.colors.tertiary,
      quaternary: theme.colors.quaternary,
      custom: theme?.colors?.custom || [],
    },
  };
};

const defaultColors = (theme?: Theme): Array<Color> => [
  {
    name: 'Primary',
    value: theme?.colors.primary,
    type: 'Default',
    id: theme?.colors.primary,
  },
  {
    name: 'Secondary',
    value: theme?.colors.secondary,
    type: 'Default',
    id: theme?.colors.secondary,
  },
  {
    name: 'Tertiary',
    value: theme?.colors.tertiary,
    type: 'Default',
    id: theme?.colors.tertiary,
  },
  //TODO: add quaternary to DB
  {
    name: 'Quaternary',
    value: theme?.colors.quaternary,
    type: 'Default',
    id: theme?.colors.quaternary,
  },
];

const textColors = (theme?: Theme): Array<Color> => [
  {
    name: 'Primary Text',
    value: theme?.colors.text.primary,
    type: 'Text',
    id: 'text.primary',
  },
  {
    name: 'Secondary Text',
    value: theme?.colors.text.secondary,
    type: 'Text',
    id: 'text.secondary',
  },
];

const allColors = (theme: Theme): Array<Color> => [
  ...defaultColors(theme),
  ...textColors(theme),
  ...(theme?.colors?.custom || []),
];

const adjustTextColor = (backgroundColor: string) => {
  backgroundColor = backgroundColor.substr(1, 6);
  const bigint = parseInt(backgroundColor, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? '#000000' : '#ffffff';
};

const defaultAndCustomColors = (theme?: Theme): Array<Color> => [
  ...(theme?.colors?.custom || []),
];

const textAndCustomColors = (theme: Theme): Array<Color> => [
  ...textColors(theme),
  ...(theme.colors.custom || []),
];

const getColorValue = (key: string, theme: Theme): string => {
  // try to find the color in the theme, or return the key
  const color: Color = defaultAndCustomColors(theme).find(c => {
    return c.id === key;
  });
  return (color && color.value) || key;
};

const getTextColorValue = (key: string, theme: Theme): string => {
  // used for backwards compatability where a site text color value is stored as 'primary' or 'secondary'
  const color: Color = textAndCustomColors(theme).find(c => {
    return c.id === `text.${key}` || c.id === key;
  });
  return (color && color.value) || key;
};

const muiTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 450,
      md: 1440,
      lg: 1500,
      xl: 1800,
    },
  },
});

export interface FontWeights {
  light: string;
  regular: string;
  medium: string;
  semiBold: string;
  bold: string;
}

export type FontWeight = keyof FontWeights;

const fontWeights: FontWeights = {
  light: '300',
  regular: '400',
  medium: '500',
  semiBold: '600',
  bold: '700',
};

export interface FontSizes {
  small: string;
  regular: string;
  large: string;
}

export type FontSize = keyof FontSizes;

const fontSizes: FontSizes = {
  small: '0.875rem',
  regular: '1rem',
  large: '1.125rem',
};

export {
  // MockSiteObject,
  allColors,
  defaultColors,
  defaultAndCustomColors,
  fontSizes,
  fontWeights,
  getColorValue,
  getTextColorValue,
  normalizeTheme,
  textColors,
  textAndCustomColors,
  adjustTextColor,
  muiTheme,
};
