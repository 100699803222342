import React, { ChangeEvent, useEffect, useState } from 'react';
import { menuClasses, SubMenu } from 'react-pro-sidebar';
import styled from 'styled-components';
import { Icon } from 'link-ui-react';
import { Button, FormControl, Label, Text } from '@uitk/react';
import { fontSizes } from 'builder/util/constants';
import { linkDeveloperTheme } from 'link-ui-react';
import HSBar from 'react-horizontal-stacked-bar-chart';
import { getCombinedFontsForTheme } from 'builder/util/getCombinedFonts';
import hexRgb from 'shared/util/hexrgb';
import {
  horizonOptumTheme,
  horizonRallyTheme,
  horizonUhcTheme,
  optumTheme,
  optumThemeBar,
  rallyTheme,
  rallyThemeBar,
  uhcTheme,
  uhcThemeBar,
} from './BrandThemes';
import { defaultAndCustomColors } from 'shared/util/theme';
import StylePanelColorPicker from 'builder/components/StylePanelColorPicker/StylePanelColorPicker';
import useHandleColorSave from './useHandleColorSave';
import {
  StyledClose,
  StyledPickerHeader,
  StyledPickerPopover,
  StyledPickerWrapper,
} from 'builder/components/GradientColorPicker/GradientColorPicker';
import { IconPalette } from '@tabler/icons-react';
import { FontSelect, FontSizeSelect } from '../SettingsPanel/SettingsPanel';

const SiteStyles = <IconPalette size={36} stroke={2} color="#2E3034" />;

const StyledMenuItem = styled(SubMenu)`
  margin-top: 1rem;
`;

StyledMenuItem.displayName = 'submenu';

export const StyledHeading = styled.p`
  color: ${linkDeveloperTheme.colors.aux.black};
  font-weight: 900;
  font-size: 1.3rem;
  font-family: OptumSans, sans-serif;
`;

export const TopSection = styled.div`
  display: flex;
  align-items: center;
  padding-top: 1.125rem;
  padding-left: 1.25rem;
`;

TopSection.displayName = 'top-section';

export const FieldLabel = styled(Label)`
  padding: 0.75rem 0 0 0.5rem;
  font-family: OptumSans, sans-serif;
  font-size: 0.875rem;
  justify-items: flex-start;
`;

const ThemeColorCard = styled.div`
  border-style: solid;
  border-radius: 0.375rem;
  border-width: thin;
  font-family: OptumSans, sans-serif;
  border-color: ${linkDeveloperTheme.colors.aux.lightGrey};
  margin: 0.5rem;
`;

const ColorCardTop = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ThemeSelectButton = styled(Button)<{ themeEnabled: boolean }>`
  line-height: 0.25;
  font-family: OptumSans, sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  margin: 0.5rem;
  padding: 0.25rem 1rem;
  background: ${p => (p.themeEnabled ? 'rgb(25, 110, 207)' : 'transparent')};
  color: ${p => (p.themeEnabled ? 'rgb(255, 255, 255)' : 'rgb(0, 38, 119)')};
`;

//Font Family and Font Size dropdowns are hidden using 'display: none'
//Set to 'display: flex' when ready to implement text styles functionality
const FontDropdowns = styled.div`
  display: none;
  padding: 0.5rem;
`;

export const StyledLink = styled(Text)`
  font-family: OptumSans, sans-serif;
  color: blue;
  font-size: 0.875rem;
  cursor: pointer;
`;

const LinkWrapper = styled.div`
  display: flex;
  padding: 0 0.5rem;
  justify-content: space-between;
`;

export const StyledMenuLink = styled(Text)`
  font-size: 1rem;
  margin: 0.75rem 0 1rem 1rem;
  cursor: pointer;
  font-family: OptumSans, sans-serif;
  color: blue;
`;

const StyledHSBar = styled(HSBar)`
  margin-top: 1rem;
`;

const HSBarWrapper = styled.div`
  width: 100%;
  margin-bottom: -0.375rem;
  #hsbar {
    border-bottom-right-radius: 0.36rem;
    border-bottom-left-radius: 0.36rem;
  }
`;

export const ThemeColorListWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: scroll
  border-top: 1px solid ${linkDeveloperTheme.colors.aux.lightGrey};
  border-width: thin;
  font-family: OptumSans, sans-serif;
  font-size: 0.875rem;
`;

export const ThemeColorListItem = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  padding-left: 1rem;
`;

export const ThemeColorDisplayWrapper = styled.div`
  justify-items: center;
  align-items: center;
  text-align: center;
  width: 50%;
  padding: 0.5rem;
`;

export const textColor = value => {
  if (value.startsWith('#')) {
    const rgba = hexRgb(value, { format: 'array' });
    const luminance = (rgba[0] * 299 + rgba[1] * 587 + rgba[2] * 114) / 1000;
    if (luminance >= 128) {
      return 'black';
    } else {
      return 'white';
    }
  }
  return 'black';
};

export interface SiteStylesProps {
  site: Site;
  updateSite: (site: Site) => void;
}

export const SiteStylesPanel: React.FC<SiteStylesProps> = (
  props: SiteStylesProps
) => {
  const { updateSite, site } = props;
  const [stylePanelOpen, setStylePanelOpen] = useState(false);
  const [colorPanelOpen, setColorPanelOpen] = useState(false);
  const [brandPanelOpen, setBrandPanelOpen] = useState(false);
  const [brandTheme, setBrandTheme] = useState([]);
  const [enabledTheme, setEnabledTheme] = useState({
    original:
      site.theme.colors.primary === horizonOptumTheme.colors.primary ||
      site.theme.colors.primary === horizonUhcTheme.colors.primary ||
      site.theme.colors.primary === horizonRallyTheme.colors.primary
        ? false
        : true,
    optum:
      site.theme.colors.primary === horizonOptumTheme.colors.primary
        ? true
        : false,
    uhc:
      site.theme.colors.primary === horizonUhcTheme.colors.primary
        ? true
        : false,
    rally:
      site.theme.colors.primary === horizonRallyTheme.colors.primary
        ? true
        : false,
  });
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [fontFamily, setFontFamily] = useState(
    props.site.theme.font ? props.site.theme.font : 'OptumSans'
  );
  const [fontSize, setFontSize] = useState('16');
  const [colors, setColors] = useState(
    defaultAndCustomColors(props.site.theme)
  );
  const [activeColor, setActiveColor] = useState('');
  const [activeColorName, setActiveColorName] = useState('');
  const [activeColorType, setActiveColorType] = useState('');
  const handleColorFunction = (
    activeColor,
    activeColorName,
    activeColorType,
    props
  ) => {
    useHandleColorSave(activeColor, activeColorName, activeColorType, props);
    setAnchorEl(null);
  };

  useEffect(() => {
    setColors(() => defaultAndCustomColors(props.site.theme));
  }, [props.site.theme.colors]);

  useEffect(() => {
    saveOriginalColors();
  }, []);

  const pickerOpen = Boolean(anchorEl);
  const id = pickerOpen ? 'simple-popover' : undefined;

  const toggleStylePanel = stylePanelOpen => {
    setStylePanelOpen(stylePanelOpen);
  };

  const handleColorClick = (
    e: React.MouseEvent<HTMLDivElement>,
    value,
    name,
    type
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setActiveColor(value);
    setActiveColorName(name);
    setActiveColorType(type);
  };
  const handleColorClose = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
  };
  const openColorPanel = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setStylePanelOpen(true);
    setColorPanelOpen(true);
  };
  const closeColorPanel = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setColorPanelOpen(false);
    setStylePanelOpen(true);
  };
  const closeAllPanels = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setColorPanelOpen(false);
    setStylePanelOpen(false);
    setBrandPanelOpen(false);
  };
  const openBrandPanel = (e: React.MouseEvent<any>, brandTheme) => {
    e.stopPropagation();
    setBrandTheme(brandTheme);
    setBrandPanelOpen(true);
  };
  const closeBrandPanel = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setBrandPanelOpen(false);
  };
  const handleFontChange = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setFontFamily(e.target.value);

    updateSite({
      ...site,
      theme: {
        ...site.theme,
        font: {
          ...site.theme.font,
          family: {
            ...site.theme.font.family,
            base: {
              ...site.theme.font.family.base,
              value: e.target.value,
            },
          },
        },
      },
    });
  };
  const handleFontSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setFontSize(e.target.value);
    updateSite({
      ...site,
      theme: {
        ...site.theme,
        font: {
          ...site.theme.font,
          size: {
            ...site.theme.font.size,
            body: {
              ...site.theme.font.size.body,
              base: {
                ...site.theme.font.size.body.base,
                value: parseFloat(e.target.value),
              },
            },
          },
        },
      },
    });
  };
  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  const updateTitle = brandTheme => {
    if (brandTheme === uhcTheme) {
      return 'UHC Theme';
    }
    if (brandTheme === optumTheme) {
      return 'Optum Theme';
    }
    if (brandTheme === rallyTheme) {
      return 'Rally Theme';
    }
  };

  const customTheme = [
    {
      value: 44,
      color: props.site.theme.colors.primary,
    },
    {
      value: 26,
      color: props.site.theme.colors.secondary,
    },
    {
      value: 15,
      color: props.site.theme.colors.tertiary,
    },
    {
      value: 15,
      color: props.site.theme.colors.quaternary
        ? props.site.theme.colors.quaternary
        : '#D14600',
    },
  ];

  const saveOriginalColors = () => {
    updateSite({
      ...site,
      theme: {
        ...site.theme,
        colors: {
          ...site.theme.colors,
          original: {
            primary: site.theme.colors.primary,
            secondary: site.theme.colors.secondary,
            tertiary: site.theme.colors.tertiary,
            quaternary: site.theme.colors.quaternary,
            custom: site.theme.colors.custom,
          },
        },
      },
    });
  };

  const selectCustomTheme = () => {
    setEnabledTheme({
      original: true,
      optum: false,
      uhc: false,
      rally: false,
    });
    updateSite({
      ...site,
      theme: {
        ...site.theme,
        colors: {
          ...site.theme.colors,
          primary: site.theme.colors.original.primary,
          secondary: site.theme.colors.original.secondary,
          tertiary: site.theme.colors.original.tertiary,
          quaternary: site.theme.colors.original.quaternary,
          custom: site.theme.colors.original.custom,
        },
      },
    });
  };

  const selectOptumTheme = () => {
    setEnabledTheme({
      original: false,
      optum: true,
      uhc: false,
      rally: false,
    });
    updateSite({
      ...site,
      theme: {
        ...site.theme,
        colors: {
          ...site.theme.colors,
          primary: horizonOptumTheme.colors.primary,
          secondary: horizonOptumTheme.colors.secondary,
          tertiary: horizonOptumTheme.colors.tertiary,
          quaternary: horizonOptumTheme.colors.quaternary,
          custom: horizonOptumTheme.colors.custom,
        },
      },
    });
  };

  const selectUhcTheme = () => {
    setEnabledTheme({
      original: false,
      optum: false,
      uhc: true,
      rally: false,
    });
    updateSite({
      ...site,
      theme: {
        ...site.theme,
        colors: {
          ...site.theme.colors,
          primary: horizonUhcTheme.colors.primary,
          secondary: horizonUhcTheme.colors.secondary,
          tertiary: horizonUhcTheme.colors.tertiary,
          quaternary: horizonUhcTheme.colors.quaternary,
          custom: horizonUhcTheme.colors.custom,
        },
      },
    });
  };

  const selectRallyTheme = () => {
    setEnabledTheme({
      original: false,
      optum: false,
      uhc: false,
      rally: true,
    });
    updateSite({
      ...site,
      theme: {
        ...site.theme,
        colors: {
          ...site.theme.colors,
          primary: horizonRallyTheme.colors.primary,
          secondary: horizonRallyTheme.colors.secondary,
          tertiary: horizonRallyTheme.colors.tertiary,
          quaternary: horizonRallyTheme.colors.quaternary,
          custom: horizonRallyTheme.colors.custom,
        },
      },
    });
  };

  return (
    <>
      <StyledMenuItem
        data-test-id="style-menu"
        icon={SiteStyles}
        onClick={() => toggleStylePanel(true)}
        rootStyles={{
          ['& > .' + menuClasses.button]: {
            color: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#FFFFFF',
            },
            '&:focus': {
              backgroundColor: '#D9F6FA',
            },
          },
          ['.' + menuClasses.subMenuContent]: {
            backgroundColor: '#FFFFFF',
            width: stylePanelOpen
              ? '16.5rem'
              : '0rem' && colorPanelOpen
              ? '16.5rem'
              : '0px',
            margin: '-3px',
          },
          [`.${menuClasses.icon}`]: {
            '&:hover': {
              backgroundColor: '#d9f6fa',
              fill: '#002677',
            },
          },
        }}
        title="Site Styles"
      >
        {(colorPanelOpen === false && brandPanelOpen === false && (
          <div
            data-test-id="color-select-panel"
            style={{ height: '775px', overflowY: 'scroll' }}
          >
            <TopSection>
              <StyledHeading data-test-id="heading">Site Styles</StyledHeading>
              <Icon
                data-test-id="collapse-icon"
                id="top-section"
                icon="Collapse"
                onClick={() => toggleStylePanel(false)}
                width="1.375rem"
                style={{ marginLeft: '5.67rem', cursor: 'pointer' }}
              />
            </TopSection>
            <div
              style={
                !site.access.edit
                  ? { pointerEvents: 'none', opacity: '0.4' }
                  : {}
              }
            >
              <FontDropdowns>
                <FormControl
                  data-test-id="font-dropdown-form"
                  id={'controlled-select'}
                >
                  <FieldLabel>Font Family</FieldLabel>
                  <FontSelect
                    data-test-id="font-dropdown"
                    onChange={handleFontChange}
                    value={fontFamily}
                  >
                    {Object.keys(getCombinedFontsForTheme()).map(f => {
                      return (
                        <option value={f} key={f}>
                          {f}
                        </option>
                      );
                    })}
                  </FontSelect>
                </FormControl>
                <FormControl
                  data-test-id="font-size-dropdown"
                  id={'controlled-select'}
                >
                  <FieldLabel>Font Size</FieldLabel>
                  <FontSizeSelect
                    onChange={handleFontSizeChange}
                    value={fontSize}
                  >
                    {fontSizes.map(f => (
                      <option value={f} key={f}>
                        {f}
                      </option>
                    ))}
                  </FontSizeSelect>
                </FormControl>
              </FontDropdowns>
              <FieldLabel data-test-id="theme-header">Custom Theme</FieldLabel>
              <ThemeColorCard>
                <ColorCardTop>
                  <FieldLabel data-test-id="theme-header">
                    Site Theme
                  </FieldLabel>
                  <ThemeSelectButton
                    data-test-id="custom-theme-button"
                    variant={enabledTheme.original ? 'secondary' : 'tertiary'}
                    onPress={selectCustomTheme}
                    disabled={!site.access.edit}
                    size="m"
                    themeEnabled={enabledTheme.original}
                  >
                    {enabledTheme.original ? 'Theme in use' : 'Use this theme'}
                  </ThemeSelectButton>
                </ColorCardTop>
                <LinkWrapper>
                  <StyledLink
                    style={{ width: 'fit-content' }}
                    data-test-id="edit-colors-link"
                    onClick={openColorPanel}
                  >
                    Edit colors{' >'}
                  </StyledLink>
                  <StyledLink
                    data-test-id="edit-text-styles-link"
                    onClick={undefined}
                    hidden
                  >
                    Edit text styles{' >'}
                  </StyledLink>
                </LinkWrapper>
                <HSBarWrapper>
                  <StyledHSBar
                    data-test-id="color-bar"
                    height="1.5rem"
                    data={customTheme}
                  />
                </HSBarWrapper>
              </ThemeColorCard>

              <FieldLabel data-test-id="theme-header">Brand Themes</FieldLabel>
              <ThemeColorCard>
                <ColorCardTop>
                  <FieldLabel>Optum</FieldLabel>
                  <ThemeSelectButton
                    data-test-id="optum-theme-button"
                    variant={enabledTheme.optum ? 'secondary' : 'tertiary'}
                    onPress={selectOptumTheme}
                    disabled={!site.access.edit}
                    themeEnabled={enabledTheme.optum}
                  >
                    {enabledTheme.optum ? 'Theme in use' : 'Use this theme'}
                  </ThemeSelectButton>
                </ColorCardTop>
                <LinkWrapper>
                  <StyledLink
                    data-test-id="view-optum-theme-link"
                    onClick={e => openBrandPanel(e, optumTheme)}
                  >
                    View colors{' >'}
                  </StyledLink>
                </LinkWrapper>
                <HSBarWrapper>
                  <StyledHSBar
                    data-test-id="color-bar"
                    height="1.5rem"
                    data={optumThemeBar}
                  />
                </HSBarWrapper>
              </ThemeColorCard>
              <ThemeColorCard>
                <ColorCardTop>
                  <FieldLabel>UHC</FieldLabel>
                  <ThemeSelectButton
                    name="uhc"
                    data-test-id="uhc-theme-button"
                    variant={enabledTheme.uhc ? 'secondary' : 'tertiary'}
                    onPress={selectUhcTheme}
                    disabled={!site.access.edit}
                    themeEnabled={enabledTheme.uhc}
                  >
                    {enabledTheme.uhc ? 'Theme in use' : 'Use this theme'}
                  </ThemeSelectButton>
                </ColorCardTop>
                <LinkWrapper>
                  <StyledLink
                    data-test-id="view-uhc-theme-link"
                    onClick={e => openBrandPanel(e, uhcTheme)}
                  >
                    View colors{' >'}
                  </StyledLink>
                </LinkWrapper>
                <HSBarWrapper>
                  <StyledHSBar
                    data-test-id="color-bar"
                    height="1.5rem"
                    data={uhcThemeBar}
                  />
                </HSBarWrapper>
              </ThemeColorCard>
              <ThemeColorCard>
                <ColorCardTop>
                  <FieldLabel>Rally</FieldLabel>
                  <ThemeSelectButton
                    name="rally"
                    data-test-id="rally-theme-button"
                    variant={enabledTheme.rally ? 'secondary' : 'tertiary'}
                    onPress={selectRallyTheme}
                    disabled={!site.access.edit}
                    themeEnabled={enabledTheme.rally}
                  >
                    {enabledTheme.rally ? 'Theme in use' : 'Use this theme'}
                  </ThemeSelectButton>
                </ColorCardTop>
                <LinkWrapper>
                  <StyledLink
                    data-test-id="view-rally-theme-link"
                    onClick={e => openBrandPanel(e, rallyTheme)}
                  >
                    View colors{' >'}
                  </StyledLink>
                </LinkWrapper>
                <HSBarWrapper>
                  <StyledHSBar
                    data-test-id="color-bar"
                    height="1.5rem"
                    data={rallyThemeBar}
                  />
                </HSBarWrapper>
              </ThemeColorCard>
            </div>
          </div>
        )) ||
          (colorPanelOpen === true && (
            <div
              data-test-id="color-picker-panel"
              style={{ height: '775px', overflowY: 'scroll' }}
            >
              <TopSection>
                <StyledHeading data-test-id="heading">
                  Site Styles
                </StyledHeading>
                <Icon
                  data-test-id="collapse-icon"
                  id="top-section"
                  icon="Collapse"
                  onClick={e => closeAllPanels(e)}
                  width="1.375rem"
                  style={{ marginLeft: '5.67rem', cursor: 'pointer' }}
                />
              </TopSection>
              <br />
              <StyledMenuLink
                data-test-id="return-to-menu-link"
                onClick={e => closeColorPanel(e)}
              >
                {' '}
                {'< '}Back to Theme{' '}
              </StyledMenuLink>
              <FieldLabel data-test-id="theme-header">Custom Theme</FieldLabel>
              <ThemeColorCard>
                <ColorCardTop>
                  <FieldLabel data-test-id="theme-header">
                    Site Theme
                  </FieldLabel>
                  <ThemeSelectButton
                    data-test-id="theme-button-disabled"
                    inverse
                    disabled
                    themeEnabled={enabledTheme.original}
                  >
                    Theme in use
                  </ThemeSelectButton>
                </ColorCardTop>
                <HSBarWrapper>
                  <StyledHSBar
                    data-test-id="color-bar"
                    height="1.5rem"
                    data={customTheme}
                  />
                </HSBarWrapper>
              </ThemeColorCard>
              <StyledPickerPopover
                data-test-id="color-picker-popover"
                id={id}
                open={pickerOpen}
                onClick={handleClick}
                onClose={handleColorClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
                }}
              >
                <StyledPickerWrapper>
                  <StyledPickerHeader data-test-id="styled-picker-header">
                    Color Selector
                  </StyledPickerHeader>
                  <StyledClose
                    data-test-id="close-icon"
                    onClick={handleColorClose}
                  />
                </StyledPickerWrapper>
                <StylePanelColorPicker
                  data-test-id="color-picker"
                  activeColor={activeColor}
                  activeColorName={activeColorName}
                  activeColorType={activeColorType}
                  handleColorFunction={handleColorFunction}
                  props={props}
                  colors={colors}
                />
              </StyledPickerPopover>

              {colors.map(color => (
                <div key={color.value} data-test-id="color-panel-display">
                  <ThemeColorListWrapper>
                    <ThemeColorListItem data-test-id="color-list-title">
                      {color.name}
                    </ThemeColorListItem>
                    <ThemeColorDisplayWrapper>
                      <div
                        key={color.id}
                        id={color.id}
                        data-test-id="color-list-square"
                        onClick={e =>
                          handleColorClick(
                            e,
                            color.value,
                            color.name,
                            color.type
                          )
                        }
                        style={{
                          background: color.value,
                          height: '1.25rem',
                          borderRadius: '.375rem',
                          cursor: 'pointer',
                          color: textColor(
                            color.value ? color.value : '#000000'
                          ),
                          padding: '0 0.8rem',
                        }}
                      >
                        {color.value.startsWith('linear') ||
                        color.value.startsWith('radial')
                          ? ''
                          : color.value}
                      </div>
                    </ThemeColorDisplayWrapper>
                  </ThemeColorListWrapper>
                </div>
              ))}
              <StyledLink
                data-test-id="add-color-link"
                style={{ paddingLeft: '1rem', width: '100%' }}
                onClick={handleColorClick}
              >
                + Add another color
              </StyledLink>
            </div>
          )) ||
          (brandPanelOpen === true && (
            <div data-test-id="brand-theme-panel" style={{ height: '775px' }}>
              <TopSection>
                <StyledHeading data-test-id="heading">
                  Site Styles
                </StyledHeading>
                <Icon
                  data-test-id="collapse-icon"
                  id="top-section"
                  icon="Collapse"
                  onClick={undefined}
                  width="1.375rem"
                  style={{ marginLeft: '5.67rem', cursor: 'pointer' }}
                />
              </TopSection>
              <br />
              <StyledMenuLink
                data-test-id="styled-link"
                onClick={closeBrandPanel}
              >
                {' '}
                {'< '}Back to Theme{' '}
              </StyledMenuLink>
              <FieldLabel data-test-id="theme-header">
                {updateTitle(brandTheme)}
              </FieldLabel>
              {brandTheme.map(({ value, name }) => (
                <div key={name}>
                  <ThemeColorListWrapper>
                    <ThemeColorListItem data-test-id="color-list-title">
                      {name}
                    </ThemeColorListItem>
                    <ThemeColorDisplayWrapper>
                      <div
                        id={value}
                        style={{
                          background: value,
                          height: '1.25rem',
                          borderRadius: '.375rem',
                          color: textColor(value),
                          padding: '0 0.8rem',
                        }}
                      >
                        {value}
                      </div>
                    </ThemeColorDisplayWrapper>
                  </ThemeColorListWrapper>
                </div>
              ))}
            </div>
          ))}
      </StyledMenuItem>
    </>
  );
};
