import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Panel, Label, FormControl, Checkbox, Select } from '@uitk/react';
import { Arrow } from 'builder/scenes/SiteBuilder/SettingsPanel/SettingsPanel';
import { linkDeveloperTheme as theme } from 'link-ui-react';
import {
  SizeLocationData,
  WidgetSizeLocation,
} from '../../../builder/components/WidgetSizeLocation';
import { Config } from './types';
import {
  MainLabelStyles,
  Typography,
} from 'builder/components/WidgetConfigurations/Typography/Typography';
import {
  Padding,
  PaddingData,
} from 'builder/components/WidgetConfigurations/Padding';
import {
  Border,
  BorderData,
} from 'builder/components/WidgetConfigurations/Border';
import {
  StyledPickerPopover,
  StyledPickerWrapper,
  StyledPickerHeader,
  StyledClose,
} from 'builder/components/GradientColorPicker/GradientColorPicker';
import { StyledPanel, StyledPanelTitle } from '../Text/TextWidgetControl';
import {
  Background,
  BackgroundData,
  ColorButtonWrapper,
} from 'builder/components/WidgetConfigurations/Background/Background';
import {
  ButtonContainer,
  HoverButton,
  RegularButton,
  StyledPanelGroup,
} from '../Button/ButtonWidgetControl';
import { UploadedFile } from '../shared/types';
import S3UploadFile from 'builder/components/S3UploadFile';
import {
  PDF_AND_WORD_REGEX,
  VALID_UPLOAD_FILES,
  defaultLinkOptions,
} from 'builder/util/constants';
import { ColorButton } from 'builder/components/ColorButton/ColorButton';
import { StyledTextInput } from 'builder/components/WidgetConfigurations/TextInput/TextInputPanel';
import GradientColorPicker from 'builder/components/GradientColorPicker/GradientColorPicker';
import { toast } from 'react-toastify';

export const WrapperDiv = styled.div`
  padding-top: 1rem;
  display: inline-flex;
  flex-direction: column;
`;

export const StyledOuterDiv = styled.div`
  margin-top: 0.5rem;
  display: inline-flex;
  justify-content: space-between;
  font-size: 14px;
  width: 100%;
`;

export const StyledInnerDiv = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  font-family: OptumSans, sans-serif;
  padding-left: 2rem;
`;

export const StyledFieldSet = styled(FormControl)`
  border-width: 0;
  font-size: 14px;
`;

export const TextIconWrapper = styled.div`
  cursor: pointer;
  font-family: OptumSans, sans-serif;
  text-align: center;
  padding-right: 5px;
  border-radius: 7px;
  &:hover {
    background: #d9f6fa;
  }
`;

export const StyledFormControl = styled(FormControl)`
  border: none;
`;

export const StyledCheckBox = styled(Checkbox)`
  padding-left: 0rem;
  & > input + label {
    font-family: OptumSans, sans-serif;
    font-size: 14px;
  }
`;

export const StyledForm = styled(FormControl)`
  width: fit-content;
`;

export const StyledSelect = styled(Select)`
  border-radius: 0.375rem;
  width: 19.8em;
  margin-bottom: 10px;
  & > select {
    font-family: OptumSans, sans-serif;
  }
`;

export const SizeLocationPanel = styled(Panel)`
  > div {
    padding: 1rem;
  }
`;

const LinkWidgetControl: React.FC<WidgetControlProps<Config>> = (
  props: WidgetControlProps<Config>
) => {
  const { value, onChange, widgetState, pages } = props;
  const { displayText } = widgetState.config;
  const [linkType, setLinkType] = useState(widgetState.config.linkType);
  const [currentTab, setCurrentTab] = useState(widgetState.config.currentTab);
  const [pageValue, setPageValue] = useState(
    widgetState.config.selectedPage?.id.toString()
  );
  const [pickerAnchorEl, setPickerAnchorEl] = useState<HTMLDivElement | null>(
    null
  );
  const pickerOpen = Boolean(pickerAnchorEl);
  const pickerId = pickerOpen ? 'simple-popover' : undefined;

  const handleColorEditClick = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    e.preventDefault();
    setPickerAnchorEl(e.currentTarget);
  };

  const handlePickerClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handlePickerClose = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setPickerAnchorEl(null);
  };

  const handleColorChange = (newColor: string) => {
    if (newColor.startsWith('linear') || newColor.startsWith('radial')) {
      toast('Gradient cannot be used for icon color', {
        type: 'warning',
        theme: 'colored',
      });
    } else {
      handleIconColor(newColor);
    }
  };

  const handleBoldData = data => {
    switch (currentTab) {
      case 0:
        onChange({
          ...value,
          bold: data,
          iconBackgroundColor: theme.colors.aux.skyBlue,
        });
        break;
      case 1:
        onChange({
          ...value,
          hoverData: {
            ...value.hoverData,
            bold: data,
            iconBackgroundColor: theme.colors.aux.skyBlue,
          },
        });
        break;
      default:
    }
  };
  const handleItalic = data => {
    switch (currentTab) {
      case undefined:
      case 0:
        onChange({
          ...value,
          italic: data,
          iconBackgroundColor: theme.colors.aux.skyBlue,
        });
        break;
      case 1:
        onChange({
          ...value,
          hoverData: {
            ...value.hoverData,
            italic: data,
            iconBackgroundColor: theme.colors.aux.skyBlue,
          },
        });
        break;
    }
  };

  const handleTextColorData = data => {
    switch (currentTab) {
      case undefined:
      case 0:
        onChange({
          ...value,
          textColor: data,
        });
        break;
      case 1:
        onChange({
          ...value,
          hoverData: {
            ...value.hoverData,
            textColor: data,
          },
        });
        break;
    }
  };

  const handleFontSizeData = data => {
    switch (currentTab) {
      case undefined:
      case 0:
        onChange({
          ...value,
          fontSize: data,
        });
        break;
      case 1:
        onChange({
          ...value,
          hoverData: {
            ...value.hoverData,
            fontSize: data,
          },
        });
        break;
    }
  };

  const handleUnderlineData = data => {
    switch (currentTab) {
      case undefined:
      case 0:
        onChange({
          ...value,
          underline: data,
          iconBackgroundColor: theme.colors.aux.skyBlue,
        });
        break;
      case 1:
        onChange({
          ...value,
          hoverData: {
            ...value.hoverData,
            underline: data,
            iconBackgroundColor: theme.colors.aux.skyBlue,
          },
        });
        break;
    }
  };

  const handleStrikeThroughData = data => {
    switch (currentTab) {
      case undefined:
      case 0:
        onChange({
          ...value,
          strikeThrough: data,
          iconBackgroundColor: theme.colors.aux.skyBlue,
        });
        break;
      case 1:
        onChange({
          ...value,
          hoverData: {
            ...value.hoverData,
            strikeThrough: data,
            iconBackgroundColor: theme.colors.aux.skyBlue,
          },
        });
        break;
    }
  };

  const handleLineSpacingData = data => {
    switch (currentTab) {
      case undefined:
      case 0:
        onChange({
          ...value,
          lineSpacing: data,
        });
        break;
      case 1:
        onChange({
          ...value,
          hoverData: {
            ...value.hoverData,
            lineSpacing: data,
          },
        });
        break;
    }
  };

  const handleCharacterSpacingData = data => {
    switch (currentTab) {
      case undefined:
      case 0:
        onChange({
          ...value,
          characterSpacing: data,
        });
        break;
      case 1:
        onChange({
          ...value,
          hoverData: {
            ...value.hoverData,
            characterSpacing: data,
          },
        });
        break;
    }
  };

  const handleLineColor = data => {
    switch (currentTab) {
      case undefined:
      case 0:
        onChange({
          ...value,
          lineColor: data,
        });
        break;
      case 1:
        onChange({
          ...value,
          hoverData: {
            ...value.hoverData,
            lineColor: data,
          },
        });
        break;
    }
  };

  const handleIconColor = data => {
    onChange({
      ...value,
      iconColor: data,
    });
  };

  const setValue = e => {
    setLinkType(e.target.value);
    onChange({
      ...value,
      url: '',
      selectedPage: null,
      linkType: e.target.value,
    });
  };

  useEffect(() => {
    if (linkType === 'None') {
      onChange({
        ...value,
        url: '',
        newTab: false,
        linkType: 'None',
        iconColor: 'black',
        selectedPage: null,
      });
      setPageValue('');
    }
  }, [linkType]);

  useEffect(() => {
    handleCurrentTab({ target: { id: 'regular' } });
  }, []);

  useEffect(() => {
    setLinkType(value.linkType);
    setCurrentTab(value.currentTab ? value.currentTab : 0);
    setPageValue(value.selectedPage?.id.toString());
  }, [widgetState.config]);

  useEffect(() => {
    if (pages.length > 0 && value.selectedPage) {
      const pageStillExists = pages.some(
        page => page.id === value.selectedPage.id
      );
      if (!pageStillExists) {
        // Reset the selection if the selected page no longer exists
        onChange({
          ...value,
          selectedPage: null,
          url: '',
        });
        setPageValue('');
      }
    }
  }, [pages]);

  const handleUrl = (e: ChangeEvent<HTMLInputElement>) => {
    if (linkType === 'URL') {
      onChange({
        ...value,
        url: e.target.value,
      });
    }
  };

  const handleDisplayText = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      displayText: e.target.value,
    });
  };

  const handleFontData = (data: string) => {
    switch (currentTab) {
      case undefined:
      case 0:
        onChange({
          ...value,
          font: data,
        });
        break;
      case 1:
        onChange({
          ...value,
          hoverData: {
            ...value.hoverData,
            font: data,
          },
        });
        break;
    }
  };

  const handleEmailAddress = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      emailAddress: e.target.value,
    });
  };

  const handleEmailSubject = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      emailSubject: e.target.value,
    });
  };

  const updateWidgetSizeLoction = (sizeLoc: SizeLocationData) => {
    onChange({
      ...value,
      width: sizeLoc.width,
      height: sizeLoc.height,
      xLocation: sizeLoc.xLocation,
      yLocation: sizeLoc.yLocation,
      uniqueId: sizeLoc.uniqueId,
    });
  };

  const updatePaddingData = (data: PaddingData) => {
    switch (currentTab) {
      case undefined:
      case 0:
        onChange({
          ...value,
          paddingData: data,
        });
        break;
      case 1:
        onChange({
          ...value,
          hoverData: {
            ...value.hoverData,
            paddingData: data,
          },
        });
        break;
    }
  };

  const updateBorderData = (data: BorderData) => {
    switch (currentTab) {
      case undefined:
      case 0:
        onChange({
          ...value,
          borderData: data,
        });
        break;
      case 1:
        onChange({
          ...value,
          hoverData: {
            ...value.hoverData,
            borderData: data,
          },
        });
        break;
      default:
    }
  };

  const updateBackgroundData = (data: BackgroundData) => {
    switch (currentTab) {
      case undefined:
      case 0:
        onChange({
          ...value,
          backgroundData: data,
        });
        break;
      case 1:
        onChange({
          ...value,
          hoverData: {
            ...value.hoverData,
            backgroundData: data,
          },
        });
        break;
    }
  };

  const handleNewTabCheck = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      newTab: e.target.checked,
    });
  };

  const handlePage = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    if (
      e.target.value &&
      pages.find(page => page.id === parseInt(e.target.value))
    ) {
      onChange({
        ...value,
        selectedPage: pages.find(page => page.id === parseInt(e.target.value)),
        url: `https://${props.site.domain}/${
          pages.find(page => page.id === parseInt(e.target.value)).current.slug
        }`,
      });
    }
  };

  const handleUpload = (file: UploadedFile) => {
    onChange({
      ...value,
      url: file.url,
      uploadedFile: file,
    });
  };

  const handleBackgroundColor = (data: string) => {
    switch (currentTab) {
      case undefined:
      case 0:
        onChange({
          ...value,
          backgroundData: {
            ...value.backgroundData,
            color: data,
          },
        });
        break;
      case 1:
        onChange({
          ...value,
          hoverData: {
            ...value.hoverData,
            backgroundData: {
              ...value.hoverData.backgroundData,
              color: data,
            },
          },
        });
        break;
    }
  };

  const handleCurrentTab = e => {
    switch (e.target.id) {
      case 'regular':
        setCurrentTab(0);
        onChange({
          ...value,
          currentTab: 0,
        });
        break;
      case 'hover':
        setCurrentTab(1);
        onChange({
          ...value,
          currentTab: 1,
        });
        break;
      default:
    }
  };

  return (
    <>
      <ButtonContainer>
        <RegularButton
          id="regular"
          onPress={handleCurrentTab}
          currentTab={currentTab}
          data-test-id="regular-button"
        >
          Regular
        </RegularButton>
        <HoverButton
          id="hover"
          onPress={handleCurrentTab}
          currentTab={currentTab}
          data-test-id="hover-button"
        >
          Hover
        </HoverButton>
      </ButtonContainer>
      {currentTab === 0 || !currentTab ? (
        <StyledPanelGroup>
          <StyledPanel>
            <Panel
              title={<Label style={StyledPanelTitle}>General</Label>}
              icon={Arrow}
              data-test-id="general-panel"
            >
              <MainLabelStyles style={{ marginBottom: '0.25rem' }}>
                Display text
              </MainLabelStyles>
              <StyledTextInput
                name="displayText"
                value={displayText}
                onChange={handleDisplayText}
                data-test-id="display-text-input"
              />
              <WrapperDiv>
                <MainLabelStyles>What do you want to link to?</MainLabelStyles>
                <StyledSelect
                  onChange={e => setValue(e)}
                  value={linkType}
                  data-test-id="link-dropdown"
                  style={{ fontFamily: 'OptumSans' }}
                >
                  {defaultLinkOptions.map(option => {
                    return (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </StyledSelect>
              </WrapperDiv>
              {linkType === 'Page' && (
                <StyledFormControl id={'controlled-select'}>
                  <StyledSelect
                    onChange={handlePage}
                    value={pageValue}
                    data-test-id="page-select-dropdown"
                    style={{ fontFamily: 'OptumSans' }}
                  >
                    <option value="none">Select a Page</option>
                    {pages.map(
                      page =>
                        page.current.slug !== 'defaultContentPage' && (
                          <option key={page.id} value={page.id}>
                            {page.current.title}
                          </option>
                        )
                    )}
                  </StyledSelect>
                </StyledFormControl>
              )}
              {linkType === 'Email' && (
                <>
                  <StyledTextInput
                    name="emailAddress"
                    value={widgetState.config.emailAddress}
                    onChange={handleEmailAddress}
                    placeholder="Type email address here"
                    data-test-id="email-address-input"
                  />

                  <StyledTextInput
                    name="emailSubject"
                    value={widgetState.config.emailSubject}
                    onChange={handleEmailSubject}
                    placeholder="Type email subject line here"
                    style={{ marginTop: '10px' }}
                    data-test-id="email-subject-input"
                  />
                </>
              )}
              {linkType === 'URL' && (
                <StyledTextInput
                  name="webaddress"
                  value={widgetState.config.url}
                  onChange={handleUrl}
                  placeholder="http:// or https://"
                  data-test-id="web-address"
                />
              )}
              {linkType === 'File' && (
                <S3UploadFile
                  label={'File (.pdf, .doc, .docx format only)'}
                  onUpload={handleUpload}
                  file={widgetState.config.uploadedFile}
                  accept={VALID_UPLOAD_FILES}
                  icon={'Upload'}
                  iconProps={{
                    width: '1.2em',
                    height: '1.3em',
                    viewBox: '0 0 24 24',
                  }}
                  fileFormatRegex={PDF_AND_WORD_REGEX}
                  data-test-id={`file-uploader`}
                />
              )}
              {linkType !== 'None' && (
                <>
                  <StyledOuterDiv>
                    <StyledForm id="webaddress-checkbox">
                      <StyledCheckBox
                        checked={widgetState.config.newTab}
                        onChange={handleNewTabCheck}
                      >
                        Open in new tab
                      </StyledCheckBox>
                    </StyledForm>
                    {widgetState.config.newTab && (
                      <StyledInnerDiv>
                        Icon color
                        <ColorButtonWrapper
                          id="icon-color"
                          onClick={handleColorEditClick}
                        >
                          <ColorButton color={widgetState.config.iconColor} />
                        </ColorButtonWrapper>
                      </StyledInnerDiv>
                    )}
                    <StyledPickerPopover
                      data-test-id="widget-color-picker-popover"
                      id={pickerId}
                      open={pickerOpen}
                      onClick={handlePickerClick}
                      onClose={handlePickerClose}
                      anchorEl={pickerAnchorEl}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                      }}
                    >
                      <StyledPickerWrapper>
                        <StyledPickerHeader data-test-id="styled-picker-header">
                          Color Selector
                        </StyledPickerHeader>
                        <StyledClose
                          data-test-id="close-icon"
                          onClick={handlePickerClose}
                        />
                      </StyledPickerWrapper>
                      <GradientColorPicker
                        data-test-id="background-color"
                        value={widgetState.config.iconColor}
                        site={props.site}
                        handleColorChange={handleColorChange}
                        onChange={handleColorChange}
                        updateSite={props.updateSite}
                        saveSite={props.saveSite}
                        anchorEl={pickerAnchorEl}
                      />
                    </StyledPickerPopover>
                  </StyledOuterDiv>
                </>
              )}
            </Panel>
          </StyledPanel>
          <StyledPanel>
            <Panel
              key={'typography-panel'}
              title={<Label style={StyledPanelTitle}>Typography</Label>}
              initiallyClosed
              icon={Arrow}
              data-test-id="typography-panel"
            >
              <Typography
                key={'typography-component'}
                data-test-id="typography-component"
                widgetState={widgetState}
                boldData={handleBoldData}
                handleFontSize={handleFontSizeData}
                handleFont={handleFontData}
                handleItalicData={handleItalic}
                handleUnderLine={handleUnderlineData}
                handleStrikeThrough={handleStrikeThroughData}
                handleLineSpacing={handleLineSpacingData}
                handleCharacterSpacing={handleCharacterSpacingData}
                hideAlign={true}
                hideTextHighlight={true}
                site={props.site}
                handleTextColor={handleTextColorData}
                updateSite={props.updateSite}
                saveSite={props.saveSite}
                pages={pages}
                currentTab={currentTab}
              />
            </Panel>
          </StyledPanel>
          <StyledPanel>
            <SizeLocationPanel
              key={'size-location-panel'}
              title={<Label style={StyledPanelTitle}>Size and Location</Label>}
              initiallyClosed
              icon={Arrow}
              data-test-id="size-location-panel"
            >
              <WidgetSizeLocation
                data-test-id="widget-size-location"
                sizeLocation={{
                  xLocation: value.xLocation,
                  yLocation: value.yLocation,
                  width: value.width,
                  height: value.height,
                  uniqueId: value.uniqueId,
                }}
                updateWidgetSizeLoction={updateWidgetSizeLoction}
                activeWidgetId={widgetState.id}
              />
            </SizeLocationPanel>
          </StyledPanel>
          <StyledPanel>
            <Panel
              key={'background-panel'}
              title={<Label style={StyledPanelTitle}>Background</Label>}
              initiallyClosed
              icon={Arrow}
              data-test-id="background-banner-panel"
            >
              <Background
                data-test-id="background-component"
                backgroundData={updateBackgroundData}
                widgetState={widgetState}
                handleLineColor={handleLineColor}
                handleBackgroundColor={handleBackgroundColor}
                site={props.site}
                updateSite={props.updateSite}
                saveSite={props.saveSite}
                currentTab={currentTab}
              />
            </Panel>
          </StyledPanel>
          <StyledPanel>
            <Panel
              key={'border-panel'}
              title={<Label style={StyledPanelTitle}>Border</Label>}
              initiallyClosed
              icon={Arrow}
              data-test-id="border-panel"
            >
              <Border
                data-test-id="border-data"
                borderData={updateBorderData}
                handleLineColor={handleLineColor}
                widgetState={widgetState}
                pages={props.pages}
                site={props.site}
                updateSite={props.updateSite}
                saveSite={props.saveSite}
              />
            </Panel>
          </StyledPanel>
          <StyledPanel>
            <Panel
              key={'padding-panel'}
              title={<Label style={StyledPanelTitle}>Padding</Label>}
              initiallyClosed
              icon={Arrow}
            >
              <Padding
                data-test-id="padding-data"
                paddingData={updatePaddingData}
                widgetState={widgetState}
              />
            </Panel>
          </StyledPanel>
        </StyledPanelGroup>
      ) : (
        <StyledPanelGroup>
          <StyledPanel>
            <Panel
              key={'hover-typography-panel'}
              title={<Label style={StyledPanelTitle}>Typography</Label>}
              initiallyClosed
              icon={Arrow}
              data-test-id="hover-typography-panel"
            >
              <Typography
                key={'hover-typography-component'}
                data-test-id="hover-typography-component"
                widgetState={widgetState}
                currentTab={currentTab}
                boldData={handleBoldData}
                handleFontSize={handleFontSizeData}
                handleFont={handleFontData}
                handleItalicData={handleItalic}
                handleUnderLine={handleUnderlineData}
                handleStrikeThrough={handleStrikeThroughData}
                handleLineSpacing={handleLineSpacingData}
                handleCharacterSpacing={handleCharacterSpacingData}
                hideAlign={true}
                hideTextHighlight={true}
                site={props.site}
                handleTextColor={handleTextColorData}
                updateSite={props.updateSite}
                saveSite={props.saveSite}
                pages={pages}
              />
            </Panel>
          </StyledPanel>
          <StyledPanel>
            <Panel
              key={'hover-background-panel'}
              title={<Label style={StyledPanelTitle}>Background</Label>}
              initiallyClosed
              icon={Arrow}
              data-test-id="hover-background-banner-panel"
            >
              <Background
                data-test-id="hover-background-component"
                backgroundData={updateBackgroundData}
                widgetState={widgetState}
                handleLineColor={handleLineColor}
                handleBackgroundColor={handleBackgroundColor}
                site={props.site}
                updateSite={props.updateSite}
                saveSite={props.saveSite}
                currentTab={currentTab}
              />
            </Panel>
          </StyledPanel>
          <StyledPanel>
            <Panel
              key={'hover-border-panel'}
              title={<Label style={StyledPanelTitle}>Border</Label>}
              initiallyClosed
              icon={Arrow}
              data-test-id="hover-border-panel"
            >
              <Border
                data-test-id="hover-border-data"
                borderData={updateBorderData}
                handleLineColor={handleLineColor}
                widgetState={widgetState}
                pages={props.pages}
                site={props.site}
                updateSite={props.updateSite}
                saveSite={props.saveSite}
                currentTab={currentTab}
              />
            </Panel>
          </StyledPanel>
          <StyledPanel>
            <Panel
              key={'hover-padding-panel'}
              title={<Label style={StyledPanelTitle}>Padding</Label>}
              initiallyClosed
              icon={Arrow}
            >
              <Padding
                data-test-id="padding-data"
                paddingData={updatePaddingData}
                widgetState={widgetState}
                currentTab={currentTab}
              />
            </Panel>
          </StyledPanel>
        </StyledPanelGroup>
      )}
    </>
  );
};

export default LinkWidgetControl;
