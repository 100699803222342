import React, { ChangeEvent, ReactElement, useState } from 'react';
import { FormControl, TextArea } from '@uitk/react';
import { Config } from './types';
import styled from 'styled-components';

const StyledTextArea = styled(TextArea)<{
  font: string;
  fontSize: number;
  align: string;
  bold: boolean;
  italic: boolean;
  strikethrough: boolean;
  lineSpacing: string;
  characterSpacing: number;
  backgroundData: any;
  backgroundImage: any;
  textColor: string;
  paddingData: any;
  borderData: any;
  lineColor: string;
}>`
  font-family: ${p => p.font};
  font-size: ${p => p.fontSize}pt;
  font-weight: ${p => (p.bold ? 'bold' : 'normal')};
  font-style: ${p => (p.italic ? 'italic' : 'normal')};
  text-decoration: ${p =>
    p.underline ? 'underline' : p.strikeThrough ? 'line-through' : 'none'};
  text-align: ${p => p.align};
  line-height: ${p => p.lineSpacing};
  letter-spacing: ${p => p.characterSpacing}px;
  margin-top: 0;
  background-image: ${p =>
    p.backgroundData?.style === 'Image'
      ? `url('${p.backgroundImage?.url}')`
      : ''};
  background-color: ${p =>
    p.backgroundData?.color !== '' ? p.backgroundData?.color : ''};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: ${p => p.paddingData?.top}px;
  padding-right: ${p => p.paddingData?.right}px;
  padding-bottom: ${p => p.paddingData?.bottom}px;
  padding-left: ${p => p.paddingData?.left}px;
  color: ${p => p.textColor};
  border-width: ${p => p.borderData?.thickness}px;
  border-style: solid;
  border-color: ${p => p.lineColor};
  border-radius: ${p => p.borderData?.radius}px;
  height: 100%;
  width: 100%;
  box-shadow: none;
`;
const StyledFormControl = styled(FormControl)<{ cursor }>`
  width: 100%;
  height: 100%;
  .fByTuF:hover,
  .fByTuF:focus,
  .fByTuF:active {
    box-shadow: none;
    cursor: ${p => (p.cursor ? '' : 'default')};
  }
`;

const StyledParagraph = styled.p<{
  font: string;
  fontSize: number;
  align: string;
  bold: boolean;
  italic: boolean;
  underline: boolean;
  strikeThrough: boolean;
  lineSpacing: string;
  characterSpacing: number;
  backgroundData: any;
  backgroundImage: any;
  textColor: string;
  paddingData: any;
  borderData: any;
  lineColor: string;
}>`
  font-family: ${p => p.font};
  font-size: ${p => p.fontSize}pt;
  font-weight: ${p => (p.bold ? 'bold' : 'normal')};
  font-style: ${p => (p.italic ? 'italic' : 'normal')};
  text-decoration: ${p =>
    p.underline ? 'underline' : p.strikeThrough ? 'line-through' : 'none'};
  text-align: ${p => p.align};
  line-height: ${p => p.lineSpacing};
  letter-spacing: ${p => p.characterSpacing}px;
  margin-top: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: ${p => p.paddingData?.top}px;
  padding-right: ${p => p.paddingData?.right}px;
  padding-bottom: ${p => p.paddingData?.bottom}px;
  padding-left: ${p => p.paddingData?.left}px;
  color: ${p => p.textColor};
  border-width: ${p => p.borderData?.thickness}px;
  border-style: solid;
  border-color: ${p => p.lineColor};
  border-radius: ${p => p.borderData?.radius}px;
  width: 100%;
  box-shadow: none;
`;

const TextWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
): ReactElement => {
  const {
    align,
    font,
    fontSize,
    bold,
    italic,
    underline,
    strikeThrough,
    lineSpacing,
    characterSpacing,
    backgroundData,
    backgroundImage,
    textColor,
    text = '',
    paddingData,
    borderData,
    lineColor,
    visible,
    isCustomWidget,
  } = props.widgetState.config;

  const [value, setValue] = useState(text);
  const onInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  const handleChange = () => {
    const { onChange } = props;
    const { config } = props.widgetState;
    onChange(
      {
        ...config,
        text: value,
      },
      props.widgetState.id,
      props.widgetState.defaultWidget
    );
  };

  if (visible === false || isCustomWidget) {
    return <></>;
  }

  return props.editing ? (
    <StyledFormControl
      id={'text-area'}
      data-test-id={'styled-form-control'}
      cursor={props.editing}
    >
      <StyledTextArea
        font={font}
        fontSize={fontSize}
        align={align}
        bold={bold}
        italic={italic}
        underline={underline}
        strikeThrough={strikeThrough}
        lineSpacing={lineSpacing}
        characterSpacing={characterSpacing}
        backgroundData={backgroundData}
        backgroundImage={backgroundImage}
        textColor={textColor}
        paddingData={paddingData}
        borderData={borderData}
        lineColor={lineColor}
        data-test-id="text-area"
        value={value}
        onChange={onInputChange}
        onBlur={handleChange}
        style={{
          backgroundColor:
            backgroundData?.color?.startsWith('#') ||
            backgroundData?.color?.startsWith('rgb')
              ? backgroundData?.color
              : 'transparent',
          backgroundImage:
            backgroundData?.style === 'Image'
              ? `url('${backgroundImage?.url}')`
              : '',
        }}
      />
    </StyledFormControl>
  ) : (
    <StyledParagraph
      data-test-id="text-widget-paragraph"
      children={bold ? <strong> {value} </strong> : value}
      font={font}
      fontSize={fontSize}
      align={align}
      bold={bold as boolean}
      italic={italic as boolean}
      underline={underline as boolean}
      strikeThrough={strikeThrough as boolean}
      lineSpacing={lineSpacing}
      characterSpacing={characterSpacing}
      backgroundData={backgroundData}
      backgroundImage={backgroundImage}
      textColor={textColor}
      paddingData={paddingData}
      borderData={borderData}
      lineColor={lineColor}
      style={{
        backgroundColor:
          backgroundData?.color?.startsWith('#') ||
          backgroundData?.color?.startsWith('rgb')
            ? backgroundData?.color
            : 'transparent',
        backgroundImage:
          backgroundData?.style === 'Image'
            ? `url('${backgroundImage?.url}')`
            : '',
      }}
    />
  );
};
export default TextWidget;
