import { InputLabel } from '@material-ui/core';
import { TextInput, Label, IDropdownItem, Button } from '@uitk/react';
import { PureComponent } from 'react';
import styled from 'styled-components';
import { DropdownStyles } from './SettingsPanel';
import { FeedbackFormField } from 'shared/widgets/Form/types';

const PaddingWrapper = styled.div`
  width: 100%;
  height: 3em;
  margin-top: 1em;
  margin-right: 3em;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
`;

const StyledDropdown = styled.p`
  .ilhvDI {
    font-family: OptumSans, sans-serif;
  }
`;

const styles = {
  width: '14.4375em',
  height: '2.5em',
  'margin-left': '-0.5em',
  'font-family': 'OptumSans',
};

const labelStyles = {
  color: '#323334',
  fontSize: '0.875em',
  fontFamily: 'OptumSans',
  lineHeight: '1.25em',
  marginLeft: '-0.5em',
  paddingTop: '7px',
};

const TextBoxStyles = {
  width: '14.4375em',
  height: '2.5em',
  'margin-left': '-0.5em',
  color: '#4b4d4f',
  'font-family': 'OptumSans',
};

const validationTypes = [
  'none',
  'alphabet',
  'alphanumeric',
  'date',
  'email',
  'optumemail',
  'number',
];

const DeleteFieldButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  margin-top: 10px;
  font-family: OptumSans, sans-serif;
  color: #ffffff;
  background: #444444;
  &:hover,
  &:focus {
    color: #ffffff;
    background: #333333;
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 5px #333333;
    border-color: transparent;
    outline: none;
  }
  &.isPressed {
    color: #ffffff;
    background: #111111;
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 5px #111111;
  }
`;

export interface FeedbackFormFieldControlProps {
  index: number;
  formField: FeedbackFormField;
  onChange: (formField: FeedbackFormField) => void;
  onDelete: () => void;
}

class FeedbackFieldControl extends PureComponent<
  FeedbackFormFieldControlProps
> {
  capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  handleFieldChange = (key: string, value: string | boolean) => {
    const { onChange, formField } = this.props;
    onChange({
      ...formField,
      [key]: value,
    });
  };

  handleClick = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  formFieldValidationDropdownValues = () => {
    let treevaluee: IDropdownItem[] = [];
    let i = 0;
    validationTypes.map(style =>
      treevaluee?.push({
        id: i++,
        label: this.capitalizeFirstLetter(style),
        value: style,
      })
    );
    return treevaluee;
  };

  getFromFieldValidation = () => {
    let returnValue = { id: 0, label: '', value: '' };
    this.formFieldValidationDropdownValues().map(style =>
      style.value === this.props.formField.validation
        ? (returnValue = {
            id: style.id as number,
            label: this.capitalizeFirstLetter(style.label) as string,
            value: style.value as string,
          })
        : ''
    );
    return returnValue;
  };

  render() {
    const { formField, index, onDelete } = this.props;
    return (
      <>
        <PaddingWrapper>
          <Label style={labelStyles}>Placeholder text</Label>
          <TextInput
            name="issueFeedBackTypeEmailList"
            style={TextBoxStyles}
            value={formField.placeholder || ''}
            onChange={({ target: { value } }) =>
              this.handleFieldChange('placeholder', value)
            }
            data-test-id={`field-placeholder-${index}`}
          />
        </PaddingWrapper>
        <PaddingWrapper onClick={this.handleClick}>
          <Label style={labelStyles}>Validation</Label>
          <StyledDropdown style={styles} onClick={this.handleClick}>
            <DropdownStyles
              type="single"
              items={this.formFieldValidationDropdownValues()}
              value={this.getFromFieldValidation()}
              placeholderText={<InputLabel>Please Select</InputLabel>}
              onChange={({ value }) =>
                this.handleFieldChange('validation', value)
              }
              data-test-id={`field-validation-${index}`}
            />
          </StyledDropdown>
        </PaddingWrapper>
        <PaddingWrapper>
          <DeleteFieldButton
            data-test-id={`field-delete-${index}`}
            variant="secondary"
            onClick={onDelete}
          >
            Delete field
          </DeleteFieldButton>
        </PaddingWrapper>
      </>
    );
  }
}

export default FeedbackFieldControl;
