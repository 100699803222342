import {
  $createCodeNode,
  $isCodeNode,
  CODE_LANGUAGE_FRIENDLY_NAME_MAP,
  CODE_LANGUAGE_MAP,
  getLanguageFriendlyName,
} from '@lexical/code';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { INSERT_HORIZONTAL_RULE_COMMAND } from '@lexical/react/LexicalHorizontalRuleNode';
import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode,
  HeadingTagType,
} from '@lexical/rich-text';
import {
  $getSelectionStyleValueForProperty,
  $isParentElementRTL,
  $patchStyleText,
  $setBlocksType,
} from '@lexical/selection';
import { $isTableNode, $isTableSelection } from '@lexical/table';
import {
  $findMatchingParent,
  $getNearestNodeOfType,
  $isEditorIsNestedEditor,
  mergeRegister,
} from '@lexical/utils';
import {
  $createParagraphNode,
  $getNodeByKey,
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_NORMAL,
  ElementFormatType,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  INDENT_CONTENT_COMMAND,
  KEY_MODIFIER_COMMAND,
  LexicalEditor,
  NodeKey,
  OUTDENT_CONTENT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from 'lexical';
import { Dispatch, useCallback, useEffect, useState } from 'react';

import { IS_APPLE } from '../../utils/environment';

import useModal from '../../hooks/useModal';
import DropDown, { DropDownItem } from '../../ui/DropDown';
import DropdownColorPicker from '../../ui/DropdownColorPicker';
import { getSelectedNode } from '../../utils/getSelectedNode';
import { sanitizeUrl } from '../../utils/url';
import joinClasses from '../../utils/joinClasses';
// Disabling Layout Columns, Image and Inline Image insertion for v1 of Rich Text Editor
// import { InsertImageDialog } from '../ImagesPlugin';
// import { InsertInlineImageDialog } from '../InlineImagePlugin';
// import InsertLayoutDialog from '../LayoutPlugin/InsertLayoutDialog';
import { InsertTableDialog } from '../TablePlugin';
import FontSize from './fontSize';
import {
  IconAlertTriangle,
  IconAlignCenter,
  IconAlignJustified,
  IconAlignLeft,
  IconAlignRight,
  IconArrowBackUp,
  IconArrowForwardUp,
  IconBlockquote,
  IconBold,
  IconBulb,
  IconClearFormatting,
  IconCode,
  IconCodeDots,
  // IconColumns3,
  IconH1,
  IconH2,
  IconH3,
  IconHandStop,
  // IconImageInPicture,
  IconIndentDecrease,
  IconIndentIncrease,
  IconInfoSquare,
  IconItalic,
  IconLayoutDistributeHorizontal,
  IconLinkPlus,
  IconList,
  IconListNumbers,
  IconPencilExclamation,
  // IconPhoto,
  IconPilcrow,
  IconQuestionMark,
  IconStrikethrough,
  IconSubscript,
  IconSuperscript,
  IconTable,
  IconUnderline,
} from '@tabler/icons-react';
import useClearFormatting from '../../hooks/useClearFormatting';
import { useSettings } from '../../context/SettingsContext';
import { insertAdmonition } from '../AdmonitionPlugin';

const blockTypeToBlockName = {
  bullet: 'Bulleted List',
  check: 'Check List',
  code: 'Code Block',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  h4: 'Heading 4',
  h5: 'Heading 5',
  h6: 'Heading 6',
  number: 'Numbered List',
  paragraph: 'Normal',
  quote: 'Quote',
};

const rootTypeToRootName = {
  root: 'Root',
  table: 'Table',
};

function getCodeLanguageOptions(): [string, string][] {
  const options: [string, string][] = [];

  for (const [lang, friendlyName] of Object.entries(
    CODE_LANGUAGE_FRIENDLY_NAME_MAP
  )) {
    options.push([lang, friendlyName]);
  }

  return options;
}

const CODE_LANGUAGE_OPTIONS = getCodeLanguageOptions();

const FONT_FAMILY_OPTIONS: [string, string][] = [
  ['OptumSans', 'OptumSans'],
  ['UHCSans', 'UHCSans'],
  ['Arial', 'Arial'],
  ['Georgia', 'Georgia'],
  ['Times New Roman', 'Times New Roman'],
  ['Trebuchet MS', 'Trebuchet MS'],
  ['Verdana', 'Verdana'],
];

const FONT_SIZE_OPTIONS: [string, string][] = [
  ['10px', '10px'],
  ['11px', '11px'],
  ['12px', '12px'],
  ['13px', '13px'],
  ['14px', '14px'],
  ['15px', '15px'],
  ['16px', '16px'],
  ['17px', '17px'],
  ['18px', '18px'],
  ['19px', '19px'],
  ['20px', '20px'],
];

const ELEMENT_FORMAT_OPTIONS: {
  [key in Exclude<ElementFormatType, ''>]: {
    icon: string;
    iconRTL: string;
    name: string;
  };
} = {
  center: {
    icon: 'center-align',
    iconRTL: 'center-align',
    name: 'Center Align',
  },
  end: {
    icon: 'right-align',
    iconRTL: 'left-align',
    name: 'End Align',
  },
  justify: {
    icon: 'justify-align',
    iconRTL: 'justify-align',
    name: 'Justify Align',
  },
  left: {
    icon: 'left-align',
    iconRTL: 'left-align',
    name: 'Left Align',
  },
  right: {
    icon: 'right-align',
    iconRTL: 'right-align',
    name: 'Right Align',
  },
  start: {
    icon: 'left-align',
    iconRTL: 'right-align',
    name: 'Start Align',
  },
};

function dropDownActiveClass(active: boolean) {
  if (active) {
    return 'active dropdown-item-active';
  } else {
    return '';
  }
}

function BlockFormatDropDown({
  editor,
  blockType,
  disabled = false,
}: {
  blockType: keyof typeof blockTypeToBlockName;
  rootType: keyof typeof rootTypeToRootName;
  editor: LexicalEditor;
  disabled?: boolean;
}): JSX.Element {
  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
  };

  const formatHeading = (headingSize: HeadingTagType) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection();
        $setBlocksType(selection, () => $createHeadingNode(headingSize));
      });
    }
  };

  const formatBulletList = () => {
    if (blockType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      formatParagraph();
    }
  };

  const formatNumberedList = () => {
    if (blockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      formatParagraph();
    }
  };

  const formatQuote = () => {
    if (blockType !== 'quote') {
      editor.update(() => {
        const selection = $getSelection();
        $setBlocksType(selection, () => $createQuoteNode());
      });
    }
  };

  const formatCode = () => {
    if (blockType !== 'code') {
      editor.update(() => {
        let selection = $getSelection();

        if (selection !== null) {
          if (selection.isCollapsed()) {
            $setBlocksType(selection, () => $createCodeNode());
          } else {
            const textContent = selection.getTextContent();
            const codeNode = $createCodeNode();
            selection.insertNodes([codeNode]);
            selection = $getSelection();
            if ($isRangeSelection(selection)) {
              selection.insertRawText(textContent);
            }
          }
        }
      });
    }
  };

  return (
    <DropDown
      disabled={disabled}
      buttonClassName="toolbar-item block-controls"
      buttonIconName={'block-type'}
      buttonLabel={blockTypeToBlockName[blockType]}
      buttonAriaLabel="Formatting options for text style"
    >
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'paragraph')}
        onClick={formatParagraph}
      >
        <IconPilcrow size={18} stroke={1} />
        <span className="text">Normal</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'h1')}
        onClick={() => formatHeading('h1')}
      >
        <IconH1 size={18} stroke={1} />
        <span className="text">Heading 1</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'h2')}
        onClick={() => formatHeading('h2')}
      >
        <IconH2 size={18} stroke={1} />
        <span className="text">Heading 2</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'h3')}
        onClick={() => formatHeading('h3')}
      >
        <IconH3 size={18} stroke={1} />
        <span className="text">Heading 3</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'bullet')}
        onClick={formatBulletList}
      >
        <IconList size={18} stroke={1} />
        <span className="text">Bullet List</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'number')}
        onClick={formatNumberedList}
      >
        <IconListNumbers size={18} stroke={1} />
        <span className="text">Numbered List</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'quote')}
        onClick={formatQuote}
      >
        <IconBlockquote size={18} stroke={1} />
        <span className="text dropdown-item-text">Quote</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'code')}
        onClick={formatCode}
      >
        <IconCode size={18} stroke={1} />
        <span className="text">Code Block</span>
      </DropDownItem>
    </DropDown>
  );
}

function Divider(): JSX.Element {
  return <div className="divider" />;
}

function FontDropDown({
  editor,
  value,
  style,
  disabled = false,
}: {
  editor: LexicalEditor;
  value: string;
  style: string;
  disabled?: boolean;
}): JSX.Element {
  const handleClick = useCallback(
    (option: string) => {
      editor.update(() => {
        const selection = $getSelection();
        if (selection !== null) {
          $patchStyleText(selection, {
            [style]: option,
          });
        }
      });
    },
    [editor, style]
  );

  return (
    <DropDown
      disabled={disabled}
      buttonClassName={`toolbar-item ${style}`}
      buttonLabel={value}
      buttonIconName="font-family"
      buttonAriaLabel="Formatting options for font family"
    >
      {(style === 'font-family' ? FONT_FAMILY_OPTIONS : FONT_SIZE_OPTIONS).map(
        ([option, text]) => (
          <DropDownItem
            className={`item ${dropDownActiveClass(value === option)} ${
              style === 'font-size' ? 'fontsize-item' : ''
            }`}
            onClick={() => handleClick(option)}
            key={option}
          >
            <span className="text">{text}</span>
          </DropDownItem>
        )
      )}
    </DropDown>
  );
}

function ElementFormatDropdown({
  editor,
  value,
  isRTL,
  disabled = false,
}: {
  editor: LexicalEditor;
  value: ElementFormatType;
  isRTL: boolean;
  disabled: boolean;
}) {
  const formatOption = ELEMENT_FORMAT_OPTIONS[value || 'left'];

  return (
    <DropDown
      disabled={disabled}
      buttonLabel={formatOption.name}
      buttonIconName={`icon ${
        isRTL ? formatOption.iconRTL : formatOption.icon
      }`}
      buttonClassName="toolbar-item spaced alignment"
      buttonAriaLabel="Formatting options for text alignment"
    >
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
        }}
        className="item"
      >
        <IconAlignLeft size={18} stroke={1} />
        <span className="text">Left Align</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
        }}
        className="item"
      >
        <IconAlignCenter size={18} stroke={1} />
        <span className="text">Center Align</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
        }}
        className="item"
      >
        <IconAlignRight size={18} stroke={1} />
        <span className="text">Right Align</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');
        }}
        className="item"
      >
        <IconAlignJustified size={18} stroke={1} />
        <span className="text">Justify Align</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'start');
        }}
        className="item"
      >
        {isRTL ? (
          <IconAlignRight size={18} stroke={1} />
        ) : (
          <IconAlignLeft size={18} stroke={1} />
        )}
        <span className="text">Start Align</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'end');
        }}
        className="item"
      >
        {isRTL ? (
          <IconAlignLeft size={18} stroke={1} />
        ) : (
          <IconAlignRight size={18} stroke={1} />
        )}
        <span className="text">End Align</span>
      </DropDownItem>
      <Divider />
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
        }}
        className="item"
      >
        {isRTL ? (
          <IconIndentIncrease size={18} stroke={1} />
        ) : (
          <IconIndentDecrease size={18} stroke={1} />
        )}
        <span className="text">Outdent</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
        }}
        className="item"
      >
        {isRTL ? (
          <IconIndentDecrease size={18} stroke={1} />
        ) : (
          <IconIndentIncrease size={18} stroke={1} />
        )}
        <span className="text">Indent</span>
      </DropDownItem>
    </DropDown>
  );
}

function AdmonitionDropdown({
  isEditable,
  isLink,
}: {
  isEditable: boolean;
  isLink: boolean;
}): JSX.Element {
  const [editor] = useLexicalComposerContext();
  return (
    <DropDown
      disabled={!isEditable || isLink}
      buttonClassName="toolbar-item spaced"
      buttonAriaLabel="Insert admonition"
      buttonIconName="admonitions"
    >
      <DropDownItem
        onClick={() => {
          insertAdmonition(editor, 'note');
        }}
        className="item"
      >
        <IconPencilExclamation
          size={18}
          stroke={1}
          className="admonition-note"
        />
        <span className="text">Note</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          insertAdmonition(editor, 'info');
        }}
        className="item"
      >
        <IconInfoSquare size={18} stroke={1} className="admonition-info" />
        <span className="text">Info</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          insertAdmonition(editor, 'tip');
        }}
        className="item"
      >
        <IconBulb size={18} stroke={1} className="admonition-tip" />
        <span className="text">Tip</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          insertAdmonition(editor, 'question');
        }}
        className="item"
      >
        <IconQuestionMark
          size={18}
          stroke={1}
          className="admonition-question"
        />
        <span className="text">Question</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          insertAdmonition(editor, 'warning');
        }}
        className="item"
      >
        <IconAlertTriangle
          size={18}
          stroke={1}
          className="admonition-warning"
        />
        <span className="text">Warning</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          insertAdmonition(editor, 'danger');
        }}
        className="item"
      >
        <IconHandStop size={18} stroke={1} className="admonition-danger" />
        <span className="text">Danger</span>
      </DropDownItem>
    </DropDown>
  );
}

export default function ToolbarPlugin({
  setIsLinkEditMode,
}: {
  setIsLinkEditMode: Dispatch<boolean>;
}): JSX.Element {
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);
  const [blockType, setBlockType] = useState<keyof typeof blockTypeToBlockName>(
    'paragraph'
  );
  const [rootType, setRootType] = useState<keyof typeof rootTypeToRootName>(
    'root'
  );
  const [selectedElementKey, setSelectedElementKey] = useState<NodeKey | null>(
    null
  );
  const [fontSize, setFontSize] = useState<string>('15px');
  const [fontColor, setFontColor] = useState<string>('#000');
  const [bgColor, setBgColor] = useState<string>('#fff');
  const [fontFamily, setFontFamily] = useState<string>('OptumSans');
  const [elementFormat, setElementFormat] = useState<ElementFormatType>('left');
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isSubscript, setIsSubscript] = useState(false);
  const [isSuperscript, setIsSuperscript] = useState(false);
  const [isCode, setIsCode] = useState(false);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [modal, showModal] = useModal();
  const [isRTL, setIsRTL] = useState(false);
  const [codeLanguage, setCodeLanguage] = useState<string>('');
  const [isEditable, setIsEditable] = useState(() => editor.isEditable());
  const [isImageCaption, setIsImageCaption] = useState(false);

  const clearFormatting = useClearFormatting(activeEditor);
  const { settings } = useSettings();

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      if (activeEditor !== editor && $isEditorIsNestedEditor(activeEditor)) {
        const rootElement = activeEditor.getRootElement();
        setIsImageCaption(
          !!rootElement?.parentElement?.classList.contains(
            'image-caption-container'
          )
        );
      } else {
        setIsImageCaption(false);
      }

      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, e => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      setIsRTL($isParentElementRTL(selection));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }

      const tableNode = $findMatchingParent(node, $isTableNode);
      if ($isTableNode(tableNode)) {
        setRootType('table');
      } else {
        setRootType('root');
      }

      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode
          );
          const type = parentList
            ? parentList.getListType()
            : element.getListType();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          if (type in blockTypeToBlockName) {
            setBlockType(type as keyof typeof blockTypeToBlockName);
          }
          if ($isCodeNode(element)) {
            const language = element.getLanguage() as keyof typeof CODE_LANGUAGE_MAP;
            setCodeLanguage(
              language ? CODE_LANGUAGE_MAP[language] || language : ''
            );
            return;
          }
        }
      }
      // Handle buttons
      setFontColor(
        $getSelectionStyleValueForProperty(selection, 'color', '#000')
      );
      setBgColor(
        $getSelectionStyleValueForProperty(
          selection,
          'background-color',
          '#fff'
        )
      );
      setFontFamily(
        $getSelectionStyleValueForProperty(
          selection,
          'font-family',
          'OptumSans'
        )
      );
      let matchingParent;
      if ($isLinkNode(parent)) {
        // If node is a link, we need to fetch the parent paragraph node to set format
        matchingParent = $findMatchingParent(
          node,
          parentNode => $isElementNode(parentNode) && !parentNode.isInline()
        );
      }

      // If matchingParent is a valid node, pass it's format type
      setElementFormat(
        $isElementNode(matchingParent)
          ? matchingParent.getFormatType()
          : $isElementNode(node)
          ? node.getFormatType()
          : parent?.getFormatType() || 'left'
      );
    }
    if ($isRangeSelection(selection) || $isTableSelection(selection)) {
      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));
      setIsSubscript(selection.hasFormat('subscript'));
      setIsSuperscript(selection.hasFormat('superscript'));
      setIsCode(selection.hasFormat('code'));

      setFontSize(
        $getSelectionStyleValueForProperty(selection, 'font-size', '15px')
      );
    }
  }, [activeEditor, editor]);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        setActiveEditor(newEditor);
        $updateToolbar();
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor, $updateToolbar]);

  useEffect(() => {
    activeEditor.getEditorState().read(() => {
      $updateToolbar();
    });
  }, [activeEditor, $updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener(editable => {
        setIsEditable(editable);
      }),
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar();
        });
      }),
      activeEditor.registerCommand<boolean>(
        CAN_UNDO_COMMAND,
        payload => {
          setCanUndo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      activeEditor.registerCommand<boolean>(
        CAN_REDO_COMMAND,
        payload => {
          setCanRedo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      )
    );
  }, [$updateToolbar, activeEditor, editor]);

  useEffect(() => {
    return activeEditor.registerCommand(
      KEY_MODIFIER_COMMAND,
      payload => {
        const event: KeyboardEvent = payload;
        const { code, ctrlKey, metaKey } = event;

        if (code === 'KeyK' && (ctrlKey || metaKey)) {
          event.preventDefault();
          let url: string | null;
          if (!isLink) {
            setIsLinkEditMode(true);
            url = sanitizeUrl('https://');
          } else {
            setIsLinkEditMode(false);
            url = null;
          }
          return activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, url);
        }
        return false;
      },
      COMMAND_PRIORITY_NORMAL
    );
  }, [activeEditor, isLink, setIsLinkEditMode]);

  const applyStyleText = useCallback(
    (styles: Record<string, string>, skipHistoryStack?: boolean) => {
      activeEditor.update(
        () => {
          const selection = $getSelection();
          if (selection !== null) {
            $patchStyleText(selection, styles);
          }
        },
        skipHistoryStack ? { tag: 'historic' } : {}
      );
    },
    [activeEditor]
  );

  const onFontColorSelect = useCallback(
    (value: string, skipHistoryStack: boolean) => {
      applyStyleText({ color: value }, skipHistoryStack);
    },
    [applyStyleText]
  );

  const onBgColorSelect = useCallback(
    (value: string, skipHistoryStack: boolean) => {
      applyStyleText({ 'background-color': value }, skipHistoryStack);
    },
    [applyStyleText]
  );

  const insertLink = useCallback(() => {
    if (!isLink) {
      setIsLinkEditMode(true);
      activeEditor.dispatchCommand(
        TOGGLE_LINK_COMMAND,
        sanitizeUrl('https://')
      );
    } else {
      setIsLinkEditMode(false);
      activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [activeEditor, isLink, setIsLinkEditMode]);

  const onCodeLanguageSelect = useCallback(
    (value: string) => {
      activeEditor.update(() => {
        if (selectedElementKey !== null) {
          const node = $getNodeByKey(selectedElementKey);
          if ($isCodeNode(node)) {
            node.setLanguage(value);
          }
        }
      });
    },
    [activeEditor, selectedElementKey]
  );

  const canViewerSeeInsertDropdown = !isImageCaption;
  const canViewerSeeInsertCodeButton = !isImageCaption;

  return (
    <div className="toolbar">
      <button
        disabled={!canUndo || !isEditable}
        onClick={() => {
          activeEditor.dispatchCommand(UNDO_COMMAND, undefined);
        }}
        title={IS_APPLE ? 'Undo (⌘Z)' : 'Undo (Ctrl+Z)'}
        type="button"
        className="toolbar-item spaced"
        data-test-id="toolbar-item-undo"
        aria-label="Undo"
      >
        <IconArrowBackUp size={18} stroke={1} />
      </button>
      <button
        disabled={!canRedo || !isEditable}
        onClick={() => {
          activeEditor.dispatchCommand(REDO_COMMAND, undefined);
        }}
        title={IS_APPLE ? 'Redo (⇧⌘Z)' : 'Redo (Ctrl+Y)'}
        type="button"
        data-test-id="toolbar-item-redo"
        className="toolbar-item"
        aria-label="Redo"
      >
        <IconArrowForwardUp size={18} stroke={1} />
      </button>
      <Divider />
      {settings.isRichText && (
        <div className="toolbar">
          {blockType in blockTypeToBlockName && activeEditor === editor && (
            <>
              <BlockFormatDropDown
                disabled={!isEditable}
                blockType={blockType}
                rootType={rootType}
                editor={activeEditor}
              />
              <Divider />
            </>
          )}
          {blockType === 'code' ? (
            <DropDown
              disabled={!isEditable}
              buttonClassName="toolbar-item code-language"
              buttonLabel={getLanguageFriendlyName(codeLanguage)}
              buttonAriaLabel="Select language"
            >
              {CODE_LANGUAGE_OPTIONS.map(([value, name]) => {
                return (
                  <DropDownItem
                    className={joinClasses(
                      'item',
                      dropDownActiveClass(value === codeLanguage)
                    )}
                    onClick={() => onCodeLanguageSelect(value)}
                    key={value}
                  >
                    <span className="text">{name}</span>
                  </DropDownItem>
                );
              })}
            </DropDown>
          ) : (
            <>
              <FontDropDown
                disabled={!isEditable || isLink}
                style={'font-family'}
                value={fontFamily}
                editor={activeEditor}
              />
              <Divider />
              <FontSize
                disabled={!isEditable || isLink}
                selectionFontSize={fontSize.slice(0, -2)}
                editor={activeEditor}
              />
              <Divider />
              <button
                data-test-id="toolbar-item-bold"
                disabled={!isEditable}
                onClick={() => {
                  activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
                }}
                className={joinClasses(
                  'toolbar-item spaced',
                  isBold && 'active'
                )}
                title={IS_APPLE ? 'Bold (⌘B)' : 'Bold (Ctrl+B)'}
                type="button"
                aria-label={`Format text as bold. Shortcut: ${
                  IS_APPLE ? '⌘B' : 'Ctrl+B'
                }`}
              >
                <IconBold stroke={1} size={18} />
              </button>
              <button
                disabled={!isEditable}
                onClick={() => {
                  activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
                }}
                className={joinClasses(
                  'toolbar-item spaced',
                  isItalic && 'active'
                )}
                title={IS_APPLE ? 'Italic (⌘I)' : 'Italic (Ctrl+I)'}
                type="button"
                aria-label={`Format text as italics. Shortcut: ${
                  IS_APPLE ? '⌘I' : 'Ctrl+I'
                }`}
              >
                <IconItalic size={18} stroke={1} />
              </button>
              <button
                disabled={!isEditable || isLink}
                onClick={() => {
                  activeEditor.dispatchCommand(
                    FORMAT_TEXT_COMMAND,
                    'underline'
                  );
                }}
                className={joinClasses(
                  'toolbar-item spaced',
                  isUnderline && 'active'
                )}
                title={IS_APPLE ? 'Underline (⌘U)' : 'Underline (Ctrl+U)'}
                type="button"
                aria-label={`Format text to underlined. Shortcut: ${
                  IS_APPLE ? '⌘U' : 'Ctrl+U'
                }`}
              >
                <IconUnderline size={18} stroke={1} />
              </button>
              {canViewerSeeInsertCodeButton && (
                <button
                  disabled={!isEditable}
                  onClick={() => {
                    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'code');
                  }}
                  className={joinClasses(
                    'toolbar-item spaced',
                    isCode && 'active'
                  )}
                  title="Insert code line"
                  type="button"
                  aria-label="Insert code line"
                >
                  <IconCodeDots size={18} stroke={1} />
                </button>
              )}
              <button
                disabled={!isEditable}
                onClick={insertLink}
                className={joinClasses(
                  'toolbar-item spaced',
                  isLink && 'active'
                )}
                aria-label="Insert link"
                title="Insert link"
                type="button"
              >
                <IconLinkPlus size={18} stroke={1} />
              </button>
              <DropdownColorPicker
                disabled={!isEditable || isLink}
                buttonClassName="toolbar-item color-picker"
                buttonAriaLabel="Formatting text color"
                buttonIconName="font-color"
                color={fontColor}
                onChange={onFontColorSelect}
                title="text color"
              />
              <DropdownColorPicker
                disabled={!isEditable || isLink}
                buttonClassName="toolbar-item color-picker"
                buttonAriaLabel="Formatting background color"
                buttonIconName="highlight-color"
                color={bgColor}
                onChange={onBgColorSelect}
                title="bg color"
              />
              <DropDown
                disabled={!isEditable || isLink}
                buttonClassName="toolbar-item spaced"
                buttonLabel=""
                buttonAriaLabel="Formatting options for additional text styles"
                buttonIconName="dropdown-format"
              >
                <DropDownItem
                  onClick={() => {
                    activeEditor.dispatchCommand(
                      FORMAT_TEXT_COMMAND,
                      'strikethrough'
                    );
                  }}
                  className={'item ' + dropDownActiveClass(isStrikethrough)}
                  title="Strikethrough"
                  aria-label="Format text with a strikethrough"
                >
                  <IconStrikethrough size={18} stroke={1} />
                  <span className="text">Strikethrough</span>
                </DropDownItem>
                <DropDownItem
                  onClick={() => {
                    activeEditor.dispatchCommand(
                      FORMAT_TEXT_COMMAND,
                      'subscript'
                    );
                  }}
                  className={joinClasses(
                    'item',
                    dropDownActiveClass(isSubscript)
                  )}
                  title="Subscript"
                  aria-label="Format text with a subscript"
                >
                  <IconSubscript size={18} stroke={1} />
                  <span className="text">Subscript</span>
                </DropDownItem>
                <DropDownItem
                  onClick={() => {
                    activeEditor.dispatchCommand(
                      FORMAT_TEXT_COMMAND,
                      'superscript'
                    );
                  }}
                  className={joinClasses(
                    'item',
                    dropDownActiveClass(isSuperscript)
                  )}
                  title="Superscript"
                  aria-label="Format text with a superscript"
                >
                  <IconSuperscript size={18} stroke={1} />
                  <span className="text">Superscript</span>
                </DropDownItem>
                <DropDownItem
                  onClick={clearFormatting}
                  className="item"
                  title="Clear text formatting"
                  aria-label="Clear all text formatting"
                >
                  <IconClearFormatting size={18} stroke={1} />
                  <span className="text">Clear Formatting</span>
                </DropDownItem>
              </DropDown>
              {canViewerSeeInsertDropdown && (
                <>
                  <Divider />
                  <DropDown
                    disabled={!isEditable}
                    buttonClassName="toolbar-item spaced"
                    buttonLabel="Insert"
                    buttonAriaLabel="Insert specialized editor node"
                    buttonIconName="plus"
                  >
                    <DropDownItem
                      onClick={() => {
                        activeEditor.dispatchCommand(
                          INSERT_HORIZONTAL_RULE_COMMAND,
                          undefined
                        );
                      }}
                      className="item"
                    >
                      <IconLayoutDistributeHorizontal size={18} stroke={1} />
                      <span className="text">Horizontal Rule</span>
                    </DropDownItem>
                    {/* Todo: Disabling Image and Inline Image insertion for v1 of Rich Text Editor */}
                    {/*<DropDownItem*/}
                    {/*  onClick={() => {*/}
                    {/*    showModal('Insert Image', onClose => (*/}
                    {/*      <InsertImageDialog*/}
                    {/*        activeEditor={activeEditor}*/}
                    {/*        onClose={onClose}*/}
                    {/*      />*/}
                    {/*    ));*/}
                    {/*  }}*/}
                    {/*  className="item"*/}
                    {/*>*/}
                    {/*  <IconPhoto size={18} stroke={1} />*/}
                    {/*  <span className="text">Image</span>*/}
                    {/*</DropDownItem>*/}
                    {/*<DropDownItem*/}
                    {/*  onClick={() => {*/}
                    {/*    showModal('Insert Inline Image', onClose => (*/}
                    {/*      <InsertInlineImageDialog*/}
                    {/*        activeEditor={activeEditor}*/}
                    {/*        onClose={onClose}*/}
                    {/*      />*/}
                    {/*    ));*/}
                    {/*  }}*/}
                    {/*  className="item"*/}
                    {/*>*/}
                    {/*  <IconImageInPicture size={18} stroke={1} />*/}
                    {/*  <span className="text">Inline Image</span>*/}
                    {/*</DropDownItem>*/}
                    <DropDownItem
                      onClick={() => {
                        showModal('Insert Table', onClose => (
                          <InsertTableDialog
                            activeEditor={activeEditor}
                            onClose={onClose}
                          />
                        ));
                      }}
                      className="item"
                    >
                      <IconTable size={18} stroke={1} />
                      <span className="text">Table</span>
                    </DropDownItem>
                    {/* Todo: Disabling Columns Layout insertion for v1 of Rich Text Editor */}
                    {/*<DropDownItem*/}
                    {/*  onClick={() => {*/}
                    {/*    showModal('Insert Columns Layout', onClose => (*/}
                    {/*      <InsertLayoutDialog*/}
                    {/*        activeEditor={activeEditor}*/}
                    {/*        onClose={onClose}*/}
                    {/*      />*/}
                    {/*    ));*/}
                    {/*  }}*/}
                    {/*  className="item"*/}
                    {/*>*/}
                    {/*  <IconColumns3 size={18} stroke={1} />*/}
                    {/*  <span className="text">Columns Layout</span>*/}
                    {/*</DropDownItem>*/}
                  </DropDown>
                </>
              )}
            </>
          )}
          <Divider />
          <AdmonitionDropdown isEditable={isEditable} isLink={isLink} />
          <ElementFormatDropdown
            disabled={!isEditable}
            value={elementFormat}
            editor={activeEditor}
            isRTL={isRTL}
          />
        </div>
      )}
      {modal}
    </div>
  );
}
