import React, { ChangeEvent, useState, useEffect } from 'react';
import { menuClasses, SubMenu } from 'react-pro-sidebar';
import styled from 'styled-components';
import { Icon } from 'link-ui-react';
import {
  Panel,
  TextInput,
  Label,
  Dropdown,
  IDropdownItem,
  Checkbox,
  FormControl,
  Select,
  Button,
  HelperText,
} from '@uitk/react';
import { LovelySwitchStyle, StyledSwitch } from '../Toggle';
import {
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@material-ui/core';
import { linkDeveloperTheme as theme } from 'link-ui-react';
import { IDP } from 'shared/api/idx';
import SelectAskId from 'builder/components/forms/SiteForm/SelectAskId';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './SettingsPanelStyles.css';
import { getTenancies } from 'shared/api/tenancies';
import S3Image from 'builder/components/S3Image/S3Image';
import {
  previewImageTooltipContent,
  feedbackEnableTooltipContent,
  fontSizes,
  feedbackStyles,
  feedbackStyleTooltipContent,
  tenancyContent,
  IDP_VALUES_TO_USE,
} from 'builder/util/constants';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import { FieldLabel } from '../SiteStylesPanel/SiteStylesPanel';
import { getCombinedFontsForTheme } from 'builder/util/getCombinedFonts';
import {
  StyledClose,
  StyledPickerHeader,
  StyledPickerPopover,
  StyledPickerWrapper,
} from 'builder/components/GradientColorPicker/GradientColorPicker';
import { Add } from '@material-ui/icons';
import Scrollbars from 'react-custom-scrollbars';
import { useLovelySwitchStyles } from '@mui-treasury/styles/switch/lovely';
import FeedbackFieldControl from './FeedbackFieldControl';
import { StyledPanel } from 'shared/widgets/Text/TextWidgetControl';
import { ColorButtonWrapper } from 'builder/components/WidgetConfigurations/Background/Background';
import { ColorButton } from 'builder/components/ColorButton/ColorButton';
import {
  FormControl as MUIFormControl,
  Radio,
  RadioGroup,
} from '@mui/material';
import { IconSettings } from '@tabler/icons-react';
import GradientColorPicker from 'builder/components/GradientColorPicker/GradientColorPicker';
import { FeedbackFormField } from 'shared/widgets/Form/types';

const Setting = <IconSettings size={36} stroke={2} color="#2E3034" />;
export const Arrow = (
  <Icon
    icon="ArrowRightOpen"
    color="#000000"
    width={'0.8125em'}
    height={'0.9375em'}
  />
);

const StyledMenuItem = styled(SubMenu)`
  margin-top: 1em;
`;
StyledMenuItem.displayName = 'submenu';

export const MuiOptionsWrapper = styled.div`
  margin-top: -0.5rem;
  & > div {
    flex-direction: row;
  }
  & > div > div {
    flex-direction: row;
  }
  & .MuiTypography-root {
    font-family: OptumSans, sans-serif;
    font-size: 14px;
  }
`;

export const StyledTextInput = styled(TextInput)`
  font-family: OptumSans, sans-serif;
  padding: 0.5rem;
  font-size: 14px;
  margin-left: -0.5rem;
`;

export const StyledRadio = styled(Radio)`
  & > input[class^='PrivateSwitchBase-input']:checked + span {
    color: #002677;
  }
`;

const StyledHeading = styled.p`
  color: ${theme.colors.aux.black};
  font-weight: 900;
  font-size: 1.3125em;
  font-family: OptumSans, sans-serif;
`;

const StyledLabelPostLogin = styled(Label)`
  font-family: OptumSans, sans-serif;
  margin-top: 8px;
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  padding-top: 1.125em;
  padding-left: 1.25em;
  padding-bottom: 1.125em;
`;
TopSection.displayName = 'topsection';

const StyledSelect = styled(Select)`
  border-radius: 0.375rem;
  & > select {
    font-family: OptumSans, sans-serif;
    line-height: 1.25rem;
  }
`;

const CheckboxFormControl = styled(FormControl)`
  border: none;
  padding: 0;
  font-family: OptumSans, sans-serif;
`;

const StyledText = styled(Typography)`
  font-family: OptumSans, sans-serif;
`;
const UploadButtonStyles = {
  marginLeft: '-5px',
  fontFamily: 'OptumSans',
  fontSize: '13px',
};

const StyledLabel = styled(Label)`
  font-family: OptumSans, sans-serif;
  font-size: '0.9rem';
  color: '#000000';
`;

export const StyledPanelTitle = {
  'font-family': 'OptumSans',
  'font-size': '14px',
  color: '#1d1929',
  'font-weight': '700',
  display: 'block',
  'margin-left': '0.625em',
};

const StyledDropdown = styled.p`
  .ilhvDI {
    font-family: OptumSans, sans-serif;
  }
`;

const DisabledCheckbox = styled(Checkbox)`
  pointer-events: none;
  opacity: 0.6;
  color: #333333;
`;

export const DropdownStyles = styled(Dropdown)`
  .iAUsfs {
    font-family: OptumSans, sans-serif;
  }
`;

const styles = {
  width: '14.4375em',
  height: '2.5em',
  'margin-left': '-0.5em',
  'font-family': 'OptumSans',
};

const TextBoxStyles = {
  width: '17.3rem',
  height: '2.5em',
  'margin-left': '-0.5em',
  color: '#4b4d4f',
  'font-family': 'OptumSans',
};

const PrimaryLabel = {
  color: '#323334',
  fontSize: '14px',
  fontFamily: 'OptumSans',
  lineHeight: '20px',
  marginLeft: '-8px',
  paddingBottom: '0.5rem',
};

const labelStyles = {
  color: '#323334',
  fontSize: '0.875em',
  fontFamily: 'OptumSans',
  lineHeight: '1.25em',
  marginLeft: '-0.5em',
  paddingTop: '1rem',
  paddingBottom: '0.5rem',
  marginRight: '0.5em',
};

const emailLabelStyles = {
  color: '#b3b3b3',
  fontSize: '0.875em',
  fontFamily: 'OptumSans',
  lineHeight: '1.25em',
  marginLeft: '-0.5em',
};

const FontDropdowns = styled.div`
  display: flex;
  padding-bottom: 0.5rem;
  margin-left: -1rem;
`;

export const FontSelect = styled(Select)`
  font-family: OptumSans, sans-serif;
  width: 9.375rem;
  padding: 0 0.5rem 0 0.5rem;
  border-radius: 0.375rem;
  & > select {
    font-family: OptumSans, sans-serif;
    line-height: 1.25rem;
  }
`;

const StyledFieldLabel = styled(Label)`
  padding: 0 0.5rem 0 0.5rem;
  font-family: OptumSans, sans-serif;
  font-size: 0.875rem;
`;

export const FontSizeSelect = styled(Select)`
  font-family: OptumSans, sans-serif;
  width: 4.5rem;
  padding: 0 0.5rem 0 0.5rem;
  border-radius: 0.375rem;
  & > select {
    font-family: OptumSans, sans-serif;
    line-height: 1.25rem;
  }
`;

const StyleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: -16px;
  padding-right: 0.5rem;
`;

export const StyledAddItemButton = styled(Button)`
  margin-left: 5px;
  margin-top: 10px;
  font-family: OptumSans, sans-serif;
  color: #ffffff;
  background: #444444;
  &:hover,
  &:focus {
    color: #ffffff;
    background: #333333;
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 5px #333333;
    border-color: transparent;
    outline: none;
  }
  &.isPressed {
    color: #ffffff;
    background: #111111;
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 5px #111111;
  }
`;

export const StyledTooltip = styled(Tooltip)`
  padding-left: 8px;
`;
const StyledCheckboxControl = styled(FormControl)`
  font-family: OptumSans, sans-serif;
  font-size: 11pt;
`;

export const StyledFormControl = styled(FormControl)`
  border: none;
  margin-left: -0.5rem;
`;

const FeedbackColor = styled.div`
  display: flex;
  padding-top: 1em;
  paddding-bottom: 1em;
`;

const FieldWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem
  align-items: center;
  & > div {
    flex: auto;
  }
`;

const TenancySelect = styled(Select)`
  width: 17.5rem;
  height: 2.5em;
  margin: -0.5rem 0 0 -0.5rem;
  font-family: OptumSans, sans-serif;
`;

const AskIdWrapper = styled.div`
  width: 15.9rem;
  padding-top: 0.5rem;
`;

const UrlStyledTextInput = styled(TextInput)`
  font-family: OptumSans, sans-serif;
  padding: 0.5rem;
  font-size: 14px;
  margin-left: -0.5rem;
  width: 17.4rem;
  height: 2.5rem;
`;

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 350,
    fontSize: 14,
    border: '1px solid #dadde9',
    fontFamily: 'OptumSans !important',
    textAlign: 'center',
  },
}));

const MAX_FORM_LENGTH = 5;

export interface SettingsPanelProps {
  siteDetails: Site;
  pages: Array<Page>;
  idps: Array<IDP>;
  siteIdp?: string;
  provisioningStores: Array<ProvisioningStore>;
  onSubmit: (site: Site) => void;
  sendData: (site: Site) => void;
  updateSite: (site: Site) => void;
}

export const SettingsPanel: React.FC<SettingsPanelProps> = (
  settingsPanelProps: SettingsPanelProps
) => {
  const { siteDetails, siteIdp, updateSite, pages } = settingsPanelProps;
  const [isopen, setIsOpen] = useState(true);
  // const [idp, setIdp] = useState([]);
  const [tenancies, setTenancies] = useState([]);
  const [uploadedImage, setUploadedImage] = useState({ url: '', alt: '' });
  const [favIconImage, setFavIconImage] = useState({ url: '', alt: '' });
  const [postLogoutType, setPostLogoutType] = useState('page');
  const [checkedValues, setCheckedValues] = useState(
    siteDetails.siteProvisioningStores.map(id =>
      id === 2 ? 'IAMGuardian' : id === 5 ? 'None' : '3rdParty'
    )
  );

  const error = checkedValues.length === 0 ? 'Select at least one option' : '';
  const lovelyStyles = useLovelySwitchStyles();
  const [pickerAnchorEl, setPickerAnchorEl] = useState<HTMLDivElement | null>(
    null
  );
  const [postLoginPageValue, setPostLoginPageValue] = useState(
    siteDetails?.horizonpostLoginPageId
      ? siteDetails.horizonpostLoginPageId?.toString()
      : siteDetails.horizonhomepageId.toString()
  );
  const [postLogoutPage, setPostLogoutPage] = useState(
    siteDetails.postLogoutPage && siteDetails.postLogoutPage.type === 'page'
      ? siteDetails.postLogoutPage.pageId.toString()
      : siteDetails.horizonhomepageId.toString()
  );
  const pickerOpen = Boolean(pickerAnchorEl);
  const pickerId = pickerOpen ? 'simple-popover' : undefined;

  useEffect(() => {
    if (siteDetails.postLogoutPage && siteDetails.postLogoutPage.url) {
      setPostLogoutType('url');
    } else {
      setPostLogoutType('page');
      updateSite({
        ...siteDetails,
        postLogoutPage: {
          pageId: siteDetails.horizonhomepageId,
          type: 'page',
          url: '',
        },
      });
    }
    getTenancies().then(tenancies => setTenancies(tenancies));
    // disable this as of now as we are using default idp set
    // getIdps().then(idp => setIdp(idp));
  }, []);

  let idpValues = () => {
    let treevaluee: IDropdownItem[] = [];
    let i = 0;
    IDP_VALUES_TO_USE.map(id =>
      treevaluee?.push({
        id: i++,
        label: id.displayName ? id.displayName : id.alias,
        value: id.alias,
      })
    );
    return treevaluee;
  };

  let feedBackStyleDropdownValues = () => {
    let treevaluee: IDropdownItem[] = [];
    let i = 0;
    feedbackStyles.map(style =>
      treevaluee?.push({
        id: i++,
        label: style,
        value: style,
      })
    );
    return treevaluee;
  };

  const toggle = (isopen: boolean) => {
    setIsOpen(isopen);
  };

  const handleImageChange = (image: Image) => {
    setUploadedImage(image);
    updateSite({
      ...siteDetails,
      footer: {
        previewImage: {
          url: image.url,
          alt: image.alt,
        },
      },
    });
    toast('Image upload succeeded', { type: 'success', theme: 'colored' });
  };

  const handlefavIconImage = (image: Image) => {
    setFavIconImage(image);
    toast('Image upload succeeded', { type: 'success', theme: 'colored' });
  };

  const handleChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    updateSite({
      ...siteDetails,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeAskId = (e: any) => {
    updateSite({
      ...siteDetails,
      aideId: e,
    });
  };

  const onChangeIdentityProvider = (e: any) => {
    updateSite({
      ...siteDetails,
      idpHint: e.value === undefined ? '' : e.value,
      idpFlag: e.value === undefined ? 'disable' : 'enable',
    });
  };

  const handleProvisioningChange = (checked: boolean, value) => {
    if (checked) {
      if (value === 'None') {
        setCheckedValues(['None']);
        updateSite({
          ...siteDetails,
          siteProvisioningStores: [5],
        });
      }
      if (value === 'IAMGuardian') {
        setCheckedValues(
          checkedValues.filter(value => value !== 'None').concat(value)
        );
        const newProvArray = siteDetails.siteProvisioningStores.filter(
          id => id !== 5 && id !== 2
        );
        updateSite({
          ...siteDetails,
          siteProvisioningStores: [...newProvArray, 2],
        });
      }
    } else {
      setCheckedValues([
        ...checkedValues.filter(checkedValue => checkedValue !== value),
      ]);
      updateSite({
        ...siteDetails,
        siteProvisioningStores: siteDetails.siteProvisioningStores.filter(
          id => id !== 2 && id !== 5
        ),
      });
    }
  };

  const updateFeedBackAttributes = (key: keyof FeedbackFin, value: any) => {
    updateSite({
      ...siteDetails,
      header: {
        ...siteDetails.header,
        feedback: {
          ...siteDetails.header.feedback,
          [key]: value,
        },
      },
    });
  };
  const handleTenancyChange = (e: any) => {
    updateSite({
      ...siteDetails,
      tenancy: { tenantId: e.target.value },
      tenantId: e.target.value,
    });
  };
  const handlePostLoginTypeChange = (e: any) => {
    updateSite({
      ...siteDetails,
      postLoginType: e.target.value,
    });
  };
  const handlePostLogoutTypeChange = (e: any) => {
    if (e.target.value === 'page') {
      setPostLogoutType('page');
    } else {
      setPostLogoutType('url');
    }
  };
  const handlePostLoginPage = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setPostLoginPageValue(e.target.value);
    if (
      e.target.value &&
      pages.find(page => page.id === parseInt(e.target.value))
    ) {
      updateSite({
        ...siteDetails,
        horizonpostLoginPageId: parseInt(e.target.value),
      });
    } else {
      setPostLoginPageValue(siteDetails.horizonhomepageId.toString());
    }
  };

  const handlePostLogoutPage = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setPostLogoutPage(e.target.value);
    if (
      e.target.value &&
      pages.find(page => page.id === parseInt(e.target.value))
    ) {
      const postLogoutPage: PostLogoutPage = {
        pageId: parseInt(e.target.value),
        type: 'page',
        url: '',
      };
      updateSite({
        ...siteDetails,
        postLogoutPage: postLogoutPage,
      });
    } else {
      setPostLogoutPage('');
    }
  };

  const handleUrl = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    updateSite({
      ...siteDetails,
      postLoginUrl: e.target.value,
    });
  };

  const handlePostLogoutUrl = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const postLogoutPage: PostLogoutPage = {
      pageId: null,
      type: 'url',
      url: e.target.value,
    };
    updateSite({
      ...siteDetails,
      postLogoutPage: postLogoutPage,
    });
  };

  const handleClick = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const toggleFeedBackWidget = (checked: boolean) => {
    updateFeedBackAttributes('feedbackEnable', checked);
  };
  const toggleFeedBackAuth = (checked: boolean) => {
    updateFeedBackAttributes('feedbackAuthentication', checked);
  };

  const onChangeFeedbackStyle = (e: any) => {
    updateFeedBackAttributes('feedbackStyle', e.value);
  };

  const handleFontChange = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    updateFeedBackAttributes('fontFamily', e.target.value);
  };
  const handleFontSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    updateFeedBackAttributes('fontSize', e.target.value);
  };

  const handleEmailListChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    updateFeedBackAttributes(
      e.target.name as keyof FeedbackFin,
      e.target.value
    );
  };

  const handleColorEditClick = (event: React.MouseEvent<any>) => {
    event.stopPropagation();
    event.preventDefault();
    setPickerAnchorEl(event.currentTarget);
  };

  const handlePickerClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const handlePickerClose = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setPickerAnchorEl(null);
  };

  const handleColorChange = newColor => {
    if (pickerAnchorEl === document.querySelector('#feedback-button-color')) {
      updateFeedBackAttributes('feedbackButtonColor', newColor);
    }
    if (pickerAnchorEl === document.querySelector('#feedback-font-color')) {
      updateFeedBackAttributes('fontColor', newColor);
    }
  };

  let getFeedbackStyle = () => {
    let returnValue = { id: 0, label: '', value: '' };
    feedBackStyleDropdownValues().map(style =>
      style.label === siteDetails.header.feedback?.feedbackStyle
        ? (returnValue = {
            id: style.id as number,
            label: style.label as string,
            value: style.value as string,
          })
        : ''
    );
    return returnValue;
  };

  const getTenancyDropdownValues = () => {
    let items: string[] = [];
    tenancies.map(tenancy => items.push(tenancy.tenantId));
    return items;
  };

  const {
    header: { feedback },
  } = siteDetails;

  const handleAddFormField = () => {
    const formFieldLength: number = feedback?.formFields.length;
    updateFeedBackAttributes('formFields', [
      ...feedback?.formFields,
      {
        label: `label-${formFieldLength + 1}`,
        key: `key-${formFieldLength + 1}`,
        validation: 'none',
        required: false,
        values: '',
      },
    ]);
  };

  const handleFormFieldChange = (index: number) => (
    formField: FeedbackFormField
  ) => {
    updateFeedBackAttributes(
      'formFields',
      feedback?.formFields.map((entry: any, innerIndex: number) => {
        if (index === innerIndex) {
          return formField;
        }
        return entry;
      })
    );
  };

  const handleDeleteFormField = (index: number) => () => {
    const fieldsAfter: Array<FeedbackFormField> = [
      ...feedback?.formFields.slice(0, index),
      ...feedback?.formFields.slice(index + 1),
    ];
    fieldsAfter.map((field, index) => (field.key = `key-${index + 1}`));

    updateFeedBackAttributes('formFields', fieldsAfter);
  };

  let addFieldbutton;
  if (feedback?.formFields && feedback?.formFields.length >= MAX_FORM_LENGTH) {
    addFieldbutton = (
      <Tooltip title="Maximum number of actions reached" placement="top">
        <div>
          <StyledAddItemButton
            variant="secondary"
            disabled
            icon={<Add />}
            data-test-id="add-item-button"
            isDisabled={!siteDetails.access.edit}
          >
            Add Feedback Field
          </StyledAddItemButton>
        </div>
      </Tooltip>
    );
  } else {
    addFieldbutton = (
      <StyledAddItemButton
        variant="secondary"
        onClick={handleAddFormField}
        icon={<Add />}
        data-test-id="add-item-button"
        disabled={!siteDetails.access.edit}
      >
        Add Feedback Field
      </StyledAddItemButton>
    );
  }

  return (
    <>
      <StyledMenuItem
        icon={Setting}
        onClick={() => toggle(true)}
        rootStyles={{
          ['& > .' + menuClasses.button]: {
            color: theme.colors.aux.white,
            '&:hover': {
              backgroundColor: theme.colors.aux.white,
            },
            '&:focus': {
              backgroundColor: '#D9F6FA',
            },
          },
          ['.' + menuClasses.subMenuContent]: {
            backgroundColor: theme.colors.aux.white,
            width: isopen ? '18.5em' : '0em',
            overflowY: 'scroll',
            margin: '-0.1875em',
          },
          [`.${menuClasses.icon}`]: {
            '&:hover': {
              backgroundColor: theme.colors.aux.skyBlue,
              fill: theme.colors.aux.darkNavyBlue,
            },
          },
        }}
        title="Settings"
        data-test-id="settings-menu-icon"
      >
        <div>
          <Scrollbars autoHeight autoHeightMin={700}>
            <TopSection data-test-id="settings-heading-section">
              <StyledHeading>Settings</StyledHeading>
              <Icon
                icon="Collapse"
                onClick={() => toggle(false)}
                width="1.375em"
                style={{ marginLeft: '4em' }}
                data-test-id="collapse-icon"
              />
            </TopSection>
            <Panel.Group>
              <StyledPanel>
                <Panel
                  title={
                    <Label style={StyledPanelTitle}>General Settings</Label>
                  }
                  initiallyClosed
                  icon={Arrow}
                  data-test-id="general-settings-panel"
                >
                  <div
                    style={
                      !siteDetails.access.edit
                        ? { pointerEvents: 'none', opacity: '0.3' }
                        : {}
                    }
                  >
                    <Label style={PrimaryLabel}>Site Name</Label>
                    <TextInput
                      name="name"
                      style={TextBoxStyles}
                      value={siteDetails?.name}
                      onChange={handleChangeField}
                      data-test-id="site-name-textbox"
                    />
                    <StyledLabelPostLogin style={PrimaryLabel}>
                      Post Login Screen
                    </StyledLabelPostLogin>
                    <MuiOptionsWrapper>
                      <MUIFormControl id={`post-login-type-radio`}>
                        <RadioGroup
                          name={`postLoginType`}
                          value={
                            siteDetails.postLoginType
                              ? siteDetails.postLoginType
                              : 'page'
                          }
                          onChange={e => handlePostLoginTypeChange(e)}
                        >
                          <FormControlLabel
                            data-test-id="post-login-type-page-radio-button"
                            value={'page'}
                            control={<StyledRadio disableRipple />}
                            label={'Page'}
                          />
                          <FormControlLabel
                            data-test-id="post-login-type-url-radio-button"
                            value={'url'}
                            control={<StyledRadio disableRipple />}
                            label={'URL'}
                          />
                        </RadioGroup>
                      </MUIFormControl>
                    </MuiOptionsWrapper>
                    {siteDetails.postLoginType === 'url' ? (
                      <UrlStyledTextInput
                        name="postLoginUrl"
                        value={siteDetails.postLoginUrl}
                        onChange={handleUrl}
                        placeholder="http:// or https://"
                        data-test-id="post-login-url-test"
                      />
                    ) : (
                      <StyledFormControl id={'post-login-type-page-radio'}>
                        <StyledSelect
                          onChange={handlePostLoginPage}
                          value={postLoginPageValue}
                          data-test-id="post-login-page-select-dropdown"
                        >
                          <option value="none">Select a Page</option>
                          {pages.map(
                            page =>
                              page.current.slug !== 'defaultContentPage' && (
                                <option key={page.id} value={page.id}>
                                  {page.current.title}
                                </option>
                              )
                          )}
                        </StyledSelect>
                      </StyledFormControl>
                    )}
                    <StyledLabelPostLogin style={PrimaryLabel}>
                      Post Logout Screen
                    </StyledLabelPostLogin>
                    <MuiOptionsWrapper>
                      <MUIFormControl id={`post-logout-type-radio`}>
                        <RadioGroup
                          name={`postLogoutType`}
                          value={postLogoutType}
                          onChange={e => handlePostLogoutTypeChange(e)}
                        >
                          <FormControlLabel
                            data-test-id="page-logout-page-radio-button"
                            value={'page'}
                            control={<StyledRadio disableRipple />}
                            label={'Page'}
                          />
                          <FormControlLabel
                            data-test-id="post-logout-url-radio-button"
                            value={'url'}
                            control={<StyledRadio disableRipple />}
                            label={'URL'}
                          />
                        </RadioGroup>
                      </MUIFormControl>
                    </MuiOptionsWrapper>
                    {postLogoutType === 'page' && (
                      <StyledFormControl id={'post-login-page-select-dropdown'}>
                        <StyledSelect
                          onChange={handlePostLogoutPage}
                          value={postLogoutPage}
                          data-test-id="post-login-page-select-dropdownn"
                        >
                          <option value="none">Select a Page</option>
                          {pages.map(
                            page =>
                              page.current.slug !== 'defaultContentPage' && (
                                <option key={page.id} value={page.id}>
                                  {page.current.title}
                                </option>
                              )
                          )}
                        </StyledSelect>
                      </StyledFormControl>
                    )}
                    {postLogoutType === 'url' && (
                      <UrlStyledTextInput
                        name="postLogoutUrl"
                        value={
                          siteDetails.postLogoutPage &&
                          siteDetails.postLogoutPage.url
                            ? siteDetails.postLogoutPage.url
                            : ''
                        }
                        onChange={handlePostLogoutUrl}
                        placeholder="http:// or https://"
                        data-test-id="post-logout-url-test"
                      />
                    )}
                    <AskIdWrapper>
                      <SelectAskId
                        input={{
                          onChange: onChangeAskId,
                          value: siteDetails?.aideId,
                        }}
                      />
                    </AskIdWrapper>
                    <Label style={labelStyles}>Tenancy</Label>
                    <HtmlTooltip
                      title={tenancyContent}
                      arrow
                      placement="bottom-start"
                      style={{ marginLeft: '8px' }}
                    >
                      <IconButton>
                        <InfoOutlinedIcon
                          fontSize="small"
                          style={{ color: 'darkblue' }}
                        />
                      </IconButton>
                    </HtmlTooltip>
                    <FormControl id="tenancy-form-control">
                      <TenancySelect
                        value={siteDetails.tenantId}
                        onChange={handleTenancyChange}
                        data-test-id="tenancy-dropdown"
                      >
                        <option value="none">None</option>
                        {getTenancyDropdownValues().map(tenancy => (
                          <option key={tenancy} value={tenancy}>
                            {tenancy}
                          </option>
                        ))}
                      </TenancySelect>
                    </FormControl>
                    <StyledLabel style={labelStyles}>Favicon</StyledLabel>
                    <div style={UploadButtonStyles}>
                      <S3Image
                        image={favIconImage}
                        label="Click to Upload"
                        data-test-id="background-image"
                        onUpload={handlefavIconImage}
                      />
                    </div>
                    <StyledLabel style={labelStyles}>
                      Site Preview Image
                    </StyledLabel>
                    <HtmlTooltip
                      title={previewImageTooltipContent}
                      arrow
                      placement="bottom-start"
                      style={{ marginLeft: '8px' }}
                    >
                      <IconButton>
                        <InfoOutlinedIcon
                          fontSize="small"
                          style={{ color: 'darkblue' }}
                        />
                      </IconButton>
                    </HtmlTooltip>
                    <div style={UploadButtonStyles}>
                      <S3Image
                        image={uploadedImage}
                        label="Click to Upload"
                        data-test-id="background-image"
                        onUpload={handleImageChange}
                      />
                    </div>
                  </div>
                </Panel>
              </StyledPanel>
              <StyledPanel>
                <Panel
                  title={<Label style={StyledPanelTitle}>Authentication</Label>}
                  initiallyClosed
                  icon={Arrow}
                  data-test-id="authentication-panel"
                >
                  <div
                    style={
                      !siteDetails.access.edit
                        ? { pointerEvents: 'none', opacity: '0.3' }
                        : {}
                    }
                  >
                    <Label style={PrimaryLabel}>Identity Provider</Label>
                    <LovelySwitchStyle
                      idpValue={idpValues()}
                      siteIdp={siteIdp}
                      idps={IDP_VALUES_TO_USE}
                      idpFlag={siteDetails.idpFlag}
                      onChangeIdentityProvider={onChangeIdentityProvider}
                    />
                  </div>
                </Panel>
              </StyledPanel>
              <StyledPanel>
                <Panel
                  title={
                    <Label style={StyledPanelTitle}>
                      Provisioning Services
                    </Label>
                  }
                  initiallyClosed
                  icon={Arrow}
                  data-test-id="provisioning-service-panel"
                >
                  <div
                    style={
                      !siteDetails.access.edit
                        ? { pointerEvents: 'none', opacity: '0.3' }
                        : {}
                    }
                  >
                    <CheckboxFormControl
                      id="checkbox-group-required"
                      error={error}
                      required
                    >
                      <Checkbox
                        data-test-id="prov-source-none-checkbox"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleProvisioningChange(
                            e.target.checked,
                            e.target.value
                          )
                        }
                        value="None"
                        checked={checkedValues.includes('None')}
                      >
                        None
                      </Checkbox>
                      <Checkbox
                        data-test-id="prov-source-iam-checkbox"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          handleProvisioningChange(
                            e.target.checked,
                            e.target.value
                          );
                        }}
                        value="IAMGuardian"
                        checked={checkedValues.includes('IAMGuardian')}
                      >
                        IAMGuardian
                      </Checkbox>
                      <DisabledCheckbox
                        data-test-id="prov-source-3p-checkbox"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleProvisioningChange(
                            e.target.checked,
                            e.target.value
                          )
                        }
                        value="3rdParty"
                        checked={checkedValues.includes('3rdParty')}
                      >
                        3rd party
                      </DisabledCheckbox>
                    </CheckboxFormControl>
                  </div>
                </Panel>
              </StyledPanel>
              <StyledPanel>
                <Panel
                  title={
                    <Label style={StyledPanelTitle}>
                      Site Feedback Settings
                    </Label>
                  }
                  initiallyClosed
                  icon={Arrow}
                  data-test-id="feedback-settings-panel"
                >
                  <div
                    style={
                      !siteDetails.access.edit
                        ? { pointerEvents: 'none', opacity: '0.3' }
                        : {}
                    }
                  >
                    <Label style={labelStyles}>Enable Feedback Tab</Label>
                    <Tooltip
                      title={feedbackEnableTooltipContent}
                      arrow
                      placement="bottom-start"
                      style={{ marginLeft: '8px' }}
                    >
                      <IconButton>
                        <InfoOutlinedIcon
                          fontSize="small"
                          style={{ color: 'darkblue' }}
                        />
                      </IconButton>
                    </Tooltip>
                    <FormGroup data-test-id="feedback-toggle-form-group">
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            classes={lovelyStyles}
                            checked={feedback?.feedbackEnable}
                            onChange={e =>
                              toggleFeedBackWidget(e.target.checked)
                            }
                            value={feedback?.feedbackEnable}
                            data-test-id="feedbackEnable-toggle-switch"
                          />
                        }
                        label={
                          feedback?.feedbackEnable ? 'Enabled' : 'Disabled'
                        }
                      />
                    </FormGroup>
                    {feedback?.feedbackEnable && (
                      <>
                        <Label style={labelStyles}>Feedback Style</Label>
                        <Tooltip
                          title={feedbackStyleTooltipContent}
                          arrow
                          placement="bottom-start"
                          style={{ marginLeft: '8px' }}
                        >
                          <IconButton>
                            <InfoOutlinedIcon
                              fontSize="small"
                              style={{ color: 'darkblue' }}
                            />
                          </IconButton>
                        </Tooltip>
                        <StyledDropdown style={styles} onClick={handleClick}>
                          <DropdownStyles
                            type="single"
                            items={feedBackStyleDropdownValues()}
                            value={getFeedbackStyle()}
                            placeholderText={
                              <StyledText>Please Select</StyledText>
                            }
                            data-test-id="feedback-style-dropdown"
                            onChange={onChangeFeedbackStyle}
                          />
                        </StyledDropdown>
                        <FeedbackColor>
                          <Label style={labelStyles}>
                            Feedback Button Color
                          </Label>
                          <ColorButtonWrapper
                            onClick={handleColorEditClick}
                            data-test-id="feedback-button-color"
                            id="feedback-button-color"
                          >
                            <ColorButton
                              color={feedback?.feedbackButtonColor}
                            />
                          </ColorButtonWrapper>
                          <StyledPickerPopover
                            data-test-id="feedback-widget-color-picker-popover"
                            id={pickerId}
                            open={pickerOpen}
                            onClick={handlePickerClick}
                            onClose={handlePickerClose}
                            anchorEl={pickerAnchorEl}
                            anchorOrigin={{
                              vertical: 'center',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'center',
                              horizontal: 'right',
                            }}
                          >
                            <StyledPickerWrapper>
                              <StyledPickerHeader data-test-id="styled-picker-header">
                                Color Selector
                              </StyledPickerHeader>
                              <StyledClose
                                data-test-id="close-icon"
                                onClick={handlePickerClose}
                              />
                            </StyledPickerWrapper>
                            <GradientColorPicker
                              data-test-id="feedback-button-color"
                              value={feedback?.feedbackButtonColor}
                              site={siteDetails}
                              handleColorChange={handleColorChange}
                              onChange={handleColorChange}
                              updateSite={updateSite}
                              saveSite={null}
                              anchorEl={pickerAnchorEl}
                            />
                          </StyledPickerPopover>
                        </FeedbackColor>
                        <Label style={labelStyles}>Feedback Font Styles</Label>
                        <FontDropdowns>
                          <FormControl
                            data-test-id="font-dropdown-form"
                            id={'controlled-select'}
                          >
                            <FieldLabel>Font Family</FieldLabel>
                            <FontSelect
                              data-test-id="font-dropdown"
                              onChange={handleFontChange}
                              value={feedback?.fontFamily}
                            >
                              {Object.keys(getCombinedFontsForTheme()).map(
                                f => {
                                  return (
                                    <option value={f} key={f}>
                                      {f}
                                    </option>
                                  );
                                }
                              )}
                            </FontSelect>
                          </FormControl>
                          <FormControl
                            data-test-id="font-size-dropdown"
                            id={'controlled-select'}
                          >
                            <FieldLabel>Font Size</FieldLabel>
                            <FontSizeSelect
                              onChange={handleFontSizeChange}
                              value={feedback?.fontSize}
                            >
                              {fontSizes.map(f => (
                                <option value={f} key={f}>
                                  {f}
                                </option>
                              ))}
                            </FontSizeSelect>
                          </FormControl>
                        </FontDropdowns>
                        <StyleWrapper>
                          <StyledFieldLabel>Font Color</StyledFieldLabel>
                          <ColorButtonWrapper
                            onClick={handleColorEditClick}
                            data-test-id="feedback-font-color"
                            id="feedback-font-color"
                          >
                            <ColorButton color={feedback?.fontColor} />
                          </ColorButtonWrapper>
                        </StyleWrapper>
                        <StyledPickerPopover
                          data-test-id="widget-color-picker-popover"
                          id={pickerId}
                          open={pickerOpen}
                          onClick={handlePickerClick}
                          onClose={handlePickerClose}
                          anchorEl={pickerAnchorEl}
                          anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                          }}
                        >
                          <StyledPickerWrapper>
                            <StyledPickerHeader data-test-id="styled-picker-header">
                              Color Selector
                            </StyledPickerHeader>
                            <StyledClose
                              data-test-id="close-icon"
                              onClick={handlePickerClose}
                            />
                          </StyledPickerWrapper>
                          <GradientColorPicker
                            value={feedback?.fontColor}
                            site={siteDetails}
                            handleColorChange={handleColorChange}
                            onChange={handleColorChange}
                            updateSite={updateSite}
                            saveSite={() => {}}
                            anchorEl={pickerAnchorEl}
                          />
                        </StyledPickerPopover>
                        <FormControl
                          id={'issueFeedBackTypeEmailListFormControl'}
                        >
                          <Label style={labelStyles}>Send Issue Email to</Label>
                          <HelperText style={emailLabelStyles}>
                            (add comma-separated values)
                          </HelperText>
                          <TextInput
                            name="issueFeedBackTypeEmailList"
                            style={TextBoxStyles}
                            value={feedback?.issueFeedBackTypeEmailList}
                            onChange={handleEmailListChange}
                            data-test-id="issue-email-textbox"
                          />
                        </FormControl>
                        <FormControl
                          id={'ideaFeedBackTypeEmailListFormControl'}
                        >
                          <Label style={labelStyles}>Send Idea Email to</Label>
                          <HelperText style={emailLabelStyles}>
                            (add comma-separated values)
                          </HelperText>
                          <TextInput
                            name="ideaFeedbackTypeEmailList"
                            style={TextBoxStyles}
                            value={feedback?.ideaFeedbackTypeEmailList}
                            onChange={handleEmailListChange}
                            data-test-id="idea-email-textbox"
                          />
                        </FormControl>
                        <FormControl id={'bugFeedBackTypeEmailListFormControl'}>
                          <Label style={labelStyles}>Send Bug Email to</Label>
                          <HelperText style={emailLabelStyles}>
                            (add comma-separated values)
                          </HelperText>
                          <TextInput
                            name="bugFeedbackTypeEmailList"
                            style={TextBoxStyles}
                            value={feedback?.bugFeedbackTypeEmailList}
                            onChange={handleEmailListChange}
                            data-test-id="bug-email-textbox"
                          />
                        </FormControl>
                        <StyledCheckboxControl id="checkbox-controlled">
                          <Checkbox
                            checked={feedback?.feedbackAuthentication === true}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              toggleFeedBackAuth(e.target.checked)
                            }
                          >
                            Authentication required?
                          </Checkbox>
                        </StyledCheckboxControl>
                        <Divider style={{ marginTop: '0.5em' }} />
                        {feedback?.formFields &&
                          feedback.formFields.length > 0 && (
                            <>
                              {feedback?.formFields.map((formField, index) => (
                                <FieldWrapper onClick={handleClick}>
                                  <FeedbackFieldControl
                                    key={index}
                                    index={index}
                                    formField={formField}
                                    onChange={handleFormFieldChange(index)}
                                    onDelete={handleDeleteFormField(index)}
                                  />
                                  {index !==
                                    feedback?.formFields.length - 1 && (
                                    <Divider />
                                  )}
                                </FieldWrapper>
                              ))}
                            </>
                          )}
                        {addFieldbutton}
                      </>
                    )}
                  </div>
                </Panel>
              </StyledPanel>
            </Panel.Group>
          </Scrollbars>
        </div>
      </StyledMenuItem>
    </>
  );
};
