import { SetStateAction, useEffect, useState } from 'react';
import ColorPicker from 'react-best-gradient-color-picker';
import styled from 'styled-components';
import useHandleColorSave from 'builder/scenes/SiteBuilder/SiteStylesPanel/useHandleColorSave';
import { defaultAndCustomColors } from 'shared/util/theme';
import { textColor } from 'builder/scenes/SiteBuilder/SiteStylesPanel/SiteStylesPanel';
import { Button } from '@uitk/react';
import { TextField, Popover } from '@material-ui/core';
import { Close } from '@material-ui/icons';

export const StyledPickerHeader = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledPickerWrapper = styled.div`
  display: flex;
  height: 100%;
  padding: 0.5rem;
  .MuiSvgIcon-root {
    display: flex;
    justify-items: flex-end;
    cursor: pointer;
  }
`;

export const StyledPickerPopover = styled(Popover)`
  border-radius: 0.375rem;
  padding: 0.5rem;
  font-family: OptumSans, sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  margin-left: 1rem;
`;

export const StyledClose = styled(Close)`
  .MuiSvgIcon-root {
    display: flex;
    justify-items: flex-end;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  padding-left: 0.25rem;
  .MuiFormControl-root {
    margin: 0.5rem;
  }
  & #color-selected {
    margin: 0.85rem 0.5rem 0.5rem 0.5rem;
  }
`;

export const ColorPip = styled.div<{ borderColor?: string }>`
  margin: 0.4rem;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 100%;
  border: 1px solid
    ${p => (p.borderColor === '' ? 'transparent' : p.borderColor)};
  cursor: pointer;
`;

export const ColorPipWrapper = styled.span`
  justify-items: center;
  display: flex;
  & #strikethrough {
    position: relative;
    border: 2px solid #e0e0e0;
    border-radius: 100%;
  }
  & #strikethrough:before {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    right: -1px;
    border-top: 2px solid;
    border-color: #ef4e63;

    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
`;

export const PaletteWrapper = styled.div`
  padding-left: 0.65em;
  display: flex;
  justify-items: center;
`;

export const DivWrapper = styled.div`
  width: 16em;
  display: flex;
  justify-items: center;
  & div {
    justify-items: center;
    display: flex;
    flex-wrap: wrap;
  }
`;

export const ButtonWrapper = styled.div`
  margin: 0.5rem;
  .uitk-button {
    font-family: OptumSans, sans-serif;
    font-weight: 700;
  }
`;

export const HoverWrapper = styled.div`
  height: 1.5em;
  display: flex;
  justify-content: space-between;
`;

export const HoverItem = styled.div`
  padding: 0 0.8em 0.8em 0.8em;
  height: 1em;
  color: #333333;
  font: OptumSans;
  font-size: 0.8em;
  font-weight: 400;
`;

export const StyledTitle = styled.div`
  margin-left: 0.8em;
  font-size: 0.9em;
  font-weight: 400;
`;

const ColorPickerWrapper = styled.div`
  padding: 0.75rem;
  & > div > div > div > div > input {
    font-family: OptumSans, sans-serif;
  }
`;

const StyledInput = styled(TextField)`
  width: 6rem;
  flex-direction: row;
  justify-items: space-evenly;
  .MuiOutlinedInput-input {
    padding: 0.35rem 0 0.35rem 0.35rem;
    font-family: OptumSans, sans-serif;
    font-size: 0.8rem;
    height: 1.75rem;
  }
  .MuiFormHelperText-root {
    font-family: OptumSans, sans-serif;
    font-size: 0.65rem;
    line-height: 1;
    letter-spacing: 0;
  }
  .MuiFormHelperText-contained {
    margin: 0.3rem 0 0 0;
  }
`;

const SaveColorButton = styled(Button)`
  width: 7.9rem;
  font-size: 0.56rem;
`;

export interface GradientColorPickerProps {
  value: string;
  onChange: (color: string) => void;
  site: Site;
  handleColorChange: (color) => void;
  updateSite: (site: Site) => void;
  saveSite: (site: Site) => void;
  anchorEl: HTMLElement | null;
}

export const GradientColorPicker: React.FC<GradientColorPickerProps> = (
  props: GradientColorPickerProps
) => {
  const [hoverName, setHoverName] = useState('');
  const [hoverValue, setHoverValue] = useState('');
  const [currentColor, setCurrentColor] = useState(props?.value);
  const [currentColorName, setCurrentColorName] = useState('');
  const [currentColorType, setCurrentColorType] = useState('');
  const [isValidHex, setIsValidHex] = useState(true);
  const [isValidName, setIsValidName] = useState(true);
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const [colors, setColors] = useState(
    defaultAndCustomColors(props.site?.theme)
  );

  useEffect(() => {
    setColors(() => defaultAndCustomColors(props.site?.theme));
  }, [props.site?.theme]);

  const enableDiv = (e: React.MouseEvent<any>, color) => {
    e.preventDefault();
    if (color === 'transparent') {
      setHoverName('Transparent');
      setHoverValue('');
    } else if (color.value.startsWith('linear')) {
      setHoverName(color.name);
      setHoverValue('Linear Gradient');
    } else if (color.value.startsWith('radial')) {
      setHoverName(color.name);
      setHoverValue('Radial Gradient');
    } else {
      setHoverName(color.name);
      setHoverValue(color.value);
    }
  };

  const disableDiv = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    setHoverName('');
    setHoverValue('');
  };

  const handleColorClick = (e: React.MouseEvent<any>, color) => {
    e.stopPropagation();
    e.preventDefault();
    if (color === 'transparent') {
      setCurrentColor('#ffffff00');
      setCurrentColorName('');
      props.handleColorChange('#ffffff00');
    } else {
      setCurrentColor(color.value);
      setCurrentColorName(color.name);
      setCurrentColorType(color.type);
      props.handleColorChange(color.value);
      setIsValidHex(true);
      setIsValidName(true);
    }
    setDisableSaveButton(true);
  };

  const handleColorFunction = (
    currentColor,
    currentColorName,
    currentColorType,
    props
  ) => {
    useHandleColorSave(currentColor, currentColorName, currentColorType, props);
  };

  const outlinePip = (color: string) => {
    if (textColor(color) === 'white') {
      return '';
    } else {
      return '#DEDFDF';
    }
  };

  const handleCurrentColorChange = (newColor: SetStateAction<string>) => {
    setCurrentColorType(undefined);
    setIsValidHex(true);
    setIsValidName(false);
    setCurrentColor(newColor);
    props.handleColorChange(newColor);
    setCurrentColorName('');
    setDisableSaveButton(false);
  };

  const handlePickerClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleNameInput = (e: React.ChangeEvent<any>) => {
    setCurrentColorName(e.currentTarget.value);
    if (
      e.currentTarget.value !== '' &&
      !colorNameExists(e.currentTarget.value)
    ) {
      setIsValidName(true);
    } else {
      setIsValidName(false);
    }
  };

  const colorNameExists = name => {
    let value = false;
    colors.forEach(color => {
      if (color.name.trim().toLowerCase() === name.trim().toLowerCase()) {
        value = true;
      }
    });
    return value;
  };

  const handleSaveColorPress = () => {
    setDisableSaveButton(true);
    handleColorFunction(
      currentColor,
      currentColorName,
      currentColorType,
      props
    );
  };

  return (
    <>
      <ColorPickerWrapper>
        <ColorPicker
          value={currentColor}
          onChange={handleCurrentColorChange}
          hidePresets
          disableDarkMode
        />
      </ColorPickerWrapper>
      <InputWrapper onClick={handlePickerClick}>
        <ColorPip
          id="color-selected"
          borderColor="#DEDFDF"
          data-test-id="color-display-circle"
          style={{ background: `${currentColor}`, cursor: 'default' }}
        />
        <StyledInput
          data-test-id="color-name-input"
          variant="outlined"
          value={currentColorName}
          onChange={handleNameInput}
          disabled={currentColorType === undefined ? false : true}
          placeholder="Name"
          error={isValidName ? false : true}
          helperText={isValidName ? '' : 'Please enter a name'}
        />
        <ButtonWrapper>
          <SaveColorButton
            data-test-id="save-new-color-button"
            disabled={
              (isValidHex ? false : true) ||
              (isValidName ? false : true) ||
              disableSaveButton
            }
            onPress={handleSaveColorPress}
          >
            Save to My Colors
          </SaveColorButton>
        </ButtonWrapper>
      </InputWrapper>
      <StyledTitle>My Colors:</StyledTitle>
      <DivWrapper>
        <PaletteWrapper data-test-id="color-palette">
          <ColorPipWrapper>
            <ColorPip
              borderColor={'transparent'}
              id="strikethrough"
              onMouseEnter={e => enableDiv(e, 'transparent')}
              onMouseOut={disableDiv}
              onClick={e => handleColorClick(e, 'transparent')}
              data-test-id="theme-display-circle"
              style={{ background: '#F2F2F2' }}
            />
          </ColorPipWrapper>
          {colors.map(color => (
            <ColorPipWrapper key={color.value}>
              <ColorPip
                borderColor={outlinePip(color.value)}
                onMouseEnter={e => enableDiv(e, color)}
                onMouseOut={disableDiv}
                onClick={e => handleColorClick(e, color)}
                data-test-id="theme-display-circle"
                style={{ background: `${color.value}` }}
              />
            </ColorPipWrapper>
          ))}
        </PaletteWrapper>
      </DivWrapper>
      <HoverWrapper>
        <HoverItem>{hoverName}</HoverItem>
        <HoverItem>{hoverValue}</HoverItem>
      </HoverWrapper>
    </>
  );
};

export default GradientColorPicker;
