import { TextFormatType } from 'lexical';
import { ExtendedTextNode } from '../plugins/ExtendableTextNode';

export function getCssPropertyFromFormat(
  format: TextFormatType | string
): string {
  switch (format) {
    case 'bold':
      return 'font-weight: bold;';
    case 'capitalize':
      return 'text-transform: capitalize;';
    case 'code':
      return 'font-family: monospace;';
    case 'highlight':
      return 'background-color: yellow;';
    case 'italic':
      return 'font-style: italic;';
    case 'lowercase':
      return 'text-transform: lowercase;';
    case 'strikethrough':
      return 'text-decoration: line-through;';
    case 'subscript':
      return 'vertical-align: sub; font-size: smaller;';
    case 'superscript':
      return 'vertical-align: super; font-size: smaller;';
    case 'underline':
      return 'text-decoration: underline;';
    case 'uppercase':
      return 'text-transform: uppercase;';
    default:
      return '';
  }
}

export function applyFormatsFromStyleString(
  node: ExtendedTextNode,
  style: string
): void {
  const formatMap: Record<string, TextFormatType> = {
    'font-weight: bold;': 'bold',
    'text-transform: capitalize;': 'capitalize',
    'font-family: monospace;': 'code',
    'background-color: yellow;': 'highlight',
    'font-style: italic;': 'italic',
    'text-transform: lowercase;': 'lowercase',
    'text-decoration: line-through;': 'strikethrough',
    'vertical-align: sub; font-size: smaller;': 'subscript',
    'vertical-align: super; font-size: smaller;': 'superscript',
    'text-decoration: underline;': 'underline',
    'text-transform: uppercase;': 'uppercase',
  };

  for (const [cssProperty, format] of Object.entries(formatMap)) {
    if (style.includes(cssProperty)) {
      node.setFormat(format);
    }
  }
}
