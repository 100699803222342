import React from 'react';
import styled from 'styled-components';
import { Panel, Label } from '@uitk/react';
import { Arrow } from 'builder/scenes/SiteBuilder/SettingsPanel/SettingsPanel';
import {
  SizeLocationData,
  WidgetSizeLocation,
} from '../../../builder/components/WidgetSizeLocation';
import { Config } from './types';
import {
  Border,
  BorderData,
} from 'builder/components/WidgetConfigurations/Border';
import {
  BackgroundData,
  Background,
} from 'builder/components/WidgetConfigurations/Background/Background';
import {
  BoxShadowData,
  BoxShadow,
} from 'builder/components/WidgetConfigurations/BoxShadow/BoxShadow';
import { StyledPanel, StyledPanelTitle } from '../Text/TextWidgetControl';
import EnableFullScreenWidth from 'builder/components/WidgetConfigurations/EnableFullScreenWidth';

const SizeLocationPanel = styled(Panel)`
  > div {
    padding: 1rem;
  }
`;

const SectionWidgetControl: React.FC<WidgetControlProps<Config>> = (
  props: WidgetControlProps<Config>
) => {
  const { value, onChange, widgetState } = props;

  const handleFullScreen = (data: boolean) => {
    onChange({
      ...value,
      enableFullScreen: data,
    });
  };

  const updateWidgetSizeLoction = (sizeLoc: SizeLocationData) => {
    onChange({
      ...value,
      width: sizeLoc.width,
      height: sizeLoc.height,
      xLocation: sizeLoc.xLocation,
      yLocation: sizeLoc.yLocation,
      uniqueId: sizeLoc.uniqueId,
    });
  };
  const updateBorderData = (data: BorderData) => {
    onChange({
      ...value,
      borderData: data,
    });
  };

  const updateShadowData = (data: BoxShadowData) => {
    onChange({
      ...value,
      boxShadowData: data,
    });
  };

  const updateBackgroundData = (data: BackgroundData) => {
    if (data.style === 'None') {
      onChange({
        ...value,
        backgroundData: {
          style: data.style,
          color: 'transparent',
        },
      });
    } else {
      onChange({
        ...value,
        backgroundData: data,
      });
    }
  };
  const updateBackgroundImage = (data: Image) => {
    onChange({
      ...value,
      backgroundImage: data,
    });
  };
  const handleLineColor = data => {
    onChange({
      ...value,
      lineColor: data,
    });
  };

  const handleBoxShadowColor = data => {
    onChange({
      ...value,
      boxShadowColor: data,
    });
  };

  const handleBackgroundColor = (data: string) => {
    if (data === 'None') {
      onChange({
        ...value,
        backgroundData: {
          style: data,
          color: 'transparent',
        },
      });
    } else {
      onChange({
        ...value,
        backgroundData: {
          ...value.backgroundData,
          color: data,
        },
      });
    }
  };

  return (
    <>
      <Panel.Group>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>General</Label>}
            icon={Arrow}
            data-test-id="general-section-panel"
          >
            <EnableFullScreenWidth
              handleEnableFullScreen={handleFullScreen}
              enableFullScreenWidth={widgetState.config.enableFullScreen}
            />
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>Background</Label>}
            icon={Arrow}
            data-test-id="provisionig-service-panel"
          >
            <Background
              data-test-id="background-component"
              backgroundData={updateBackgroundData}
              backgroundImage={updateBackgroundImage}
              handleBackgroundColor={handleBackgroundColor}
              widgetState={widgetState}
              site={props.site}
              updateSite={props.updateSite}
              saveSite={props.saveSite}
            />
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <SizeLocationPanel
            title={<Label style={StyledPanelTitle}>Size and Location</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="size-location-panel"
          >
            <WidgetSizeLocation
              activeWidgetId={props.widgetState.id}
              data-test-id="widget-size-location"
              sizeLocation={{
                xLocation: value.xLocation,
                yLocation: value.yLocation,
                width: value.width,
                height: value.height,
                uniqueId: value.uniqueId,
              }}
              updateWidgetSizeLoction={updateWidgetSizeLoction}
            />
          </SizeLocationPanel>
        </StyledPanel>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>Border</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="border-panel"
          >
            <Border
              data-test-id="border-component"
              borderData={updateBorderData}
              handleLineColor={handleLineColor}
              widgetState={widgetState}
              pages={props.pages}
              site={props.site}
              updateSite={props.updateSite}
              saveSite={props.saveSite}
            />
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>Shadow</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="shadow-panel"
          >
            <BoxShadow
              data-test-id="padding-data"
              boxShadowData={updateShadowData}
              handleBoxShadowColor={handleBoxShadowColor}
              widgetState={widgetState}
              site={props.site}
              updateSite={props.updateSite}
              saveSite={props.saveSite}
            />
          </Panel>
        </StyledPanel>
      </Panel.Group>
    </>
  );
};

export default SectionWidgetControl;
