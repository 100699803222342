import styled from 'styled-components';
import React, { ChangeEvent, ReactElement, useState } from 'react';
import { Config } from './types';
import { FormControl, RadioButton, Label } from '@uitk/react';
import { csvRegex } from 'builder/util/constants';

const StyledFieldSet = styled(FormControl)`
  font-family: OptumSans, sans-serif;
  border-width: 0;
  & > legend {
    padding: 0.25rem 0 0 0;
  }
  & > ul {
    flex-wrap: wrap;
    display: inline-flex;
    & > li {
      padding: 0 0.5rem 0.5rem 0;
      & > div > input + label:before {
        margin-right: 0.5rem;
      }
    }
  }
`;

const StyledRadioButton = styled(RadioButton)`
  cursor: default;
  margin-top: 0;
  &:checked + &:before {
    background-color: ${p => p.buttonColor} !important;
  }
  &:hover + &:before {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 5px ${p => p.buttonColor} !important;
  }
  &:active + &:before {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 5px ${p => p.buttonColor} !important;
  }
  &:focus + &:before {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 5px ${p => p.buttonColor} !important;
  }
  & > input + label {
    font-family: OptumSans, sans-serif;
    font-size: 1rem;
  }
`;

const Radio: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
): ReactElement => {
  const {
    value,
    borderData,
    lineColor,
    label,
    required,
    buttonColor,
    checkedValue,
    visible,
    isCustomWidget,
  } = props.widgetState.config;

  const [checked, setChecked] = useState(checkedValue || '');
  const [blurred, setBlurred] = useState(false);
  const error =
    checked.length === 0 && blurred && required ? 'This field is required' : '';

  const setValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { onChange } = props;
    const { config } = props.widgetState;
    setChecked(e.target.value);
    onChange(
      {
        ...config,
        checkedValue: e.target.value,
      },
      props.widgetState.id,
      props.widgetState.defaultWidget
    );
  };

  if (visible === false || isCustomWidget) {
    return <></>;
  }

  return (
    <div
      style={{
        borderWidth: `${borderData?.thickness}px`,
        borderStyle: 'solid',
        borderRadius: `${borderData?.radius}px`,
        borderColor: `${lineColor}`,
        marginTop: '.25rem',
        height: '100%',
        width: '100%',
        padding: '.25rem',
      }}
    >
      <StyledFieldSet
        key={props.widgetState.id}
        className="radio-group"
        id={`${'radio-group-controlled' + props.widgetState.id}`}
        error={error}
        required={required}
      >
        <Label>{label}</Label>
        {value &&
          value.split(csvRegex).map(item => (
            <StyledRadioButton
              buttonColor={buttonColor}
              className="radio-button"
              id="radio-button"
              key={item}
              value={item}
              onChange={setValue}
              onBlur={() => setBlurred(true)}
              checked={checked === item}
            >
              {item}
            </StyledRadioButton>
          ))}
      </StyledFieldSet>
    </div>
  );
};

export default Radio;
