import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { SmallLabelStyles } from '.././Typography/Typography';
import { ColorButtonWrapper } from '.././Background/Background';
import { ColorButton } from '../../ColorButton/ColorButton';
import {
  StyledClose,
  StyledPickerHeader,
  StyledPickerPopover,
  StyledPickerWrapper,
} from '../../GradientColorPicker/GradientColorPicker';
import { Label, TextInput } from '@uitk/react';
import GradientColorPicker from 'builder/components/GradientColorPicker/GradientColorPicker';

const PaddingInputs = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledTextInput = styled(TextInput)`
  width: 6em;
  height: 1.5em;
  margin-right: 0.75rem;
  color: #4b4d4f;
  font-family: OptumSans, sans-serif;
  font-size: 0.75rem;
`;

export const LabelStyles = styled(Label)`
  color: #1d1929;
  font-size: 12px;
  font-family: OptumSans, sans-serif;
  font-weight: 500;
  padding-right: 0.5rem;
  margin-top: 15px;
`;

export interface BoxShadowData {
  horizontal: number;
  vertical: number;
  blurRadius: number;
  spreadRadius: number;
}

export interface BoxShadowProps<T> {
  boxShadowData: (data: BoxShadowData) => void;
  widgetState: Widget<T>;
  currentTab?: number;
  handleLineColor?: (data: string) => void;
  handleBoxShadowColor?: (data: string) => void;
  site?: Site;
  updateSite?: (site: Site) => void;
  saveSite?: () => void;
}

export function getHorizontalData(widgetState, tab) {
  switch (tab) {
    case undefined:
    case 0:
      return widgetState.config?.boxShadowData?.horizontal
        ? widgetState.config?.boxShadowData?.horizontal
        : 0;
    case 1:
      return widgetState.config?.hoverData?.boxShadowData?.horizontal
        ? widgetState.config?.hoverData?.boxShadowData?.horizontal
        : 0;
    default:
  }
}

export function getVerticalData(widgetState, tab) {
  switch (tab) {
    case undefined:
    case 0:
      return widgetState.config?.boxShadowData?.vertical
        ? widgetState.config?.boxShadowData?.vertical
        : 0;
    case 1:
      return widgetState.config?.hoverData?.boxShadowData?.vertical
        ? widgetState.config?.hoverData?.boxShadowData?.vertical
        : 0;
    default:
  }
}

export function getBlurRadius(widgetState, tab) {
  switch (tab) {
    case undefined:
    case 0:
      return widgetState.config?.boxShadowData?.blurRadius
        ? widgetState.config?.boxShadowData?.blurRadius
        : 0;
    case 1:
      return widgetState.config?.hoverData?.boxShadowData?.blurRadius
        ? widgetState.config?.hoverData?.boxShadowData?.blurRadius
        : 0;
    default:
  }
}

export function getSpreadRadius(widgetState, tab) {
  switch (tab) {
    case undefined:
    case 0:
      return widgetState.config?.boxShadowData?.spreadRadius
        ? widgetState.config?.boxShadowData?.spreadRadius
        : 0;
    case 1:
      return widgetState.config?.hoverData?.boxShadowData?.spreadRadius
        ? widgetState.config?.hoverData?.boxShadowData?.spreadRadius
        : 0;
    default:
  }
}

export function getShadowColor(widgetState, tab) {
  switch (tab) {
    case undefined:
    case 0:
      return widgetState.config?.boxShadowColor;
    case 1:
      return widgetState.config?.hoverData?.boxShadowColor;
    default:
  }
}

export const BoxShadow: React.FC<BoxShadowProps<any>> = (
  props: BoxShadowProps<any>
) => {
  const {
    widgetState,
    currentTab,
    boxShadowData,
    handleBoxShadowColor,
    site,
    updateSite,
    saveSite,
  } = props;

  const data: BoxShadowData = {
    horizontal: getHorizontalData(widgetState, currentTab),
    vertical: getVerticalData(widgetState, currentTab),
    blurRadius: getBlurRadius(widgetState, currentTab),
    spreadRadius: getSpreadRadius(widgetState, currentTab),
  };
  const [shadow, setShadow] = useState(data);
  const [color, setColor] = useState(getShadowColor(widgetState, currentTab));
  const [pickerAnchorEl, setPickerAnchorEl] = useState<HTMLDivElement | null>(
    null
  );
  const pickerOpen = Boolean(pickerAnchorEl);
  const pickerId = pickerOpen ? 'simple-popover' : undefined;

  React.useEffect(() => {
    boxShadowData(shadow);
  }, [shadow]);

  React.useEffect(() => {
    handleBoxShadowColor(color);
  }, [color]);

  const handleShadowData = () => {
    boxShadowData(shadow);
  };

  const handleShadowChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setShadow(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleColorEditClick = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    e.preventDefault();
    setPickerAnchorEl(e.currentTarget);
  };

  const handlePickerClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handlePickerClose = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setPickerAnchorEl(null);
  };

  const handleColorChange = newColor => {
    if (pickerAnchorEl === document.querySelector('#boxshadow-color')) {
      setColor(newColor);
      handleBoxShadowColor(newColor);
    }
  };

  return (
    <>
      <PaddingInputs>
        <Wrapper>
          <SmallLabelStyles>Horizontal</SmallLabelStyles>
          <StyledTextInput
            name="horizontal"
            data-test-id="horizontal-text-box"
            value={shadow.horizontal}
            onChange={handleShadowChange}
            onBlur={handleShadowData}
          />
        </Wrapper>
        <Wrapper>
          <SmallLabelStyles>Vertical</SmallLabelStyles>
          <StyledTextInput
            name="vertical"
            data-test-id="vertical-text-box"
            value={shadow.vertical}
            onChange={handleShadowChange}
            onBlur={handleShadowData}
          />
        </Wrapper>
        <Wrapper>
          <SmallLabelStyles>Color</SmallLabelStyles>
          <ColorButtonWrapper
            id="boxshadow-color"
            data-test-id="boxshadow-color"
            onClick={handleColorEditClick}
          >
            <ColorButton color={props.widgetState.config.boxShadowColor} />
          </ColorButtonWrapper>
        </Wrapper>
        <StyledPickerPopover
          data-test-id="widget-color-picker-popover"
          id={pickerId}
          open={pickerOpen}
          onClick={handlePickerClick}
          onClose={handlePickerClose}
          anchorEl={pickerAnchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        >
          <StyledPickerWrapper>
            <StyledPickerHeader data-test-id="styled-picker-header">
              Color Selector
            </StyledPickerHeader>
            <StyledClose
              data-test-id="close-icon"
              onClick={handlePickerClose}
            />
          </StyledPickerWrapper>
          <GradientColorPicker
            data-test-id="divider-color"
            value={color}
            site={site}
            handleColorChange={handleColorChange}
            onChange={handleColorChange}
            updateSite={updateSite}
            saveSite={saveSite}
            anchorEl={pickerAnchorEl}
          />
        </StyledPickerPopover>
      </PaddingInputs>
      <PaddingInputs>
        <Wrapper>
          <LabelStyles>Blur Radius</LabelStyles>
          <StyledTextInput
            name="blurRadius"
            data-test-id="blur-radius-text-box"
            value={shadow.blurRadius}
            onChange={handleShadowChange}
            onBlur={handleShadowData}
          />
        </Wrapper>
        <Wrapper>
          <LabelStyles>Spread Radius</LabelStyles>
          <StyledTextInput
            name="spreadRadius"
            data-test-id="spread-radius-text-box"
            value={shadow.spreadRadius}
            onChange={handleShadowChange}
            onBlur={handleShadowData}
          />
        </Wrapper>
      </PaddingInputs>
    </>
  );
};
