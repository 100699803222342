import { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Select } from '@mui/material';
import { MenuItem } from '@material-ui/core';
import { IconCopy } from '@tabler/icons-react';
import { toast, Zoom } from 'react-toastify';
import Tooltip from 'shared/components/Tooltip';

const UrlDropdownWrapper = styled.div`
  flex: 1;
  max-width: fit-content;
  min-width: 15em;
  @media (max-width: 768px) {
    flex: 1;
    max-width: 50%;
  }
  position: relative;
  display: inline-flex;
  width: 100%;
`;

const StyledUrlDropdown = styled(Select)`
  & [data-testid='site-url-dropdown'] {
    font-family: OptumSans, sans-serif;
    white-space: normal;
  }
  font-family: OptumSans, sans-serif;
  & > div {
    text-overflow: ellipsis;
    font-family: OptumSans, sans-serif;
    margin-right: 2.5rem;
  }
  width: 100%;
`;

export const StyledIconCopy = styled(IconCopy)`
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  :hover {
    background-color: #d9f6fa;
    border-radius: 5px;
    outline: solid 1px #c7c7c7;
  }
`;

interface SiteUrlDropdownProps {
  site: Site;
}

const SiteUrlDropdown = (props: SiteUrlDropdownProps) => {
  const { site } = props;
  const [urlDropdownValue, setUrlDropdownValue] = useState(0);
  const [isCopyToastVisible, setIsCopyToastVisible] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const iconCopyRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleUrlDropdownValue = event => {
    const {
      target: { value },
    } = event;
    setUrlDropdownValue(Number(value));
  };

  const truncateUrl = (urlIndex: number) => {
    const url =
      urlIndex === 0
        ? 'Live URL: ' + getLiveDomain(site.domain)
        : 'Demo URL: ' + getDemoDomain(site.domain);
    return url.length > 55 ? url.substring(0, 55) + '...' : url;
  };

  const getDemoDomain = (siteDomain: string) => {
    let demoUrl = '';
    if (siteDomain.includes('-stage')) {
      demoUrl =
        'https://' +
        siteDomain.slice(0, siteDomain.length - 17) +
        'linkplatform-stage.com';
    } else {
      demoUrl =
        'https://' + siteDomain.slice(0, siteDomain.length - 4) + '-demo.com';
    }
    return demoUrl;
  };

  const getLiveDomain = (siteDomain: string) => {
    return 'https://' + siteDomain;
  };

  const handleCopyClick = () => {
    setTooltipOpen(false);
    if (!isCopyToastVisible) {
      navigator.clipboard.writeText(
        urlDropdownValue === 0
          ? getLiveDomain(site.domain)
          : getDemoDomain(site.domain)
      );

      const iconCopyElement = iconCopyRef.current;
      const iconCopyRect = iconCopyElement.getBoundingClientRect();
      const iconCopyX = iconCopyRect.left;

      toast('Copied to clipboard', {
        onClose: () => setIsCopyToastVisible(false),
        transition: Zoom,
        className: 'copy-url-toast',
        position: 'top-center',
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        containerId: 'custom-toast-container',
        style: {
          position: 'absolute',
          top: '-13px',
          left: `${screenWidth <= 1600 ? iconCopyX - 800 : iconCopyX - 1130}px`,
        },
      });
      setIsCopyToastVisible(true);
    }
  };

  return (
    <UrlDropdownWrapper>
      <StyledUrlDropdown
        data-test-id="site-url-dropdown"
        key={'url-dropdown'}
        value={urlDropdownValue}
        onChange={handleUrlDropdownValue}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        renderValue={(value: number) => truncateUrl(value)}
        style={{
          borderRadius: '2rem',
          height: '2.5rem',
          background: '#f7f8f8',
        }}
      >
        <MenuItem
          data-test-id="live-url"
          value={0}
          style={{ fontFamily: 'OptumSans' }}
        >
          Live URL: {getLiveDomain(site.domain)}
        </MenuItem>
        <MenuItem
          data-test-id="demo-url"
          value={1}
          style={{ fontFamily: 'OptumSans' }}
        >
          Demo URL: {getDemoDomain(site.domain)}
        </MenuItem>
      </StyledUrlDropdown>
      <Tooltip title={'Copy URL'} open={tooltipOpen}>
        <StyledIconCopy
          onClick={handleCopyClick}
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)}
          ref={iconCopyRef}
          aria-label={'Copy URL'}
        />
      </Tooltip>
    </UrlDropdownWrapper>
  );
};

export default SiteUrlDropdown;
