import React from 'react';
import { Panel, Label, FormControl, Select } from '@uitk/react';
import styled from 'styled-components';
import { Arrow } from 'builder/scenes/SiteBuilder/SettingsPanel/SettingsPanel';

import {
  SizeLocationData,
  WidgetSizeLocation,
} from '../../../builder/components/WidgetSizeLocation';
import { Config } from './types';
import {
  BackgroundData,
  Background,
} from 'builder/components/WidgetConfigurations/Background/Background';
import {
  Border,
  BorderData,
} from 'builder/components/WidgetConfigurations/Border';
import CalendarColorSelector from './CalendarColorSelector';
import { getCombinedFontsForTheme } from 'builder/util/getCombinedFonts';

const StyledPanel = styled.div`
  & button[class^='Panelcomponent__HeaderSection'] {
    height: 2.5em;
    background: rgba(141, 145, 160, 0.07);
    border-radius: 0;
    &: hover,&: focus {
      box-shadow: none;
    }
  }
  & span[class^='Panelcomponent__HeaderMainContent'] {
    height: 2.5em;
  }
  & div[class^='uitk-panel Panelcomponent__Panel'] {
    margin-bottom: 0rem;
    border: none;
  }
  & div[class^='Panelcomponent__Content'] {
    padding-top: 0rem;
  }
`;

const FontWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const FontSelect = styled(Select)`
  font-family: OptumSans, sans-serif;
  width: 9.375rem;
  margin-left: 0.6rem;
  border-radius: 0.375rem;
  & > select {
    font-family: OptumSans, sans-serif;
    line-height: 1.25rem;
  }
`;

const MainLabelStyles = styled(Label)`
  color: #1d1929;
  font-size: 14px;
  font-family: OptumSans, sans-serif;
  font-weight: 500;
  padding-right: 0.5rem;
`;

const StyledPanelTitle = {
  'font-family': 'OptumSans',
  'font-size': '14px',
  color: '#1d1929',
  'font-weight': '700',
  'margin-left': '0.8em',
};

// const labelStyles = {
//   color: '#1d1929',
//   'font-size': '12px',
//   'font-family': 'OptumSans',
//   'margin-left': '-10px',
//   'padding-top': '20px',
//   'font-weight': '500',
// };

const SizeLocationPanel = styled(Panel)`
  > div {
    padding: 1rem;
  }
`;

const CalendarWidgetControl: React.FC<WidgetControlProps<Config>> = (
  props: WidgetControlProps<Config>
) => {
  const { value, onChange, widgetState } = props;

  const updateWidgetSizeLoction = (sizeLoc: SizeLocationData) => {
    onChange({
      ...value,
      width: sizeLoc.width,
      height: sizeLoc.height,
      xLocation: sizeLoc.xLocation,
      yLocation: sizeLoc.yLocation,
      uniqueId: sizeLoc.uniqueId,
    });
  };
  const handleBackgroundColor = (data: string) => {
    onChange({
      ...value,
      backgroundData: {
        ...value.backgroundData,
        color: data,
      },
    });
  };
  const updateBackgroundData = (data: BackgroundData) => {
    onChange({
      ...value,
      backgroundData: data,
    });
  };
  const updateBackgroundImage = (data: Image) => {
    onChange({
      ...value,
      backgroundImage: data,
    });
  };
  const updateBorderData = (data: BorderData) => {
    onChange({
      ...value,
      borderData: data,
    });
  };
  const handleLineColor = data => {
    onChange({
      ...value,
      lineColor: data,
    });
  };
  const handleCalendarAccentColor = (data: string) => {
    onChange({
      ...value,
      calendarAccentColor: data,
    });
  };

  const handleFontFamily = (data: string) => {
    onChange({
      ...value,
      fontFamily: data,
    });
  };

  const handleFontChange = event => {
    handleFontFamily(event.target.value);
  };

  return (
    <div>
      <Panel.Group>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>General</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="background-panel"
          >
            <CalendarColorSelector
              data-test-id="hover-focus-panel"
              handleCalendarAccentColor={handleCalendarAccentColor}
              widgetState={widgetState}
              pages={props.pages}
              site={props.site}
              updateSite={props.updateSite}
              saveSite={props.saveSite}
            />
            <FontWrapper>
              <MainLabelStyles>Font</MainLabelStyles>
              <FormControl
                data-test-id="font-select-dropdown"
                id={'controlled-select'}
              >
                <FontSelect
                  onChange={handleFontChange}
                  value={props.value.fontFamily}
                  data-test-id="font-select"
                >
                  {Object.keys(getCombinedFontsForTheme()).map(f => {
                    return (
                      <option value={f} key={f}>
                        {f}
                      </option>
                    );
                  })}
                </FontSelect>
              </FormControl>
            </FontWrapper>
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <SizeLocationPanel
            title={<Label style={StyledPanelTitle}>Size and Location</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="sizeandlocation-panel"
          >
            <WidgetSizeLocation
              data-test-id="widget-size-location"
              sizeLocation={{
                xLocation: value.xLocation,
                yLocation: value.yLocation,
                width: value.width,
                height: value.height,
                uniqueId: value.uniqueId,
              }}
              updateWidgetSizeLoction={updateWidgetSizeLoction}
              activeWidgetId={widgetState.id}
            />
          </SizeLocationPanel>
        </StyledPanel>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>Background</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="provisionig-service-panel"
          >
            <Background
              data-test-id="background-component"
              backgroundData={updateBackgroundData}
              backgroundImage={updateBackgroundImage}
              widgetState={widgetState}
              handleBackgroundColor={handleBackgroundColor}
              site={props.site}
              updateSite={props.updateSite}
              saveSite={props.saveSite}
            />
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>Border</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="border-panel"
          >
            <Border
              data-test-id="border-component"
              borderData={updateBorderData}
              handleLineColor={handleLineColor}
              widgetState={widgetState}
              pages={props.pages}
              site={props.site}
              updateSite={props.updateSite}
              saveSite={props.saveSite}
            />
          </Panel>
        </StyledPanel>
      </Panel.Group>
    </div>
  );
};

export default CalendarWidgetControl;
