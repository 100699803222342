import React, { useState, useEffect, MouseEvent } from 'react';
import {
  StyledClose,
  StyledPickerHeader,
  StyledPickerPopover,
  StyledPickerWrapper,
} from 'builder/components/GradientColorPicker/GradientColorPicker';
import styled from 'styled-components';
import { ColorButtonWrapper } from '../Background/Background';
import { StyledLabel } from '../TextInput/TextInputPanel';
import { Config } from 'shared/widgets/AccordionWidget/types';
import { ColorLabelStyles } from '../Dropdown/DropdownPanel';
import { ColorButton } from 'builder/components/ColorButton/ColorButton';
import GradientColorPicker from 'builder/components/GradientColorPicker/GradientColorPicker';
import { toast } from 'react-toastify';

export const ColorButtonAndLabelWrapper = styled.div`
  display: flex;
`;

export interface AccordionColorSelectorProps<T> {
  pages?: Array<Page>;
  site?: Site;
  widgetState?: Widget<T>;
  updateSite?: (site: Site) => void;
  saveSite?: () => void;
  handleHoverFocusColor: (data: string) => void;
}

const AccordionColorSelector = (props: AccordionColorSelectorProps<Config>) => {
  const {
    handleHoverFocusColor,
    widgetState,
    site,
    updateSite,
    saveSite,
  } = props;

  const [hoverFocusColor, setHoverFocusColor] = useState(
    widgetState.config.hoverFocusColor
  );
  const [pickerAnchorEl, setPickerAnchorEl] = useState<HTMLDivElement | null>(
    null
  );
  const pickerOpen = Boolean(pickerAnchorEl);
  const pickerId = pickerOpen ? 'simple-popover-accordion' : undefined;

  useEffect(() => {
    setHoverFocusColor(widgetState.config.hoverFocusColor);
  }, [widgetState.config]);

  useEffect(() => {
    handleHoverFocusColor(hoverFocusColor);
  }, [hoverFocusColor]);

  const handleColorEditClick = (e: MouseEvent<any>) => {
    e.stopPropagation();
    e.preventDefault();
    setPickerAnchorEl(e.currentTarget);
  };

  const handlePickerClick = (e: MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handlePickerClose = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setPickerAnchorEl(null);
  };

  const handleColorChange = newColor => {
    if (newColor.startsWith('linear') || newColor.startsWith('radial')) {
      toast('Gradient cannot be used for hover and focus color', {
        type: 'warning',
        theme: 'colored',
      });
    } else {
      setHoverFocusColor(newColor);
    }
  };

  return (
    <>
      <ColorButtonAndLabelWrapper>
        <StyledLabel style={ColorLabelStyles}>
          Hover and Focus Color
        </StyledLabel>
        <ColorButtonWrapper
          data-test-id="color-icon"
          id="button-color"
          onClick={handleColorEditClick}
        >
          <ColorButton color={hoverFocusColor} />
        </ColorButtonWrapper>
        <StyledPickerPopover
          data-test-id="widget-color-picker-popover"
          id={pickerId}
          open={pickerOpen}
          onClick={handlePickerClick}
          onClose={handlePickerClose}
          anchorEl={pickerAnchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        >
          <StyledPickerWrapper>
            <StyledPickerHeader data-test-id="styled-picker-header">
              Color Selector
            </StyledPickerHeader>
            <StyledClose
              data-test-id="close-icon"
              onClick={handlePickerClose}
            />
          </StyledPickerWrapper>
          <GradientColorPicker
            value={hoverFocusColor}
            site={site}
            handleColorChange={handleColorChange}
            onChange={handleColorChange}
            updateSite={updateSite}
            saveSite={saveSite}
            anchorEl={pickerAnchorEl}
          />
        </StyledPickerPopover>
      </ColorButtonAndLabelWrapper>
    </>
  );
};

export default AccordionColorSelector;
