import React, { ChangeEvent, useEffect, useState } from 'react';
import { Select, FormControl } from '@uitk/react';
import styled from 'styled-components';
import {
  StyledClose,
  StyledPickerHeader,
  StyledPickerPopover,
  StyledPickerWrapper,
} from 'builder/components/GradientColorPicker/GradientColorPicker';
import { MainLabelStyles } from '../Typography/Typography';
import { ColorButtonWrapper } from '../Background/Background';
import { ColorButton } from 'builder/components/ColorButton/ColorButton';
import GradientColorPicker from 'builder/components/GradientColorPicker/GradientColorPicker';
import {
  lineDirectionOptions,
  lineThicknessOptions,
} from 'builder/util/constants';

export const DividerLabelStyle = {
  color: '#1d1929',
  'font-size': '12px',
  'font-family': 'OptumSans',
  'padding-top': '10px',
  'font-weight': '500',
};

const DividerSelect = styled(Select)`
  font-family: OptumSans, sans-serif;
  width: 9.375rem;
  border-radius: 0.375rem;
  margin-left: 20px;
  & > select {
    font-family: OptumSans, sans-serif;
    line-height: 1.25rem;
  }
`;

const LineDirectionWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
`;

const LineColorAndThicknessWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const LineThicknessSelect = styled(Select)`
  font-family: OptumSans, sans-serif;
  width: 6rem;
  padding: 0 0.5rem 0 0.5rem;
  border-radius: 0.375rem;
  padding-left: 15px;
  & > select {
    font-family: OptumSans, sans-serif;
    line-height: 1.25rem;
  }
`;

export interface DividerPanelProps {
  pages?: Array<Page>;
  site?: Site;
  updateSite?: (site: Site) => void;
  saveSite?: () => void;
  handleDirection: (data: string) => void;
  handleLineColor: (data: string) => void;
  handleThickness: (data: string) => void;
  handleColorEditClick?: () => void;
  dividerDirection: string;
  lineColor: string;
  dividerThickness: string;
  widgetState?: Widget<any>;
}

export const DividerPanel: React.FC<DividerPanelProps> = (
  dividerPanelProps: DividerPanelProps
) => {
  const {
    site,
    updateSite,
    saveSite,
    handleThickness,
    handleDirection,
    handleLineColor,
    dividerDirection,
    lineColor,
    dividerThickness,
    widgetState,
  } = dividerPanelProps;
  const [thickness, setThickness] = useState(widgetState?.config.thickness);
  const [direction, setDirection] = useState(dividerDirection);
  const [color, setColor] = useState(lineColor);
  const [pickerAnchorEl, setPickerAnchorEl] = useState<HTMLDivElement | null>(
    null
  );
  const pickerOpen = Boolean(pickerAnchorEl);
  const pickerId = pickerOpen ? 'simple-popover' : undefined;

  useEffect(() => {
    handleThickness(thickness);
  }, [thickness]);

  useEffect(() => {
    handleDirection(direction);
  }, [direction]);

  useEffect(() => {
    setColor(lineColor);
  }, [lineColor]);

  useEffect(() => {
    setThickness(dividerThickness);
  }, [dividerThickness]);

  useEffect(() => {
    setDirection(dividerDirection);
  }, [dividerDirection]);

  const handleThicknessChange = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setThickness(e.target.value);
    handleThickness(e.target.value);
  };

  const handleColorChange = newColor => {
    if (pickerAnchorEl === document.querySelector('#line-color')) {
      setColor(newColor);
      handleLineColor(newColor);
    }
  };

  const handleDirectionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setDirection(e.target.value);
    handleDirection(e.target.value);
  };

  const handlePickerClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handlePickerClose = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setPickerAnchorEl(null);
  };

  const handleColorEditClick = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    e.preventDefault();
    setPickerAnchorEl(e.currentTarget);
  };

  return (
    <>
      <LineDirectionWrapper>
        <MainLabelStyles>Direction</MainLabelStyles>
        <FormControl
          data-test-id="divider-select-dropdown"
          id={'divider-controlled-select'}
        >
          <DividerSelect
            name="dividerDirection"
            data-test-id="divider-direction"
            value={direction}
            onChange={handleDirectionChange}
          >
            {lineDirectionOptions.map(d => {
              return (
                <option value={d} key={d}>
                  {d}
                </option>
              );
            })}
          </DividerSelect>
        </FormControl>
      </LineDirectionWrapper>
      <LineColorAndThicknessWrapper>
        <MainLabelStyles>Line Color</MainLabelStyles>
        <ColorButtonWrapper
          id="line-color"
          data-test-id="line-color-button"
          onClick={handleColorEditClick}
        >
          <ColorButton color={color} />
        </ColorButtonWrapper>
        <StyledPickerPopover
          data-test-id="widget-color-picker-popover"
          id={pickerId}
          open={pickerOpen}
          onClick={handlePickerClick}
          onClose={handlePickerClose}
          anchorEl={pickerAnchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        >
          <StyledPickerWrapper>
            <StyledPickerHeader data-test-id="styled-picker-header">
              Color Selector
            </StyledPickerHeader>
            <StyledClose
              data-test-id="close-icon"
              onClick={handlePickerClose}
            />
          </StyledPickerWrapper>
          <GradientColorPicker
            data-test-id="divider-color"
            site={site}
            handleColorChange={handleColorChange}
            onChange={handleColorChange}
            updateSite={updateSite}
            saveSite={saveSite}
            anchorEl={pickerAnchorEl}
            value={color}
          />
        </StyledPickerPopover>

        <MainLabelStyles>Thickness</MainLabelStyles>
        <LineThicknessSelect
          name="dividerThickness"
          data-test-id="divider-thickness"
          value={thickness}
          onChange={handleThicknessChange}
        >
          {lineThicknessOptions.map(size => (
            <option value={size}>{size}</option>
          ))}
        </LineThicknessSelect>
      </LineColorAndThicknessWrapper>
    </>
  );
};

export default DividerPanel;
