import React, { ChangeEvent, useState, useEffect } from 'react';
import { Label, FormControl, Select } from '@uitk/react';
import { linkDeveloperTheme as theme } from 'link-ui-react';
import styled from 'styled-components';
import { getCombinedFontsForTheme } from 'builder/util/getCombinedFonts';
import {
  characterSpacingValues,
  fontSizes,
  lineSpacingValues,
} from 'builder/util/constants';
import {
  StyledClose,
  StyledPickerHeader,
  StyledPickerPopover,
  StyledPickerWrapper,
} from 'builder/components/GradientColorPicker/GradientColorPicker';
import GradientColorPicker from 'builder/components/GradientColorPicker/GradientColorPicker';
import {
  IconAlignCenter,
  IconAlignLeft,
  IconAlignRight,
  IconBold,
  IconHighlight,
  IconItalic,
  IconPalette,
  IconStrikethrough,
  IconUnderline,
} from '@tabler/icons-react';

import { toast } from 'react-toastify';

export const MainLabelStyles = styled(Label)`
  color: #1d1929;
  font-size: 14px;
  font-family: OptumSans;
  font-weight: 500;
  padding-right: 0.5rem;
`;

export const SmallLabelStyles = styled(Label)`
  color: #1d1929;
  font-size: 12px;
  font-family: OptumSans;
  font-weight: 500;
  padding-right: 0.5rem;
`;

export const FontSelect = styled(Select)`
  font-family: 'OptumSans';
  width: 9.375rem;
  margin-left: 0.6rem;
  border-radius: 0.375rem;
  & > select {
    font-family: OptumSans;
    line-height: 1.25rem;
  }
`;

export const FontSizeSelect = styled(Select)`
  font-family: OptumSans;
  width: 5.5rem;
  line-height: 1.25rem;
  padding: 0.5rem;
  border-radius: 0.375rem;
  & > select {
    font-family: OptumSans;
    line-height: 1.25rem;
  }
`;

const LineSpacingSelect = styled(Select)`
  font-family: OptumSans;
  width: 5.1rem;
  padding: 0.5rem;
  border-radius: 0.375rem;
  & > select {
    font-family: OptumSans;
    line-height: 1.25rem;
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTextWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const StyledSpacingWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const StyledAlignWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5em 0;
`;

const AlignmentIconWrapper = styled.span`
  display: flex;
  flex-direction: row;
  padding: 0 0.5rem 0 0;
`;

const StyleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
`;

export const TextIconWrapper = styled.span`
  cursor: pointer;
  font-family: 'OptumSans';
  text-align: center;
  padding: 5px 5px 5px 0;
  border-radius: 7px;
  & :hover {
    background: ${theme.colors.aux.skyBlue};
  }
`;

const PaletteIconWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  text-align: center;
`;

const StyledIcon = styled.div<{ selected: boolean }>`
  border-radius: 9px;
  height: 1.5em;
  background: ${p => (p.selected ? theme.colors.aux.skyBlue : '')};
  & :hover {
    background: ${theme.colors.aux.skyBlue};
    border-radius: 9px;
  }
`;

export interface TypographyPanelProps<T> {
  boldData: (data: Boolean) => void;
  handleFontSize: (data: string) => void;
  handleFont: (data: string) => void;
  handleCharacterSpacing?: (data: string) => void;
  handleLineSpacing?: (data: string) => void;
  handleItalicData: (data: Boolean) => void;
  handleUnderLine: (data: Boolean) => void;
  handleStrikeThrough: (data: Boolean) => void;
  handleTextColor?: (data: string) => void;
  handleTextHighlightColor?: (data: string) => void;
  leftAlignData?: (data: string) => void;
  centerAlignData?: (data: string) => void;
  rightAlignData?: (data: string) => void;
  hideAlign?: boolean;
  hideTextHighlight?: boolean;
  hideTextColor?: boolean;
  hideSpacing?: boolean;
  widgetState: Widget<T>;
  currentTab?: number;
  pages: Array<Page>;
  site?: Site;
  updateSite?: (site: Site) => void;
  saveSite?: () => void;
}

export function getBold(widgetState, tab) {
  switch (tab) {
    case undefined:
    case 0:
      return widgetState.config.bold;
    case 1:
      return widgetState.config.hoverData?.bold;
    default:
  }
}

export function getItalic(widgetState, tab) {
  switch (tab) {
    case undefined:
    case 0:
      return widgetState.config.italic;
    case 1:
      return widgetState.config.hoverData?.italic;
    default:
  }
}

export function getUnderline(widgetState, tab) {
  switch (tab) {
    case undefined:
    case 0:
      return widgetState.config.underline;
    case 1:
      return widgetState.config.hoverData?.underline;
    default:
  }
}

export function getStrikeThrough(widgetState, tab) {
  switch (tab) {
    case undefined:
    case 0:
      return widgetState.config.strikeThrough;
    case 1:
      return widgetState.config.hoverData?.strikeThrough;
    default:
  }
}

export function getTextColor(widgetState, tab): string {
  switch (tab) {
    case undefined:
    case 0:
      return widgetState.config.textColor;
    case 1:
      return widgetState.config.hoverData?.textColor;
    default:
  }
}

export function getFontFamily(widgetState, tab) {
  switch (tab) {
    case undefined:
    case 0:
      return widgetState.config.font;
    case 1:
      return widgetState.config.hoverData?.font;
    default:
  }
}

export function getFontSize(widgetState, tab) {
  switch (tab) {
    case undefined:
    case 0:
      return widgetState.config.fontSize;
    case 1:
      return widgetState.config.hoverData?.fontSize;
    default:
  }
}

export function getAlign(widgetState, tab) {
  switch (tab) {
    case undefined:
    case 0:
      return widgetState.config.align;
    case 1:
      return widgetState.config.hoverData?.align;
    default:
  }
}

export function getLineSpacing(widgetState, tab) {
  switch (tab) {
    case undefined:
    case 0:
      return widgetState.config.lineSpacing;
    case 1:
      return widgetState.config.hoverData?.lineSpacing;
    default:
  }
}

export function getCharacterSpacing(widgetState, tab) {
  switch (tab) {
    case undefined:
    case 0:
      return widgetState.config.characterSpacing;
    case 1:
      return widgetState.config.hoverData?.characterSpacing;
    default:
  }
}

export const Typography: React.FC<TypographyPanelProps<any>> = (
  typographyPanelProps: TypographyPanelProps<any>
) => {
  const {
    boldData,
    handleFontSize,
    handleFont,
    handleItalicData,
    handleUnderLine,
    handleStrikeThrough,
    handleTextColor,
    handleTextHighlightColor,
    leftAlignData,
    centerAlignData,
    rightAlignData,
    handleLineSpacing,
    handleCharacterSpacing,
    hideAlign,
    hideTextHighlight,
    widgetState,
    currentTab,
    site,
    updateSite,
    saveSite,
    hideTextColor,
    hideSpacing,
  } = typographyPanelProps;

  const [fontFamily, setFontFamily] = useState(
    getFontFamily(widgetState, currentTab)
  );
  const [fontSize, setFontSize] = useState(
    getFontSize(widgetState, currentTab)
  );
  const [lineSpacing, setLineSpacing] = useState(
    getLineSpacing(widgetState, currentTab)
  );
  const [characterSpacing, setCharacterSpacing] = useState(
    getCharacterSpacing(widgetState, currentTab)
  );
  const [bold, setBold] = useState(getBold(widgetState, currentTab));
  const [italic, setItalic] = useState(getItalic(widgetState, currentTab));
  const [underLine, setUnderLine] = useState(
    getUnderline(widgetState, currentTab)
  );

  const [strikeThrough, setStrikeThrough] = useState(
    getStrikeThrough(widgetState, currentTab)
  );
  const [textColor, setTextColor] = useState(
    getTextColor(widgetState, currentTab)
  );
  const [textHighlightColor, setTextHighlightColor] = useState(
    widgetState.config?.textHighlightColor
  );
  const [align, setAlign] = useState(getAlign(widgetState, currentTab));
  const [pickerAnchorEl, setPickerAnchorEl] = useState<HTMLDivElement | null>(
    null
  );
  const [_color, setColor] = useState(widgetState.config?.iconBackgroundColor);

  useEffect(() => {
    boldData(bold);
  }, [bold]);

  useEffect(() => {
    handleFontSize(fontSize);
  }, [fontSize]);

  useEffect(() => {
    handleFont(fontFamily);
  }, [fontFamily]);

  useEffect(() => {
    if (handleLineSpacing) {
      handleLineSpacing(lineSpacing);
    }
  }, [lineSpacing]);

  useEffect(() => {
    if (handleCharacterSpacing) {
      handleCharacterSpacing(characterSpacing);
    }
  }, [characterSpacing]);

  useEffect(() => {
    handleItalicData(italic);
  }, [italic]);

  useEffect(() => {
    handleUnderLine(underLine);
  }, [underLine]);

  useEffect(() => {
    handleStrikeThrough(strikeThrough);
  }, [strikeThrough]);

  useEffect(() => {
    if (
      handleTextColor ||
      (widgetState.type !== 'LoginButton' &&
        !(textColor.startsWith('linear') || textColor.startsWith('radial')))
    ) {
      handleTextColor(textColor);
    }
  }, [textColor]);

  useEffect(() => {
    if (handleTextHighlightColor) {
      handleTextHighlightColor(textHighlightColor);
    }
  }, [textHighlightColor]);

  useEffect(() => {
    if (align) {
      switch (align) {
        case 'left':
          leftAlignData(align);
          break;
        case 'right':
          rightAlignData(align);
          break;
        case 'center':
          centerAlignData(align);
          break;
        default:
      }
    }
  }, [align]);

  useEffect(() => {
    setItalic(getItalic(widgetState, currentTab));
    setFontFamily(getFontFamily(widgetState, currentTab));
    setFontSize(getFontSize(widgetState, currentTab));
    setLineSpacing(getLineSpacing(widgetState, currentTab));
    setCharacterSpacing(getCharacterSpacing(widgetState, currentTab));
    setBold(getBold(widgetState, currentTab));
    setUnderLine(getUnderline(widgetState, currentTab));
    setStrikeThrough(getStrikeThrough(widgetState, currentTab));
    setTextColor(getTextColor(widgetState, currentTab));
    setTextHighlightColor(widgetState.config.textHighlightColor);
    setAlign(getAlign(widgetState, currentTab));
    setColor(widgetState.config.iconBackgroundColor);
  }, [widgetState.config]);

  const pickerOpen = Boolean(pickerAnchorEl);
  const pickerId = pickerOpen ? 'simple-popover' : undefined;
  const handleBoldChange = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    setBold(!bold);
    setColor(theme.colors.aux.skyBlue);
  };
  const handleItalicChange = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    setItalic(!italic);
    setColor(theme.colors.aux.skyBlue);
  };
  const handleUnderLineChange = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    setUnderLine(!underLine);
    setColor(theme.colors.aux.skyBlue);
  };
  const handleStrikeThroughChange = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    setStrikeThrough(!strikeThrough);
    setColor(theme.colors.aux.skyBlue);
  };
  const handleLeftAlign = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    setAlign('left');
    setColor(theme.colors.aux.skyBlue);
  };
  const handleCenterAlign = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    setAlign('center');
    setColor(theme.colors.aux.skyBlue);
  };
  const handleRightAlign = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    setAlign('right');
    setColor(theme.colors.aux.skyBlue);
  };
  const handleFontChange = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setFontFamily(e.target.value);
  };
  const handleFontSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setFontSize(e.target.value);
  };
  const handleLineSpacingChange = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setLineSpacing(e.target.value);
  };
  const handleSpacing = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setCharacterSpacing(e.target.value);
  };
  const handleColorEditClick = (event: React.MouseEvent<any>) => {
    event.stopPropagation();
    event.preventDefault();
    setPickerAnchorEl(event.currentTarget);
    setColor(theme.colors.aux.skyBlue);
  };
  const handlePickerClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const handlePickerClose = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setPickerAnchorEl(null);
  };

  const handleColorChange = newColor => {
    if (pickerAnchorEl === document.querySelector('#text-color')) {
      if (newColor.startsWith('linear') || newColor.startsWith('radial')) {
        toast('Gradient cannot be used for text color', {
          type: 'warning',
          theme: 'colored',
        });
      } else {
        setTextColor(newColor);
      }
    }
    if (pickerAnchorEl === document.querySelector('#text-highlight-color')) {
      if (newColor.startsWith('linear') || newColor.startsWith('radial')) {
        toast('Gradient cannot be used for text color', {
          type: 'warning',
          theme: 'colored',
        });
      } else {
        setTextHighlightColor(newColor);
      }
    }
  };

  return (
    <>
      <StyledWrapper>
        <MainLabelStyles>Font</MainLabelStyles>
        <FormControl
          data-test-id="font-select-dropdown"
          id={'controlled-select'}
        >
          <FontSelect
            onChange={handleFontChange}
            value={fontFamily}
            data-test-id="font-select"
          >
            {Object.keys(getCombinedFontsForTheme()).map(f => {
              return (
                <option value={f} key={f}>
                  {f}
                </option>
              );
            })}
          </FontSelect>
        </FormControl>
        <FormControl data-test-id="fontsize-dropdown" id={'controlled-select'}>
          <FontSizeSelect
            onChange={handleFontSizeChange}
            value={fontSize}
            data-test-id="font-size-select"
          >
            {fontSizes.map(f => (
              <option value={f}>{f}pt</option>
            ))}
          </FontSizeSelect>
        </FormControl>
      </StyledWrapper>
      <StyleWrapper>
        <MainLabelStyles>Style</MainLabelStyles>
        <TextIconWrapper>
          <StyledIcon
            selected={bold}
            data-test-id="bold-icon"
            title="Bold"
            onClick={handleBoldChange}
          >
            <IconBold />
          </StyledIcon>
        </TextIconWrapper>
        <TextIconWrapper>
          <StyledIcon
            selected={italic}
            data-test-id="italic-icon"
            title="Italic"
            onClick={handleItalicChange}
          >
            <IconItalic />
          </StyledIcon>
        </TextIconWrapper>
        <TextIconWrapper>
          <StyledIcon
            selected={underLine}
            data-test-id="underline-icon"
            title="Underline"
            onClick={handleUnderLineChange}
          >
            <IconUnderline />
          </StyledIcon>
        </TextIconWrapper>
        <TextIconWrapper>
          <StyledIcon
            selected={strikeThrough}
            data-test-id="strikethrough-icon"
            title="Strikethrough"
            onClick={handleStrikeThroughChange}
          >
            <IconStrikethrough />
          </StyledIcon>
        </TextIconWrapper>
        {!hideTextColor && (
          <PaletteIconWrapper>
            <MainLabelStyles>Color</MainLabelStyles>
            <TextIconWrapper>
              <StyledIcon
                selected={
                  pickerAnchorEl === document.querySelector('#text-color')
                }
                id={'text-color'}
                data-test-id="text-color-icon"
                onClick={handleColorEditClick}
              >
                <IconPalette size={30} />
              </StyledIcon>
            </TextIconWrapper>
            {!hideTextHighlight && (
              <TextIconWrapper>
                <StyledIcon
                  selected={textHighlightColor}
                  data-test-id="text-highlight-color-icon"
                  id={'text-highlight-color'}
                  onClick={handleColorEditClick}
                >
                  <IconHighlight size={30} />
                </StyledIcon>
              </TextIconWrapper>
            )}
          </PaletteIconWrapper>
        )}
      </StyleWrapper>
      <StyledPickerPopover
        data-test-id="widget-color-picker-popover"
        id={pickerId}
        open={pickerOpen}
        onClick={handlePickerClick}
        onClose={handlePickerClose}
        anchorEl={pickerAnchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <StyledPickerWrapper>
          <StyledPickerHeader data-test-id="styled-picker-header">
            Color Selector
          </StyledPickerHeader>
          <StyledClose data-test-id="close-icon" onClick={handlePickerClose} />
        </StyledPickerWrapper>
        <GradientColorPicker
          site={site}
          handleColorChange={handleColorChange}
          updateSite={updateSite}
          saveSite={saveSite}
          anchorEl={pickerAnchorEl}
          value={textColor}
          onChange={handleColorChange}
        />
      </StyledPickerPopover>
      {!hideAlign && (
        <StyledAlignWrapper>
          <MainLabelStyles children={'Alignment'} />
          <AlignmentIconWrapper>
            <TextIconWrapper>
              <StyledIcon
                selected={align === 'left'}
                data-test-id="leftalign-icon"
                title="Align Left"
                onClick={handleLeftAlign}
              >
                <IconAlignLeft />
              </StyledIcon>
            </TextIconWrapper>
            <TextIconWrapper>
              <StyledIcon
                selected={align === 'center'}
                data-test-id="centeralign-icon"
                title="Align Center"
                onClick={handleCenterAlign}
              >
                <IconAlignCenter />
              </StyledIcon>
            </TextIconWrapper>
            <TextIconWrapper>
              <StyledIcon
                selected={align === 'right'}
                data-test-id="rightalign-icon"
                title="Align Right"
                onClick={handleRightAlign}
              >
                <IconAlignRight />
              </StyledIcon>
            </TextIconWrapper>
          </AlignmentIconWrapper>
        </StyledAlignWrapper>
      )}
      {!hideSpacing && (
        <>
          <StyledTextWrapper>
            <MainLabelStyles>Spacing</MainLabelStyles>
            <StyledSpacingWrapper>
              <LineSpacingSelect
                onChange={handleLineSpacingChange}
                value={lineSpacing}
                data-test-id="linespacing-dropdown"
              >
                {lineSpacingValues.map(f => (
                  <option value={f}>{f}</option>
                ))}
              </LineSpacingSelect>
              <SmallLabelStyles>Line</SmallLabelStyles>
            </StyledSpacingWrapper>
            <StyledSpacingWrapper>
              <LineSpacingSelect
                onChange={handleSpacing}
                value={characterSpacing}
                data-test-id="characterspacing-dropdown"
              >
                {characterSpacingValues.flatMap(f => (
                  <option value={f}>{f}</option>
                ))}
              </LineSpacingSelect>
              <SmallLabelStyles>Character</SmallLabelStyles>
            </StyledSpacingWrapper>
          </StyledTextWrapper>
        </>
      )}
    </>
  );
};
