export const TEMPLATE_EDITOR_SITE_ID = 99999999999999;
export const TEMPLATE_EDITOR_PAGE_ID = 99999999999999;
export const TEMPLATE_EDITOR_SITE_NAME = 'FAKE_SITE_FOR_TEMPLATE_EDITING';
export const TEMPLATE_STARTER_NAME = 'Template Starter';

export enum TEMPLATE_STATUS {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  DELETED = 'DELETED',
  ARCHIVED = 'ARCHIVED',
}
