/*
The StandardAppList.tsx file is a React component that renders a list of application tiles.
Each tile is represented by a card that includes the application's logo, title, and description. The tiles are styled using styled-components.
The StandardAppList component receives an array of tiles as a prop. 
Each tile object includes properties like tileId, themeColor, image, internalName, emulationEnabled, and targetURL.
The component maps over the tiles array and for each tile, it creates a StyledCard that includes an InnerAppWrapper, LogoContainer, AppBody, AppTitleWrapper, AppTitle, AppDescription, and AppLaunch button.
The AppLaunch button opens the application in a new browser tab when clicked. If the application does not support emulation and impersonation is active, the button is disabled and the description indicates that the application does not support emulation.
The component also receives impersonationActive and cardStyle as props. The cardStyle prop affects the styling of the card and the impersonationActive prop determines whether the application supports emulation.
The component uses media queries to adjust the styling based on the screen size. The appListSize prop, which is derived from the length of the tiles array, is also used to adjust the styling of the component.
*/

import { FC, Fragment } from 'react';
import styled from 'styled-components';
import Typography from 'shared/components/Typography';
import { EnhancedTile } from '../types';
import { ScreenSizeBreakpoints } from 'shared/constants';
import { Button, Card } from '@uitk/react';
import { defaultTheme as theme } from 'link-ui-react';

const AppBody = styled.p`
  display: block;
  position: absolute;
  bottom: 12px;
  transition: bottom '0.2s';
  left: 0;
  right: 0;
  height: 66%;
  padding-top: 20px;
`;

const LogoContainer = styled.div`
  display: grid;
  place-items: center;
  width: 90px;
`;
// const LogoContainer = styled.div`
//   display: flex;
//   justify-content: center; /* Horizontally center items */
//   align-items: flex-start; /* Align items to the start of the container (top) */
//   height: 100%; /* Set the container height to the full height of the parent div */
//   position: relative; /* Required for absolute positioning of the image */
// `;

const Logo = styled.img`
  margin: auto;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`;

const StyledCard = styled.div`
  font-family: OptumSans, sans-serif;
  margin-top: -2.75rem;
`;

const InnerAppWrapper = styled(Card)<{
  themeColor: string;
  appListSize: number;
  disabled: boolean;
  cardStyle: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 10px;
  ${p => (p.disabled ? theme.colors.aux.grey : p.themeColor)};
  width: ${p => (p.appListSize >= 5 ? '320px' : '320px')};
  height: ${p =>
    !p.cardStyle || p.cardStyle === 'Spacious' ? '408px' : '18rem'};
  position: relative;
  padding-top: 85px;
  margin-top: 65px;
  background: #ffffff;
  box-shadow: 0px 4px 8px #e3e3e3;
  &:hover {
    box-shadow: 0 0 8px 0 ${p => p.themeColor};
  }

  @media (max-width: ${ScreenSizeBreakpoints.medium}px) {
    width: 228px;
    height: 208px;
  }
`;

const AppTitleWrapper = styled.div<{
  themeColor: string;
  cardStyle: string;
}>`
  color: ${theme.colors.aux.black};
  text-align: center;
  width: 100%;
  height: ${p => (!p.cardStyle || p.cardStyle === 'Spacious' ? '18%' : '25%')};
  margin-top: ${p =>
    !p.cardStyle || p.cardStyle === 'Spacious' ? '0rem' : '3rem'};
  background: transparent;

  @media (max-width: ${ScreenSizeBreakpoints.medium}px) {
    position: relative;
    bottom: -10px;
  }
`;

const AppTitle = styled(Typography)<{ appListSize: number }>`
  font-family: OptumSans, sans-serif;
  color: ${theme.colors.aux.black};
  text-align: center;
  height: 35px;
  padding: ${p => (p.appListSize >= 5 ? '0 14px' : '0 8px')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  && {
    line-height: 20px;
    font-size: 18px;
    font-weight: 700;
    height: 100%;

    @media (max-width: ${ScreenSizeBreakpoints.small}px) {
      line-height: 40px;
    }

    @media (max-width: ${ScreenSizeBreakpoints.medium}px) {
      padding: 0 14px;
      line-height: normal;
    }
  }
`;

const AppDescription = styled(Typography)<{ appListSize: number }>`
  font-family: 'OptumSans' !important;
  -webkit-line-clamp: ${p => (p.appListSize >= 5 ? '4' : '3')};
  -webkit-box-orient: vertical;
  width: 100%;
  text-align: left;
  color: ${theme.colors.aux.black};
  background: none;
  padding: 0rem 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  height: ${p => (p.appListSize >= 5 ? '65%' : '70%')};
  &:hover {
    white-space: normal;
  }
  @media (max-width: ${ScreenSizeBreakpoints.medium}px) {
    height: 55%;
    -webkit-line-clamp: 4;
  }
`;

const AppLaunch = styled(Button)<{ themeColor: string; disabled: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 25px;
  left: 30px;
  right: 0px;
  width: 80%;
  color: #282a2e;
  text-align: center;
  font-family: 'OptumSans-Regular', 'OptumSans', 'sans-serif';
  line-height: 30px;
  text-decoration: none;
  padding: 8px 16px 8px 16px;
  &:hover,
  &:focus {
    cursor: pointer;
    background: ${p => p.themeColor};
    color: #fff;
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 5px ${p => p.themeColor};
    border: 0;
  }
  font-weight: normal;
`;

const StandardAppList: FC<{
  tiles: Array<EnhancedTile>;
  handleClick: (e: MouseEvent, tile: EnhancedTile) => any;
  impersonationActive: boolean;
  borderColor: string;
  cardStyle: string;
}> = ({ tiles, impersonationActive, cardStyle }) => (
  <Fragment>
    {tiles.map(tile => (
      <StyledCard>
        <InnerAppWrapper
          appListSize={tiles.length}
          key={tile.tileId}
          themeColor={tile.themeColor}
          data-test-id="tile"
          disabled={impersonationActive && !tile.emulationEnabled}
          cardStyle={cardStyle}
        >
          <LogoContainer>
            <Logo data-test-id={`tile-logo-${tile.tileId}`} src={tile.image} />
          </LogoContainer>
          <AppBody>
            <AppTitleWrapper themeColor={tile.themeColor} cardStyle={cardStyle}>
              <AppTitle
                appListSize={tiles.length}
                data-test-id={`tile-title-${tile.tileId}`}
                variant="headline"
              >
                {tile.internalName}
              </AppTitle>
            </AppTitleWrapper>
            {(!cardStyle || cardStyle === 'Spacious') && (
              <AppDescription
                appListSize={tiles.length}
                data-test-id="tile-description"
                variant="body1"
                gutterBottom
              >
                {impersonationActive && !tile.emulationEnabled
                  ? 'Application does not support emulation.'
                  : tile.description}
              </AppDescription>
            )}
          </AppBody>
          <AppLaunch
            themeColor={tile.themeColor}
            data-test-id={`tile-launch-${tile.tileId}`}
            variant="tertiary"
            disabled={impersonationActive && !tile.emulationEnabled}
            onPress={
              (!impersonationActive || tile.emulationEnabled) &&
              (() => {
                window.open(tile.targetURL, '_blank');
              })
            }
          >
            Launch Application
          </AppLaunch>
        </InnerAppWrapper>
      </StyledCard>
    ))}
  </Fragment>
);

export default StandardAppList;
