import { Step, StepLabel, Stepper } from '@mui/material';
import {
  Dialog,
  FormControl,
  Label,
  LoadingIndicator,
  TextInput,
  Select,
} from '@uitk/react';
import { ChangeEvent, FC, useEffect } from 'react';
import { IDP } from 'shared/api/idx';
import { Button } from '@uitk/react';
import styled from 'styled-components';
import { getTenancies } from 'shared/api/tenancies';
import SelectAskId, {
  getAskIdDetails,
} from 'builder/components/forms/SiteForm/SelectAskId';
import config from 'builder/config';
import {
  BLANK_TEMPLATE_NAME,
  BRAND_THEMES,
  FORM_TEMPLATE_NAME,
  GENERAL_TEMPLATE_NAME,
  IDP_VALUES_TO_USE,
  createSiteThemeInfo,
  createSiteThemeInfo2,
} from 'builder/util/constants';
import {
  defaultTheme,
  horizonOptumTheme,
  horizonRallyTheme,
  horizonUhcTheme,
} from '../SiteStylesPanel/BrandThemes';
import ThemePreview from './ThemePreview';
import Carousel from 'nuka-carousel';
import './DialogStyles.css';
import { fetchProvStores } from 'shared/api/provisioningStores';
import templates from './templates';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormControlLabel, FormGroup } from '@material-ui/core';
import { SwitchLovely } from '../SwitchLovely';

export const StyledButton = styled(Button)`
  justify-content: center;
  width: 40%;
  & span {
    font-family: OptumSans, sans-serif;
  }
`;
export const ButtonDiv = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-evenly;
  & > button + button {
    box-shadow: 0 0 0 1px #333333;
  }
`;
const StyledDialog = styled(Dialog)`
  z-index: 1000;
  .uitk-dialog {
    font-family: OptumSans, sans-serif;
  }
  & div[class^='Dialogcomponent__Body'] {
    padding: 1rem;
  }
  .uitk-button {
    font-family: OptumSans, sans-serif;
  }
  & > div > div > div + div {
    width: 100%;
    position: relative;
  }
  #text-input {
    font-family: OptumSans, sans-serif;
  }
  & [data-testid^='asterisk-icon'] {
    display: none;
  }
  & div.uitk-dropdown > div > button + div {
    position: fixed;
    width: 15rem;
  }
`;

export const StyledLabel = styled(Label)`
  font-family: OptumSans, sans-serif;
`;
const StyledImage = styled.img`
  width: 40%;
  padding: 0.75rem 0;
  align-self: center;
`;
const StyledHeaderWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
`;
const StyledHeader = styled.h2`
  text-align: center;
  font-family: OptumSans, sans-serif;
  font-weight: 700;
  color: black;
`;
const StyledParagraph = styled.div`
  font-family: OptumSans, sans-serif;
  font-weight: 400;
  font-size: 0.95rem;
  text-align: center;
  padding: 0.5rem 1.4rem;
`;
const StyledFailureMessage = styled.p`
  font-family: OptumSans, sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  text-align: center;
  padding: 0.5rem 1.4rem;
`;
const StyledSection = styled.div`
  display: inline-flex;
`;
const StyledFormControl = styled(FormControl)`
  width: 100%;
  padding: 0.5em;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  #required-name-input-err {
    display: none;
  }
  #required-domain-input-err {
    display: inline-flex;
    font-family: OptumSans, sans-serif;
  }
  #dropdown-required-err {
    display: none;
  }
  & > div > select {
    height: 2.9rem;
    font-family: OptumSans, sans-serif;
  }
`;
const StyledTextInput = styled(TextInput)`
  font-size: 12pt;
  font-family: OptumSans, sans-serif;
`;
const AskIdWrapper = styled.div<{ error: boolean }>`
  width: 100%;
  & > div[class^='Async-Select-Paginate__menu-list'] {
    position: absolute;
    bottom: 100%;
  }
  & > div {
    padding: 0.5rem 1.75rem 0.5rem 0.75rem;
  }
  & label {
    font-size: 1rem;
    padding-bottom: 0.2rem;
    color: ${p => (p.error ? '#E91B18' : '#5a5a5a')};
  }
  & div {
    & div {
      & div {
        padding: 0.15rem;
      }
    }
  }
  .css-k45lv-control {
    background: ${p => (p.error ? '#F9F1ED' : 'none')};
    box-shadow: ${p => (p.error ? '0 0 0 1px #E91B18' : '0 0 0 1px #737373')};
  }
`;
const StyledCarouselImage = styled.img<{ isSelected: boolean }>`
  width: 33%;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 0.5rem;
  background: ${p => (p.isSelected ? '#d9f6fa' : 'none')};
  :hover {
    border: 1px solid black;
    border-radius: 10px;
  }
`;
const StyledCarouselSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
`;
const StyledCarouselDiv = styled.div`
  width: 80%;
`;
const StyledBlock = styled.div`
  width: inherit;
  height: 96px;
  display: block;
`;
const StyledIcon = styled.i`
  color: #002677;
`;
const StyledLoaderContainer = styled.div`
  height: 8rem;
`;
const SiteUrlPreview = styled.div`
  font-style: italic;
  font-family: OptumSans, sans-serif;
`;
const StyledFormGroup = styled(FormGroup)`
  padding: 0.5rem;
  width: 100%;
  & .MuiTypography-body1 {
    font-family: OptumSans, sans-serif;
  }
`;
const SitePreviewWrapper = styled.div`
  padding: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const StyledStepper = styled(Stepper)`
  padding: 0 6rem;
  .MuiStepIcon-root {
    > path {
      display: none;
    }
  }
  .MuiStepLabel-iconContainer.Mui-disabled {
    & svg {
      color: white;
      border: 1px solid #a8a8a8;
      border-radius: 50%;
    }
  }
  .MuiStepIcon-root.Mui-completed {
    color: #ff612b;
    border: 1px solid #ff612b;
    border-radius: 50%;
    background: '#ff612b';
  }
  .MuiStepIcon-text {
    display: none;
  }
  .MuiStepIcon-root.Mui-active {
    color: white;
    border: 1px solid #ff612b;
    border-radius: 50%;
  }
  .MuiStepLabel-iconContainer {
    padding-right: 0;
  }
  .MuiStep-root {
    padding: 0;
  }
  .MuiStepConnector-line {
    border-top-width: 1px;
  }
  .MuiStepLabel-label {
    display: none;
  }
  & div[class^='MuiStepConnector-root MuiStepConnector-horizontal Mui-active'] {
    > span {
      border-color: #ff612b;
      border-top-width: 1px;
    }
  }
  &
    div[class^='MuiStepConnector-root MuiStepConnector-horizontal Mui-completed'] {
    > span {
      border-color: #ff612b;
      border-top-width: 1px;
    }
  }
`;

//TODO: update URL to real FAQ page
export const FAQ_URL = 'https://www.google.com';

export const CreateSiteDetails = () => (
  <p>
    Before we can set up your site, we need just a few details. If you are
    unfamiliar with any of the fields, please visit our{' '}
    <a href={FAQ_URL}>FAQ</a> section.
  </p>
);

export function updatePageFromTemplate(template: Preset) {
  return template.widgets;
}

export interface CreateSiteDialogProps {
  open: boolean;
  sites: Array<Site>;
  idps?: Array<IDP>;
  existingDomains: Array<string>;
  existingNames: Array<string>;
  onClose: () => void;
  createSite: (site: Partial<Site>, content) => void;
  fetchIdps: () => void;
  input?: {
    onChange: <T>(event: React.ChangeEvent<T> | any) => void;
    value: string;
  };
  defaultAskIds?: Array<Result>;
  createSiteMeta: any;
  newSiteId?: number;
}

const CreateSiteDialog: FC<CreateSiteDialogProps> = (
  props: CreateSiteDialogProps
) => {
  const steps = ['1', '2', '3'];
  const [tenancies, setTenancies] = React.useState<Tenancy[]>([]);
  const [selectedTenancy, setSelectedTenancy] = React.useState(null);
  // const [idps, setIdps] = React.useState<IDP[]>([]);
  const [selectedIdp, setSelectedIdp] = React.useState(null);
  const [askId, setAskId] = React.useState<AskId>(null);
  const [askIdError, setAskIdError] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(1);
  const [brandTheme, setBrandTheme] = React.useState<Theme>(null);
  const [selectedTheme, setSelectedTheme] = React.useState(null);
  const [theme, setTheme] = React.useState('');
  const [filledOut, setFilledOut] = React.useState(false);
  const [pageTemplate, setPageTemplate] = React.useState(
    templates.find(t => t.name === 'Blank')
  );
  const [provStores, setProvStores] = React.useState<ProvisioningStore[]>([]);

  const history = useHistory();

  const [formValues, setFormValues] = React.useState({
    siteName: '',
    siteDomain: '',
    tenancy: { tenantId: '', tenantName: '' },
    idp: { alias: '', displayName: '' },
    askId: { askId: '', applicationName: '' },
    provisioningSource: [2],
  });

  const [blurred, setBlurred] = React.useState({
    siteName: false,
    siteDomain: false,
    tenancy: false,
    idp: false,
    askId: false,
    provisioningSource: false,
    theme: false,
  });

  useEffect(() => {
    if (props.createSiteMeta.successful) {
      setActiveStep(1);
      history.push(`/sites/${props.newSiteId}`);
    }
  }, [props.createSiteMeta.successful]);

  //checks if all fields are filled out
  useEffect(() => {
    const hasNoValue = (value: any) => {
      if (typeof value === 'object') {
        return Object.values(value).some(
          subValue => subValue === hasNoValue(subValue)
        );
      }
      return value === '';
    };
    const isFormCompleted = () => {
      //check all fields except tenancy and provisioningSource
      for (const [key, value] of Object.entries(formValues)) {
        if (
          key !== 'tenancy' &&
          key !== 'provisioningSource' &&
          hasNoValue(value)
        ) {
          return false;
        }
      }
      //check if provisioningSource is IAM-Guardian and tenancy is not filled out
      const provisioningCondition =
        formValues.provisioningSource.toString() !== [5].toString() &&
        (hasNoValue(formValues.tenancy.tenantId) ||
          hasNoValue(formValues.tenancy.tenantName));
      return !provisioningCondition;
    };
    setFilledOut(isFormCompleted());
  }, [formValues]);

  useEffect(() => {
    getTenancies().then(tenancies => setTenancies(tenancies));
    fetchProvStores().then(provStores => setProvStores(provStores));
    // removing idps as of now as we will be using the default idp set
    // getIdps().then(idps => setIdps(idps));
  }, []);

  const resetForm = () => {
    setFormValues({
      siteName: '',
      siteDomain: '',
      tenancy: { tenantId: '', tenantName: '' },
      idp: { alias: '', displayName: '' },
      askId: { askId: '', applicationName: '' },
      provisioningSource: [
        provStores.find(store => store.provStoreName === 'None')?.id,
      ],
    });
    setBlurred({
      siteName: false,
      siteDomain: false,
      tenancy: false,
      idp: false,
      askId: false,
      provisioningSource: false,
      theme: false,
    });
    setSelectedIdp(null);
    setSelectedTenancy(null);
    setAskId(null);
    setAskIdError(false);
    setBrandTheme(null);
    setSelectedTheme(undefined);
    setPageTemplate(templates.find(t => t.name === 'Blank'));
    setTheme('');
  };

  const siteDomainExists = (siteDomain: string) => {
    const { existingDomains } = props;
    return existingDomains
      .filter(entry => !!entry)
      .some(existingDomain => {
        const parts = existingDomain.split('.');
        if (parts.length > 0 && parts[0] === siteDomain) {
          return true;
        }
        return false;
      });
  };

  const handleSiteNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      siteName: e.target.value,
    });
  };

  const handleSiteDomainChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      siteDomain: e.target.value.toLowerCase().replace(/ +/g, '-'),
    });
  };

  const validName = new RegExp('^[a-zA-Z0-9._-]+$');

  const siteNameError = () => {
    if (formValues.siteName === '' && blurred.siteName) {
      return 'error';
    } else {
      return '';
    }
  };

  const siteDomainError = () => {
    if (formValues.siteDomain === '' && blurred.siteDomain) {
      return 'Site must have a domain';
    } else if (siteDomainExists(formValues.siteDomain)) {
      return 'Site domain is already taken';
    } else if (
      formValues.siteDomain.startsWith('-') ||
      formValues.siteDomain.endsWith('-')
    ) {
      return 'Remove leading or trailing hyphens';
    } else if (formValues.siteDomain.length > 61) {
      return 'Maximum length is 61 characters';
    } else if (
      formValues.siteDomain.length > 0 &&
      !validName.test(formValues.siteDomain)
    ) {
      return 'Can only use valid characters';
    }
    return '';
  };

  const dropdownError = (key: string) => {
    if (key === 'tenancy') {
      return selectedTenancy === null &&
        blurred.tenancy &&
        formValues.provisioningSource.includes(2)
        ? 'error'
        : '';
    }
    if (key === 'idp') {
      return selectedIdp === null && blurred.idp ? 'error' : '';
    }
    if (key === 'theme') {
      return selectedTheme === undefined && blurred.theme ? 'error' : '';
    }
  };

  const handleNext = () => {
    if (activeStep === 3) {
      const newSite: Partial<Site> = {
        aideId: formValues.askId.askId,
        name: formValues.siteName,
        clientId: formValues.siteDomain,
        domainExtension: config.inStage ? 'optumoi-stage.com' : 'optumoi.com',
        footer: { variant: 'standard' },
        header: {
          variant: 'utilityWithNav',
          logoPosition: 'left',
          utilityBgColor: '',
          navBgColor: '',
          nav: {
            fontColor: '',
            items: [],
          },
          feedback: {
            feedbackEnable: false,
          },
        },
        idpHint: formValues.idp.alias,
        domain: `${formValues.siteDomain}`,
        theme: brandTheme ? brandTheme : defaultTheme,
        siteProvisioningStores: formValues.provisioningSource,
        tenancy: formValues.tenancy.tenantId ? formValues.tenancy : null,
        tenantId: formValues.provisioningSource.includes(5)
          ? null
          : formValues.tenancy.tenantId,
      };
      props.createSite(newSite, updatePageFromTemplate(pageTemplate));
      setActiveStep(null);
      resetForm();
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 1) {
      props.onClose();
      resetForm();
    } else {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
  };

  const handleTenancyChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedTenancy(
      tenancies.find(tenancy => tenancy.tenantId === e.target.value)
    );
  };

  useEffect(() => {
    if (selectedTenancy) {
      const selectTenancy = tenancies.find(
        tenancy => tenancy.tenantId == Object.values(selectedTenancy)[0]
      );
      setFormValues({
        ...formValues,
        tenancy: {
          tenantId: selectTenancy.tenantId,
          tenantName: selectTenancy.tenantDesc,
        },
      });
    }
  }, [selectedTenancy]);

  const handleIdpChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedIdp(
      IDP_VALUES_TO_USE.find(idp => idp.displayName === e.target.value)
    );
  };

  useEffect(() => {
    if (selectedIdp) {
      const selectIdp = IDP_VALUES_TO_USE.find(
        idp => idp.displayName === selectedIdp.displayName
      );
      setFormValues({
        ...formValues,
        idp: {
          alias: selectIdp.alias,
          displayName: selectIdp.displayName,
        },
      });
    }
  }, [selectedIdp]);

  function handleThemeChange(e: ChangeEvent<HTMLSelectElement>) {
    setSelectedTheme(e.target.value);
    switch (e.target.value) {
      case 'UHC':
        setBrandTheme(horizonUhcTheme);
        setTheme('UHC');
        break;
      case 'Optum':
        setBrandTheme(horizonOptumTheme);
        setTheme('Optum');
        break;
      case 'Rally':
        setBrandTheme(horizonRallyTheme);
        setTheme('Rally');
        break;
      default:
        setBrandTheme(null);
        setTheme('Custom');
        break;
    }
  }

  const handleProvisioningChange = () => {
    setFormValues({
      ...formValues,
      provisioningSource: formValues.provisioningSource.includes(2) ? [5] : [2],
    });
  };

  const handleAskIdChange = async e => {
    setAskIdError(false);
    setAskId(e);
    const details = await getAskIdDetails(e, 0);
    details.forEach(item =>
      setFormValues({
        ...formValues,
        askId: {
          askId: item.record.askId,
          applicationName: item.record.applicationName,
        },
      })
    );
  };

  const handleBlur = () => {
    if (askId === null) {
      setAskIdError(true);
    } else {
      setAskIdError(false);
    }
    setBlurred({
      ...blurred,
      askId: true,
    });
  };

  const handleClose = () => {
    props.onClose();
    resetForm();
    setActiveStep(1);
  };

  return (
    <>
      {props.open && config.createSiteWizardEnabled && (
        <StyledDialog
          data-test-id="create-site-dialog"
          open={props.open}
          title=""
          titleAs="h3"
          onClose={handleClose}
        >
          <Dialog.Body>
            {activeStep && (
              <StyledStepper theme={theme} activeStep={activeStep - 1}>
                {steps.map(label => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: {
                    optional?: React.ReactNode;
                  } = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </StyledStepper>
            )}
            {activeStep === 1 && (
              <>
                <StyledImage
                  src={
                    'https://cdn-stage.linkhealth.com/site-builder/horizon/TemplateSelect.png'
                  }
                />
                <StyledHeader>Welcome to your new site!</StyledHeader>
                <StyledParagraph>
                  <CreateSiteDetails />
                </StyledParagraph>
                <StyledFormControl
                  id={'required-name-input'}
                  error={siteNameError()}
                >
                  <StyledLabel>Site Name</StyledLabel>
                  <StyledTextInput
                    data-test-id="site-name-input"
                    placeholder="Enter a site name"
                    onChange={handleSiteNameChange}
                    onBlur={() => setBlurred({ ...blurred, siteName: true })}
                    value={formValues.siteName}
                  />
                </StyledFormControl>
                <StyledSection>
                  <StyledFormControl
                    id={'required-domain-input'}
                    error={siteDomainError()}
                  >
                    <StyledLabel>Site Domain</StyledLabel>
                    <StyledTextInput
                      data-test-id="site-domain-input"
                      placeholder="Enter a site domain"
                      onChange={handleSiteDomainChange}
                      onBlur={() =>
                        setBlurred({ ...blurred, siteDomain: true })
                      }
                      value={formValues.siteDomain}
                    />
                  </StyledFormControl>
                  <SitePreviewWrapper>
                    <StyledLabel>Site URL Preview</StyledLabel>
                    <SiteUrlPreview>
                      {formValues.siteDomain === ''
                        ? 'your-site-domain'
                        : formValues.siteDomain}
                      {config.inStage ? '.optumoi-stage.com' : '.optumoi.com'}
                    </SiteUrlPreview>
                  </SitePreviewWrapper>
                </StyledSection>
                <StyledSection>
                  <StyledFormGroup data-test-id="feedback-toggle-form-group">
                    <StyledLabel>IAM-Guardian Provisioning</StyledLabel>
                    <FormControlLabel
                      control={
                        <SwitchLovely
                          checked={formValues.provisioningSource.includes(2)}
                          onChange={handleProvisioningChange}
                          value={formValues.provisioningSource}
                          data-test-id="iamguardian-toggle-switch"
                        />
                      }
                      label={
                        formValues.provisioningSource.includes(2)
                          ? 'Enabled'
                          : 'Disabled'
                      }
                    />
                  </StyledFormGroup>
                  <StyledFormControl
                    id="dropdown-required"
                    error={dropdownError('tenancy')}
                    disabled={formValues.provisioningSource.includes(5)}
                  >
                    <StyledLabel>Tenancy</StyledLabel>
                    <Select
                      value={selectedTenancy ? selectedTenancy.tenantId : ''}
                      onChange={handleTenancyChange}
                      onBlur={() => setBlurred({ ...blurred, tenancy: true })}
                      data-test-id="tenancy-dropdown"
                    >
                      <option value="none">Please Select</option>
                      {tenancies.map(tenancy => (
                        <option key={tenancy.tenantId} value={tenancy.tenantId}>
                          {tenancy.tenantId}
                        </option>
                      ))}
                    </Select>
                  </StyledFormControl>
                </StyledSection>
                <StyledSection>
                  <StyledFormControl
                    id="dropdown-required"
                    error={dropdownError('idp')}
                  >
                    <StyledLabel>Identity Provider</StyledLabel>
                    <Select
                      value={selectedIdp ? selectedIdp.displayName : ''}
                      onChange={handleIdpChange}
                      onBlur={() => setBlurred({ ...blurred, idp: true })}
                      data-test-id="idp-dropdown"
                    >
                      <option value="none">Please Select</option>
                      {IDP_VALUES_TO_USE.map(idp => (
                        <option key={idp.displayName} value={idp.displayName}>
                          {idp.displayName}
                        </option>
                      ))}
                    </Select>
                  </StyledFormControl>
                  <AskIdWrapper error={askIdError} onBlur={handleBlur}>
                    <SelectAskId
                      data-test-id="askid-dropdown"
                      input={{
                        onChange: handleAskIdChange,
                        value: formValues.askId.askId,
                      }}
                    />
                  </AskIdWrapper>
                </StyledSection>
              </>
            )}
            {activeStep === 2 && (
              <>
                <StyledImage
                  src={
                    'https://cdn-stage.linkhealth.com/site-builder/horizon/ThemeSelect.png'
                  }
                />
                <StyledHeaderWrapper>
                  <StyledHeader>Select your theme</StyledHeader>
                </StyledHeaderWrapper>
                <StyledParagraph>{createSiteThemeInfo}</StyledParagraph>
                <StyledParagraph>{createSiteThemeInfo2}</StyledParagraph>
                <StyledSection>
                  <StyledFormControl
                    id="dropdown-required"
                    error={dropdownError('theme')}
                  >
                    <StyledLabel>Theme</StyledLabel>
                    <Select
                      value={selectedTheme ? selectedTheme : ''}
                      onChange={handleThemeChange}
                      onBlur={() => setBlurred({ ...blurred, theme: true })}
                      data-test-id="theme-dropdown"
                    >
                      {BRAND_THEMES.map(theme => (
                        <option key={theme} value={theme}>
                          {theme}
                        </option>
                      ))}
                    </Select>
                  </StyledFormControl>
                </StyledSection>
                {brandTheme !== null ? (
                  <ThemePreview theme={brandTheme} />
                ) : (
                  <StyledBlock />
                )}
              </>
            )}
            {activeStep === 3 && (
              <>
                <StyledImage
                  src={
                    'https://cdn-stage.linkhealth.com/site-builder/horizon/TemplateSelect.png'
                  }
                />
                <div
                  style={{ display: 'inline-flex', justifyContent: 'center' }}
                >
                  <StyledHeader>Select your template</StyledHeader>
                </div>
                <StyledParagraph>
                  Here, you may select a page template to jump-start your
                  design.
                </StyledParagraph>
                <Carousel
                  renderCenterLeftControls={({ previousSlide }) => (
                    <button onClick={previousSlide}>
                      <StyledIcon className="fa-solid fa-square-arrow-left" />
                    </button>
                  )}
                  renderCenterRightControls={({ nextSlide }) => (
                    <button onClick={nextSlide}>
                      <StyledIcon className="fa-solid fa-square-arrow-right" />
                    </button>
                  )}
                  defaultControlsConfig={{
                    pagingDotsStyle: {
                      visibility: 'hidden',
                    },
                  }}
                >
                  <StyledCarouselSection>
                    <StyledCarouselDiv>
                      <StyledCarouselImage
                        data-test-id="blank-template-card"
                        isSelected={pageTemplate.name === BLANK_TEMPLATE_NAME}
                        src={
                          'https://cdn-stage.linkhealth.com/site-builder/horizon/BlankTemplate.png'
                        }
                        onClick={() =>
                          setPageTemplate(
                            templates.find(t => t.name === BLANK_TEMPLATE_NAME)
                          )
                        }
                      />
                      <StyledCarouselImage
                        data-test-id="template1-card"
                        isSelected={pageTemplate.name === FORM_TEMPLATE_NAME}
                        src={
                          'https://cdn-stage.linkhealth.com/site-builder/horizon/FormTemplatePreview.png'
                        }
                        onClick={() =>
                          setPageTemplate(
                            templates.find(t => t.name === FORM_TEMPLATE_NAME)
                          )
                        }
                      />
                      <StyledCarouselImage
                        data-test-id="template2-card"
                        isSelected={pageTemplate.name === GENERAL_TEMPLATE_NAME}
                        src={
                          'https://cdn-stage.linkhealth.com/site-builder/horizon/MarketingTemplate.png'
                        }
                        onClick={() =>
                          setPageTemplate(
                            templates.find(
                              t => t.name === GENERAL_TEMPLATE_NAME
                            )
                          )
                        }
                      />
                    </StyledCarouselDiv>
                  </StyledCarouselSection>
                </Carousel>
              </>
            )}
            {props.createSiteMeta.pending && (
              <>
                <StyledImage
                  src={
                    'https://cdn-stage.linkhealth.com/site-builder/horizon/CreateSiteLoading.png'
                  }
                />
                <StyledHeader>Your site is being created...</StyledHeader>
                <StyledParagraph>
                  Hold tight while we create your site.
                </StyledParagraph>
                <StyledLoaderContainer>
                  <LoadingIndicator loading={true} size="l" />
                </StyledLoaderContainer>
              </>
            )}
            {!props.createSiteMeta.pending &&
              !props.createSiteMeta.successful &&
              activeStep === null && (
                <StyledFailureMessage>
                  Site creation failed. Please close the wizard and try again.
                </StyledFailureMessage>
              )}
          </Dialog.Body>
          <Dialog.Actions>
            {activeStep && (
              <ButtonDiv>
                <StyledButton
                  disabled={activeStep === 3 && (!filledOut || theme === '')}
                  data-test-id="next-button"
                  onPress={handleNext}
                >
                  {activeStep === steps.length ? 'Finish' : 'Next'}
                </StyledButton>
                <StyledButton
                  data-test-id="previous-button"
                  inverse
                  onPress={handleBack}
                >
                  {activeStep === 1 ? 'Cancel' : 'Previous'}
                </StyledButton>
              </ButtonDiv>
            )}
          </Dialog.Actions>
        </StyledDialog>
      )}
    </>
  );
};

export default CreateSiteDialog;
