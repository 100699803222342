import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RootState, Dispatch } from 'shared/state';
import { selectUser } from 'shared/state/misc/oidc';
import {
  selectSiteMeta,
  fetchPages,
  selectSite,
} from 'shared/state/ducks/sites';
import { selectIsEditingTemplate } from 'shared/state/ducks/pageTemplates';
import SiteRouter from './SiteRouter';

interface SiteRouterContainerProps {
  siteId: number;
}

const mapStateToProps = (
  state: RootState,
  ownProps: SiteRouterContainerProps
) => ({
  siteMeta: selectSiteMeta(ownProps.siteId)(state),
  site: selectSite(ownProps.siteId)(state),
  user: selectUser()(state),
  isEditingTemplate: selectIsEditingTemplate()(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: SiteRouterContainerProps
) => ({
  fetchPages: () => {
    dispatch(fetchPages(ownProps.siteId));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SiteRouter)
);
