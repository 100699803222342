import { SetStateAction, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import { Icon } from 'link-ui-react';
import {
  duplicateTemplate,
  PageTemplate,
  setSelectedPageTemplate,
} from 'shared/state/ducks/pageTemplates';
import { selectUser } from 'shared/state/misc/oidc';
import { RootState } from '../../../shared/state';
import { TEMPLATE_STATUS } from './constants';

const StyledSpan = styled.span<{ hasMarginLeft?: boolean }>`
  font-size: 1rem;
  color: #8d91a0;
  margin-left: ${({ hasMarginLeft }) => (hasMarginLeft ? '48px' : '0')};
`;

const PageTemplateNameSpan = styled.span`
  font-weight: bold;
  font-size: 1rem;
  margin-right: 20px;
  flex-grow: 1;
  max-width: 400px;
  word-wrap: break-word;
`;

const StyledBoldSpan = styled.span`
  font-weight: 400;
  color: #000;
  font-size: 1rem;
  margin-left: 4px;
`;

const PageTemplateRowWrapper = styled.div`
  font-family: OptumSans, sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  text-align: left;
`;

const PageTemplateRowInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 20px;
`;

const PageTemplateRowContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: flex-start;
`;

const StyledIcon = styled(Icon)`
  margin: 6px 6px 0 6px;
  cursor: pointer;
`;

const options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

interface PageTemplateTableRowProps {
  pageTemplate: PageTemplate;
  onDeleteTemplateClick: (open: boolean) => void;
  onDuplicateTemplateClick: (open: boolean) => void;
  onEditDetailsClick: (open: boolean) => void;
  onEditPageTemplateClick: (pageTemplate: PageTemplate) => void;
  onPublishTemplateClick: (pageTemplate: PageTemplate) => void;
}

const PageTemplateTableRow = ({
  pageTemplate,
  onDeleteTemplateClick,
  onDuplicateTemplateClick,
  onEditDetailsClick,
  onEditPageTemplateClick,
  onPublishTemplateClick,
}: PageTemplateTableRowProps) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => selectUser()(state));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event: {
    currentTarget: SetStateAction<HTMLElement>;
  }) => {
    open ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
  };
  const publishTemplateOptionTitle =
    pageTemplate.status === TEMPLATE_STATUS.PUBLISHED
      ? 'Unpublish Template'
      : 'Publish Template';
  const lastUpdatedOnText = `Last Updated on ${new Date(
    pageTemplate.updatedAt
  ).toLocaleDateString('en-US', options)} -`;
  const createdOnText = `Created on ${new Date(
    pageTemplate.createdAt
  ).toLocaleDateString('en-US', options)} -`;
  const handleEditDetailsClick = () => {
    onEditDetailsClick(true);
    dispatch(setSelectedPageTemplate(pageTemplate.id));
    handleOpenMenu(null);
  };
  const handleEditTemplateClick = () => {
    onEditPageTemplateClick(pageTemplate);
    dispatch(setSelectedPageTemplate(pageTemplate.id));
    handleOpenMenu(null);
  };
  const handlePublishClick = () => {
    onPublishTemplateClick(pageTemplate);
    handleOpenMenu(null);
  };
  const handleDuplicateTemplateClick = () => {
    onDuplicateTemplateClick(false);
    dispatch(duplicateTemplate(pageTemplate.id, user.profile.name));
    handleOpenMenu(null);
  };
  const handleDeleteTemplateClick = () => {
    onDeleteTemplateClick(true);
    dispatch(setSelectedPageTemplate(pageTemplate.id));
    handleOpenMenu(null);
  };
  return (
    <PageTemplateRowWrapper
      data-test-id="page-template-row"
      data-template-row-id={pageTemplate.id}
      role="row"
    >
      <PageTemplateRowInnerWrapper role="gridcell">
        <PageTemplateRowContent>
          <PageTemplateNameSpan>{pageTemplate.title}</PageTemplateNameSpan>
          <StyledSpan>
            <StyledBoldSpan>{pageTemplate.description}</StyledBoldSpan>
          </StyledSpan>
        </PageTemplateRowContent>
        <PageTemplateRowContent>
          <StyledSpan>
            {createdOnText}
            <StyledBoldSpan>{pageTemplate.createdByUserName}</StyledBoldSpan>
          </StyledSpan>
          {pageTemplate.updatedAt && (
            <StyledSpan hasMarginLeft>
              {lastUpdatedOnText}
              <StyledBoldSpan>{pageTemplate.updatedByUserName}</StyledBoldSpan>
            </StyledSpan>
          )}
          <Chip
            label={pageTemplate.status}
            size="small"
            sx={{ marginLeft: '20px', fontFamily: "'OptumSans', sans-serif" }}
          />
        </PageTemplateRowContent>
      </PageTemplateRowInnerWrapper>
      <div onClick={handleOpenMenu}>
        <StyledIcon icon="MoreVertical" height={16} width={16} />
      </div>
      <Menu
        data-test-id="page-template-row-dropdown"
        open={open}
        onSelect={handleOpenMenu}
        onClose={handleOpenMenu}
        anchorEl={anchorEl}
        aria-label="Page template actions"
        aria-hidden="false"
      >
        <MenuItem onClick={handleEditDetailsClick}>Edit Details</MenuItem>
        <MenuItem onClick={handleEditTemplateClick}>Edit Template</MenuItem>
        <MenuItem onClick={handlePublishClick}>
          {publishTemplateOptionTitle}
        </MenuItem>
        <MenuItem onClick={handleDuplicateTemplateClick}>Duplicate</MenuItem>
        <MenuItem onClick={handleDeleteTemplateClick}>Delete</MenuItem>
      </Menu>
    </PageTemplateRowWrapper>
  );
};
export default PageTemplateTableRow;
