import styled from 'styled-components';
import { Button } from '@uitk/react';

const StyledButton = styled(Button)`
  font-family: 'OptumSans';
  height: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-right: 20px;
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 16px;
`;

const PageTemplatesTableHeader = ({ templateCount, onCreateTemplateClick }) => (
  <StyledDiv data-test-page-template-count={templateCount}>
    Your Custom Templates ({templateCount})
    <StyledButton
      onPress={onCreateTemplateClick}
      data-test-id="create-page-template-button"
      aria-label="Create a new page template"
    >
      + Create Template
    </StyledButton>
  </StyledDiv>
);

export default PageTemplatesTableHeader;
