import React, { ReactElement, useEffect } from 'react';
import { Config } from './types';
import styled from 'styled-components';
import { default as NukaCarousel } from 'nuka-carousel';
import { Icon } from 'link-ui-react';

const ArrowWrapper = styled.div`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  cursor: default;
  :hover,
  :focus {
    outline: 3px solid #2e65d8;
    border-radius: 10px;
    background-color: transparent;
  }
  :active {
    outline: 3px solid #2e65d8;
    border-radius: 10px;
    background-image: linear-gradient(rgb(0 0 0 /5%) 0 0);
  }
`;

const ArrowIcon = styled(Icon)`
  fill: ${p => p.theme.colors.primary};
`;

const StyledCarouselDiv = styled.div<{ slideUnselectedDotsColor: string }>`
  &
    > div
    > div
    + div
    + div
    + div
    > div
    > ul
    > li[class='paging-item']
    > button
    > svg {
    fill: ${p => p.slideUnselectedDotsColor};
  }
`;

const SlideShowWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
): ReactElement => {
  const {
    backgroundData,
    backgroundImage,
    paddingData,
    borderData,
    lineColor,
    slides,
    slideArrowColor,
    slideSelectedDotsColor,
    autoPlayInterval,
    currentSlideIndex,
    autoPlayChecked,
    arrowsCheck,
    slideUnselectedDotsColor,
    positionalDotsCheck,
    height,
    visible,
    isCustomWidget,
  } = props.widgetState.config;
  const { onChange, value, currentPage } = props;
  const activeWidgetId = props.widgetState.id;

  useEffect(() => {
    const slideShowWidgetChildren =
      currentPage.content[props.widgetState.id].children;
    slideShowWidgetChildren.forEach(child => {
      const isVisible =
        currentPage.content[child].tabIndex === currentSlideIndex;
      if (currentPage.content[child].config.visible !== isVisible) {
        props.changeWidgetVisible(
          props.siteId,
          props.currentPage.pageId,
          props.widgetState.id,
          child,
          isVisible,
          currentSlideIndex
        );
      }
    });
  }, []);

  const handleSlideClick = (direction: string) => {
    let newSlideIndex = currentSlideIndex;

    if (direction === 'next') {
      newSlideIndex =
        currentSlideIndex < slides.length - 1 ? currentSlideIndex + 1 : 0;
    } else if (direction === 'previous') {
      newSlideIndex =
        currentSlideIndex > 0 ? currentSlideIndex - 1 : slides.length - 1;
    }

    const slideShowWidgetChildren =
      currentPage.content[props.widgetState.id].children;
    slideShowWidgetChildren.forEach(child => {
      const isVisible = currentPage.content[child].tabIndex === newSlideIndex;
      if (currentPage.content[child].config.visible !== isVisible) {
        props.changeWidgetVisible(
          props.siteId,
          props.currentPage.pageId,
          props.widgetState.id,
          child,
          isVisible,
          newSlideIndex
        );
      }
    });

    onChange(
      {
        ...value,
        currentSlideIndex: newSlideIndex,
      },
      activeWidgetId,
      props.widgetState.defaultWidget
    );
  };

  if (visible === false || isCustomWidget) {
    return <></>;
  }

  return (
    <StyledCarouselDiv
      slideUnselectedDotsColor={slideUnselectedDotsColor}
      data-test-id="nuka-caraousal-div"
      style={{
        borderWidth: `${borderData?.thickness}px`,
        borderStyle: 'solid',
        borderColor: `${lineColor}`,
        borderRadius: `${borderData?.radius}px`,
        backgroundImage:
          backgroundData?.style === 'Image'
            ? `url('${backgroundImage?.url}')`
            : '',
        background:
          backgroundData?.color !== '' ? backgroundData?.color : 'none',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        paddingTop: `${paddingData?.top}px`,
        paddingRight: `${paddingData?.right}px`,
        paddingBottom: `${paddingData?.bottom}px`,
        paddingLeft: `${paddingData?.left}px`,
      }}
    >
      <NukaCarousel
        renderCenterLeftControls={() => (
          <ArrowWrapper
            onClick={e => {
              e.stopPropagation();
              handleSlideClick('previous');
            }}
            data-test-id="previous-icon"
            tabIndex={0}
          >
            <ArrowIcon
              icon="ArrowLeftOpen"
              width="20px"
              style={{
                fill: slideArrowColor,
                visibility: arrowsCheck ? 'visible' : 'hidden',
              }}
            />
          </ArrowWrapper>
        )}
        renderCenterRightControls={() => (
          <ArrowWrapper
            onClick={e => {
              e.stopPropagation();
              handleSlideClick('next');
            }}
            data-test-id="next-icon"
            tabIndex={0}
          >
            <ArrowIcon
              icon="ArrowRightOpen"
              width="20px"
              style={{
                fill: slideArrowColor,
                visibility: arrowsCheck ? 'visible' : 'hidden',
              }}
            />
          </ArrowWrapper>
        )}
        autoplayInterval={Number(autoPlayInterval)}
        slideIndex={currentSlideIndex}
        autoplay={!!autoPlayChecked}
        afterSlide={() => {
          if (!!autoPlayChecked) handleSlideClick('next');
        }}
        defaultControlsConfig={{
          pagingDotsStyle: {
            cursor: 'default',
            fill: slideSelectedDotsColor,
            marginTop: '1000px',
            visibility: positionalDotsCheck ? 'visible' : 'hidden',
            opacity: 1,
          },
        }}
        wrapAround
      >
        {slides.map(() => (
          <div
            style={{ paddingTop: height - 10 }}
            data-test-id="slides-div"
          ></div>
        ))}
      </NukaCarousel>
    </StyledCarouselDiv>
  );
};
export default SlideShowWidget;
