import { PureComponent } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import uuid from 'uuid/v4';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import URI from 'urijs';
import { getSignedUrl as fetchSignedUrl } from 'shared/api/s3';
import ReactS3Uploader from 'react-s3-uploader';
import { withRouter } from 'react-router-dom';
import config from 'builder/config';
import SiteEditContext from 'shared/util/SiteEditContext';
import { SignedUrlObject } from 'shared/api/s3';
import { UploadedFile } from 'shared/widgets/shared/types';
import { UploadButton } from '../S3Image/S3Image';
import { ArrowWtailBoldUp } from '@uitk/react-icons';
import {
  S3UploadFileProps,
  S3UploadFileState,
  Wrapper,
  UploadLabel,
} from '../S3UploadFile/S3UploadFile';

const InputWrapper = styled.div`
  & .MuiButton-root {
    font-family: OptumSans, sans-serif;
    font-weight: 700;
  }
  display: flex;
  flex-direction: row;
  width: 100%;
  > label {
    width: unset;
  }
`;

const StyledUploadButton = styled(UploadButton)`
  font-family: OptumSans, sans-serif;
`;

class S3UploadFileButton extends PureComponent<
  S3UploadFileProps,
  S3UploadFileState
> {
  static contextType = SiteEditContext;

  constructor(props: S3UploadFileProps) {
    super(props);
    this.state = {
      uploading: false,
      uuid: uuid(),
    };
  }

  handleFinish = (signedUrlObject: SignedUrlObject) => {
    const { onUpload } = this.props;
    this.setState({
      uploading: false,
    });
    const uri = URI(signedUrlObject.signedUrl);
    const file: UploadedFile = {
      url: uri
        .hostname(config.cdn)
        .query('')
        .toString(),
      name: uri.filename().toString(),
    };
    onUpload(file);
  };

  rejectUpload = () => {
    this.setState({
      uploading: false,
    });
    toast('This file type is not supported', {
      type: 'error',
      theme: 'colored',
    });
  };

  onUploadStart(file: File, callback: any) {
    const fileName = file.name;
    const { fileFormatRegex } = this.props;
    // If extension of file being uploaded is as per fileformatRegex then do callback otherwise reject the upload.
    fileFormatRegex &&
      (fileFormatRegex.test(fileName) ? callback(file) : this.rejectUpload());
  }

  render() {
    const { uploading, uuid } = this.state;
    const {
      label,
      accept,
      match: {
        params: { siteId },
      },
    } = this.props;
    return (
      <Wrapper>
        <ReactS3Uploader
          getSignedUrl={(file, cb) => fetchSignedUrl(file, cb, siteId)}
          s3path={`site-builder/${siteId}`}
          scrubFilename={filename => filename}
          preprocess={(file, callback) => this.onUploadStart(file, callback)}
          uploadRequestHeaders={{}}
          onProgress={() => {
            this.setState({ uploading: true });
          }}
          onFinish={this.handleFinish}
          style={{ display: 'none' }}
          accept={accept}
          onError={() => {
            this.setState({ uploading: false });
            toast('Error uploading file', { type: 'error', theme: 'colored' });
          }}
          id={uuid}
          type="file"
          data-test-id={`file-uploader`}
        />
        <InputWrapper>
          <UploadLabel htmlFor={uuid}>
            <StyledUploadButton
              style={{ textTransform: 'none' }}
              size="medium"
              variant="outlined"
              startIcon={
                uploading ? (
                  <CircularProgress size={15} />
                ) : (
                  <ArrowWtailBoldUp />
                )
              }
            >
              {label}
            </StyledUploadButton>
          </UploadLabel>
        </InputWrapper>
      </Wrapper>
    );
  }
}

export { S3UploadFileButton };
export default withRouter<S3UploadFileProps>(S3UploadFileButton);
