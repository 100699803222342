import React from 'react';
import {
  DecoratorNode,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  NodeKey,
  SerializedLexicalNode,
  Spread,
} from 'lexical';
import { TablerIcon } from '@tabler/icons-react';
import { TYPE_ICON_MAP } from '../../plugins/AdmonitionPlugin';
import { AdmonitionType } from './index';

export type SerializedIconNode = Spread<
  {
    type: 'icon';
    version: 1;
    className: string;
  },
  SerializedLexicalNode
>;

export class AdmonitionIconNode extends DecoratorNode<JSX.Element> {
  __iconComponent: React.ComponentType<any>;
  __className: string;

  constructor(
    iconComponent: React.ComponentType<any>,
    className: string,
    key?: NodeKey
  ) {
    super(key);
    this.__iconComponent = iconComponent;
    this.__className = className;
  }

  static getType() {
    return 'icon';
  }

  static clone(node: AdmonitionIconNode): AdmonitionIconNode {
    return new AdmonitionIconNode(
      node.__iconComponent,
      node.__className,
      node.__key
    );
  }

  createDOM() {
    const element = document.createElement('span');
    element.setAttribute('class', `admonition-icon-${this.__className}`);
    return element;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span');
    element.setAttribute('class', `admonition-icon-${this.__className}`);
    return { element };
  }

  updateDOM(prevNode: AdmonitionIconNode, dom: HTMLElement): boolean {
    if (prevNode.__className !== this.__className) {
      dom.className = `admonition-icon-${this.__className}`;
      return true;
    }
    return false;
  }

  decorate() {
    const IconComponent = this.__iconComponent;
    const color = getColorForAdmonitionType(
      this.__className.replace('admonition-icon-', '') as AdmonitionType
    );
    return (
      <IconComponent
        size={18}
        stroke={2}
        color={color}
        style={{ marginBottom: '-3px' }}
      />
    );
  }

  exportJSON(): SerializedIconNode {
    return {
      type: 'icon',
      version: 1,
      className: this.__className,
    };
  }

  static importJSON(serializedNode: SerializedIconNode): AdmonitionIconNode {
    const { className } = serializedNode;
    const tempElement = document.createElement('div');
    tempElement.className = `admonition-icon-${className}`;
    const iconComponent = getIconComponentFromClass(tempElement.classList);
    return new AdmonitionIconNode(iconComponent, className);
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        const className = Array.from(domNode.classList).find(cls =>
          cls.startsWith('admonition-icon-')
        );
        if (!className) {
          return null;
        }
        return {
          conversion: (domNode: HTMLElement) =>
            $convertIconElement(
              domNode,
              className.replace('admonition-icon-', '')
            ),
          priority: 2,
        };
      },
    };
  }
}

export function $createIconNode(
  iconComponent: React.ComponentType<TablerIcon>,
  className: string
) {
  return new AdmonitionIconNode(iconComponent, className);
}

function $convertIconElement(
  domNode: HTMLElement,
  className: string
): null | DOMConversionOutput {
  const iconComponent = getIconComponentFromClass(domNode.classList);
  if (!iconComponent) {
    return null;
  }
  const node = $createIconNode(iconComponent, className);
  return { node };
}

function getIconComponentFromClass(
  classList: DOMTokenList
): React.ComponentType<any> | null {
  const className = Array.from(classList).find(cls =>
    cls.startsWith('admonition-icon-')
  );
  if (!className) {
    return null;
  }
  const admonitionType = className.replace(
    'admonition-icon-',
    ''
  ) as AdmonitionType;
  return TYPE_ICON_MAP[admonitionType] || null;
}

function getColorForAdmonitionType(type: AdmonitionType): string {
  switch (type) {
    case 'note':
      return '#2f70e0';
    case 'info':
      return '#209cee';
    case 'tip':
      return '#47d147';
    case 'question':
      return '#6b47d1';
    case 'danger':
      return '#d14545';
    case 'warning':
      return '#ffcc00';
    default:
      return '#eeeeee';
  }
}
