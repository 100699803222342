import { connect } from 'react-redux';
import { RootState, Dispatch } from 'shared/state';
import {
  selectSite,
  selectPages,
  updateSite,
  saveSite,
  selectPage,
  savePage,
  publishSite,
  publishSiteToDemo,
} from 'shared/state/ducks/sites';
import { setRedoButton, setUndoButton } from 'shared/state/ducks/ui';
import { BuilderTopMenu } from './BuilderTopMenu';
import { fetchIdpsIfNeeded, selectIdps } from 'shared/state/ducks/idps';
import { fetchProvisioningStores } from 'shared/state/ducks/provisioningStores';
import { ActionCreators as UndoActionCreators } from 'redux-undo';
import { fetchCompareSiteandPage } from 'shared/state/ducks/site';
import {
  PageTemplate,
  selectIsEditingTemplate,
  selectSelectedPageTemplate,
  updatePageTemplate,
} from '../../../shared/state/ducks/pageTemplates';

interface SiteLandingPageContainerProps {
  siteId: number;
  pageId: number;
}

const mapStateToProps = (
  state: RootState,
  ownProps: SiteLandingPageContainerProps
) => ({
  site: selectSite(ownProps.siteId)(state),
  pages: selectPages(ownProps.siteId)(state),
  page: selectPage(ownProps.siteId, ownProps.pageId)(state),
  idps: selectIdps()(state),
  provisioningStores: state.provisioningStores.data.provisioningStores,
  canUndo: state.undoabletodos.past?.length > 1,
  canRedo: state.undoabletodos.future.length > 0,
  hasChanged: state.site.hasChanged,
  isEditingTemplate: selectIsEditingTemplate()(state),
  selectedPageTemplate: selectSelectedPageTemplate()(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: SiteLandingPageContainerProps
) => ({
  saveSite: () => {
    dispatch(saveSite(ownProps.siteId));
  },
  updateSite: (site: Site) => {
    dispatch(updateSite(ownProps.siteId, site));
  },
  savePage: (page: PageVersion) => {
    dispatch(savePage(ownProps.siteId, ownProps.pageId, page));
  },
  fetchIdps: () => {
    dispatch(fetchIdpsIfNeeded());
  },
  fetchProvisioningStores: () => {
    dispatch(fetchProvisioningStores());
  },
  publishSite: () => {
    dispatch(publishSite(ownProps.siteId));
  },
  publishSiteToDemo: () => {
    dispatch(publishSiteToDemo(ownProps.siteId));
  },
  onUndo: () => dispatch(UndoActionCreators.undo()),
  onRedo: () => dispatch(UndoActionCreators.redo()),

  whenClickedUndo: () => dispatch(setUndoButton()),
  whenClickedRedo: () => dispatch(setRedoButton()),
  fetchCompareSiteandPage: (siteId: number) => {
    dispatch(fetchCompareSiteandPage(siteId));
  },
  updatePageTemplate: (pageTemplate: PageTemplate) => {
    dispatch(updatePageTemplate(pageTemplate));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BuilderTopMenu);
