import { Button, Dialog, LoadingIndicator, Text } from '@uitk/react';
import {
  selectPageTemplatesMeta,
  selectSelectedPageTemplate,
  deletePageTemplate,
  setSelectedPageTemplate,
  clearPageTemplateMeta,
} from '../../../../shared/state/ducks/pageTemplates';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../shared/state';
import { PageTemplateErrorMessages } from '../../../../shared/api/pageTemplate';
import { StyledFailureMessage, StyledLoaderContainer } from './TemplateDialog';

export interface DeleteTemplateDialogProps {
  setDeleteTemplateDialogOpen: (open: boolean) => void;
  deleteTemplateDialogOpen: boolean;
}

const DeleteTemplateDialog = (props: DeleteTemplateDialogProps) => {
  const dispatch = useDispatch();
  const { setDeleteTemplateDialogOpen, deleteTemplateDialogOpen } = props;

  const selectedTemplate = useSelector((state: RootState) =>
    selectSelectedPageTemplate()(state)
  );

  const { pending, error } = useSelector((state: RootState) =>
    selectPageTemplatesMeta()(state)
  );

  const handleClose = () => {
    dispatch(setSelectedPageTemplate(null));
    dispatch(clearPageTemplateMeta());
    setDeleteTemplateDialogOpen(false);
  };

  const handleDeleteTemplate = async () => {
    try {
      const result = await dispatch(deletePageTemplate(selectedTemplate?.id));
      if (result && !error && !pending) {
        handleClose();
      }
    } catch (error) {
      console.error('Error deleting template:', error);
    }
  };

  return (
    <span data-test-id="template-dialog-wrapper">
      {deleteTemplateDialogOpen && (
        <Dialog
          data-test-id="delete-template-dialog"
          open={deleteTemplateDialogOpen}
          title={`Delete ${selectedTemplate?.title}?`}
          titleAs="h3"
          onClose={handleClose}
        >
          <Dialog.Body>
            <Text>
              Are you sure you want to delete this template? This action cannot
              be undone.
            </Text>
            {pending && (
              <StyledLoaderContainer>
                <LoadingIndicator loading={true} size="l" />
              </StyledLoaderContainer>
            )}
            {error === PageTemplateErrorMessages.DeleteTemplateFailed && (
              <StyledFailureMessage>
                {`${error}. Please try again. If the issue
                persists, please contact support.`}
              </StyledFailureMessage>
            )}
          </Dialog.Body>
          <Dialog.Actions>
            <Button
              data-test-id="delete-template-button"
              onPress={handleDeleteTemplate}
            >
              Delete
            </Button>
            <Button
              data-test-id="cancel-delete-template-button"
              variant="secondary"
              onPress={handleClose}
            >
              Cancel
            </Button>
          </Dialog.Actions>
        </Dialog>
      )}
    </span>
  );
};

export default DeleteTemplateDialog;
