import { TEMPLATE_EDITOR_PAGE_ID } from './index';

export const pageSkeleton = {
  id: TEMPLATE_EDITOR_PAGE_ID,
  current: {
    id: 1,
    pageId: TEMPLATE_EDITOR_PAGE_ID,
    status: 'draft',
    content: {},
    title: 'Home',
    slug: 'home',
    authentication: false,
    allowedIdps: null,
    headerEnabled: false,
    footerEnabled: false,
    pageBackground: {
      style: 'Color',
      color: '#ffffff00',
      gradient: '',
    },
  },
  isDuploPage: false,
};
