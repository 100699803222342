import React, { ChangeEvent, useState } from 'react';
import { Config } from './types';
import { Label, TextArea, FormControl } from '@uitk/react';
import styled from 'styled-components';
import { validationRegex } from 'builder/util/constants';

const StyledTextInput = styled.div<{
  lineColor: string;
  borderData: any;
  height: number;
}>`
  padding: 0.5rem;
  border-width: ${p => p.borderData?.thickness ?? 0}px;
  border-style: solid;
  border-color: ${p => p.lineColor ?? '#ff0000'};
  border-radius: ${p => p.borderData?.radius ?? 0}px;
  height: ${p => p.height}px;
  display: flex;
  justify-content: center;
`;

export const StyledFormControl = styled(FormControl)`
  font-family: OptumSans, sans-serif;
  align-content: center;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  #required-text-input-err {
    display: inline-flex;
  }
  width: 100%;
  flex-direction: row;
  -webkit-flex-direction: row;
`;

const StyledTextArea = styled(TextArea)`
  font-family: OptumSans, sans-serif;
  resize: none;
  align-content: center;
  padding-left: 0.25em;
`;

export const TextInputWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
) => {
  const {
    required,
    label,
    lineColor,
    borderData,
    backgroundData,
    backgroundImage,
    height,
    validation,
    placeholder,
    value,
    visible,
    isCustomWidget,
  } = props.widgetState.config;
  const [inputValue, setValue] = useState(value);
  const [error, setError] = useState(props.widgetState.config.error);

  const onInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  const handleBlur = e => {
    const { onChange } = props;
    const { config } = props.widgetState;
    const input = e.target.value;
    let result = '';

    if (required) {
      if (validation === 'none' && input) {
        setError('');
      }
      if (validation === 'none' && !input) {
        result = 'This field is required';
        setError(result);
      }
      if (validation !== 'none') {
        result = validationRegex[validation](input);
        setError(result);
      }
    }

    if (!required) {
      if (validation === 'none') {
        setError('');
      }
      if (validation !== 'none') {
        result = validationRegex[validation](input);
        setError(result);
      }
    }
    onChange(
      {
        ...config,
        value: input,
        error: result,
      },
      props.widgetState.id,
      props.widgetState.defaultWidget
    );
  };

  const handleErrorMessage = () => {
    return window.location.pathname.includes('preview/') ? error : '';
  };

  if (visible === false || isCustomWidget) {
    return <></>;
  }

  return (
    <StyledTextInput
      borderData={borderData}
      lineColor={lineColor}
      height={height}
    >
      <StyledFormControl
        id={'required-text-input'}
        error={handleErrorMessage()}
        required={required}
      >
        <Label>{label || label === '' ? label : 'Label'}</Label>
        <StyledTextArea
          id={props.widgetState.id}
          data-test-id="text-input-widget-text-area"
          onChange={onInputChange}
          onBlur={handleBlur}
          readOnly={props.editing}
          value={props.editing ? '' : inputValue}
          placeholder={placeholder}
          style={{
            overflow: 'hidden',
            backgroundColor:
              backgroundData?.color.startsWith('#') ||
              backgroundData?.color.startsWith('rgb')
                ? backgroundData?.color
                : '',
            backgroundImage:
              backgroundData?.style === 'Image'
                ? `url('${backgroundImage?.url}')`
                : backgroundData?.style === 'Color' &&
                  !(
                    backgroundData?.color.startsWith('#') ||
                    backgroundData?.color.startsWith('rgb')
                  )
                ? backgroundData?.color
                : '',
            height: '1rem',
            fontSize: '1rem',
            minHeight: '40px',
            boxShadow: '0 0 0 0.0625rem #4B4D4F',
            borderRadius: '0.25rem',
            marginTop: '.25rem',
          }}
        />
      </StyledFormControl>
    </StyledTextInput>
  );
};

export default TextInputWidget;
