/*
The PagesPanel.tsx file is a React component file that is part of the website builder.
The file contains a function filterAndAdjustTemplate which takes a template, a default page, and a footer location as arguments. This function is responsible for adjusting the template based on the default page and the footer location. It does this by cloning the template, removing the header and footer sections, and then adding the default header and footer. It also adjusts the y-location of the widgets in the template based on the height difference of the header.
The file also defines an interface PagesPanelProps which outlines the props expected by the PagesPanel component. These props include arrays of pages, site details, the current page, and functions to create, remove, and save pages. There are also handlers for active widget ID, current page data, and new page status.
The rest of the file contains a lot of styled components and icons, which are likely used in the PagesPanel component. These include styled versions of buttons, cards, labels, dropdowns, text inputs, checkboxes, tooltips, icons, and more. The styles are defined using the styled-components library.
*/
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { Menu, menuClasses, SubMenu } from 'react-pro-sidebar';
import styled from 'styled-components';
import { Icon } from 'link-ui-react';
import { linkDeveloperTheme as theme } from 'link-ui-react';
import {
  Button,
  Card,
  Label,
  Dropdown,
  TextInput,
  FormControl,
  IDropdownItem,
  Checkbox,
  Select as UitkSelect,
} from '@uitk/react';
import { Add } from '@uitk/react-icons';
import 'react-complex-tree/lib/style-modern.css';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Divider, ListSubheader, MenuItem, Popover } from '@material-ui/core';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import templates from '../CreateSiteDialog/templates';
import {
  FORM_TEMPLATE_NAME,
  GENERAL_TEMPLATE_NAME,
  pageSlugContent,
  IDP_VALUES_TO_USE,
  pageBackgroundStyles,
  FOOTER_SECTION_ID,
  HEADER_SECTION_ID,
  HEADER_PLACEHOLDER_ID,
  FOOTER_PLACEHOLDER_ID,
} from 'builder/util/constants';
import { IconClipboardText } from '@tabler/icons-react';
import {
  BackgroundColorWrapper,
  BackgroundStyleDropdowns,
  ColorButtonWrapper,
} from 'builder/components/WidgetConfigurations/Background/Background';
import { StyledButtonUitkSave } from 'builder/components/BuilderTopMenu/BuilderTopMenu';
import { ColorButton } from 'builder/components/ColorButton/ColorButton';
import GradientColorPicker, {
  StyledClose,
  StyledPickerHeader,
  StyledPickerPopover,
  StyledPickerWrapper,
} from 'builder/components/GradientColorPicker/GradientColorPicker';
import {
  fetchUserTenancy,
  selectUserTenancy,
} from '../../../../shared/state/ducks/tenancies';
import {
  fetchPageTemplates,
  fetchTemplateById,
  selectPageTemplatesByStatus,
  selectSelectedPageTemplate,
} from '../../../../shared/state/ducks/pageTemplates';
import { RootState } from '../../../../shared/state';
import { TEMPLATE_STATUS } from '../../PageTemplates/constants';

export const StyledIcon = styled(Icon)`
  margin-right: 8px;
  margin-left: 8px;
`;

const Pages = <IconClipboardText size={36} stroke={2} color="#2E3034" />;
const Home = <Icon icon="Home" width={20} />;
const PageIcon = <Icon icon="Form" width={15} />;

export const StyledPage = styled.span``;
const StyledPageDelete = styled.p`
  width: 302px;
`;

export const StyledTitle = {
  'font-family': 'OptumSans',
  'font-size': '18px',
  color: '#000000',
  fontWeight: '700',
  'margin-top': '-5px',
  'text-align': 'left',
};

export const StyledSettingName = styled.div``;
export const iconStyle = {
  fill: '#196ECF',
};
export const StyledTextName = styled.span`
  font-family: OptumSans, sans-serif;
  color: #797979;
  display: inline-block;
  padding: 0 0 10px 10px;
`;
const StyledTextNameDelete = styled.span`
  font-family: OptumSans, sans-serif;
  color: #797979;
  display: inline-block;
`;
export const StyledNewPage = {
  width: '282px',
};

export const StyledCard = styled.div`
  & div[class^='Cardcomponent__Content'] {
    margin-top: -15px;
  }
`;

export const StyledSettingsCard = styled(Card)`
  & div[class^='Cardcomponent__Content'] {
    padding: 1rem;
  }
`;

export const settingsDividerStyles = {
  marginBottom: '10px',
};

const StyledMenuItem = styled(SubMenu)`
  margin-top: 16px;
`;

const StyledHeading = styled.p`
  color: ${theme.colors.aux.black};
  font-weight: 900;
  font-size: 21px;
  font-family: OptumSans, sans-serif;
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  padding-top: 18px;
  padding-left: 20px;
`;

export const StyledAddItemButton = styled(Button)`
  margin: 10px 0px 1rem 55px;
  font-family: OptumSans, sans-serif;
  color: #ffffff;
  background: #444444;
  &:hover,
  &:focus {
    color: #ffffff;
    background: #333333;
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 5px #333333;
    border-color: transparent;
    outline: none;
  }
  &.isPressed {
    color: #ffffff;
    background: #111111;
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 5px #111111;
  }
`;

export const StyledPopover = styled(Popover)`
  margin-top: -14px;
  .MuiPaper-root {
    overflow-x: unset;
    overflow-y: unset;
    left: 350px !important;
  }
`;

export const ButtonWrapper = styled.div`
  padding: 20px 20px 20px 35px;
  display: flex;
  .uitk-button {
    margin-right: 15px;
    margin-top: 10px;
    font-family: OptumSans, sans-serif;
  }
`;

const TextBoxStyles = {
  width: '14.4375em',
  height: '2.5em',
  color: '#4b4d4f',
  'font-family': 'OptumSans',
};

export const labelStyles = {
  color: '#333333',
  'font-size': '0.875em',
  'font-family': 'OptumSans',
  'line-height': '1.25em',
  'padding-top': '15px',
};

const StyledSlug = styled.div`
  font-family: OptumSans, sans-serif;
  color: #797979;
  background-color: #fffaf7;
  padding-left: 8px;
  margin-top: 10px;
  font-size: 12px;
`;

const ButtonWrapper1 = styled.div`
  margin-left: -12.5px;
  display: flex;
  .uitk-button {
    margin-right: 7px;
    margin-top: 70px;
    font-family: OptumSans, sans-serif;
  }
`;

const StyledDropdown = styled(Dropdown)`
  font-size: 12pt;
  font-family: OptumSans, sans-serif;
  & button {
    font-family: OptumSans, sans-serif;
    padding: 0.7rem;
  }
  #dropdown-multi-controlled-text {
    & > span > p {
      font-family: OptumSans, sans-serif;
    }
  }
  #dropdown-multi-controlled {
    & + div {
      width: auto;
    }
  }
`;

const SelectWrapper = styled.div`
  & .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    display: block;
    font-family: OptumSans, sans-serif;
    border: none;
    box-shadow: 0 0 0 1px #737373;
    border-radius: 0.25rem;
  }
  & .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover {
  }
  & > div > div {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  & .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    fill: black;
  }
`;

export const StyledSelect = styled(Select)`
  width: 14.5em;
  & .MuiButtonBase-root {
    justify-content: flex-start;
    display: grid;
  }
`;

const StyledDropdownControl = styled(FormControl)`
  & > div > span {
    font-family: OptumSans, sans-serif;
  }
`;

export const ButtonWrapperFolder = styled.div`
  margin-left: -12.5px;
  display: flex;
  .uitk-button {
    margin-right: 7px;
    margin-top: 40px;
    font-family: OptumSans, sans-serif;
  }
`;

const StyledListItem = styled(ListItemText)`
  .MuiListItem-root {
    padding-top: 0;
  }
  .MuiTypography-root {
    word-wrap: break-word;
    margin-left: -30px;
    font-family: OptumSans, sans-serif;
    font-size: 12pt;
  }
`;

const StyledListIcon = styled.div`
  & div[class^='MuiListItemIcon-root home'] {
    margin-right: 10px;
    min-width: 0px;
  }
  ,
  & div[class^='MuiListItemIcon-root settings'] {
    min-width: 0px;
  }
`;

export const dividerStyles = {
  'margin-top': '-10px',
  backgroundColor: 'whitesmoke',
};

export const StyledAlternativeButton = styled(Button)`
  color: #ffffff;
  background: #444444;
  &:hover,
  &:focus {
    color: #ffffff;
    background: #333333;
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 5px #333333;
    border-color: transparent;
    outline: none;
  }
  &.isPressed {
    color: #ffffff;
    background: #111111;
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 5px #111111;
  }
`;

const StyledFormControl = styled(FormControl)`
  #edit-slug-textbox-err {
    font-family: OptumSans, sans-serif;
    & > span {
      width: fit-content;
    }
  }
  #new-slug-textbox-err {
    font-family: OptumSans, sans-serif;
  }
`;

const StyledCheckboxControl = styled(FormControl)`
  & .uitk-checkbox-label {
    font-family: OptumSans, sans-serif;
    font-size: 11pt;
  }
`;

export const StyledDropdownOption = styled(MenuItem)`
  font-family: OptumSans !important;
  margin-left: 1rem !important;
  font-size: 12pt !important;
`;

const StyledSubHeader = styled(ListSubheader)`
  font-family: OptumSans !important;
  font-weight: 700 !important;
  line-height: 40px !important;
  color: black !important;
`;

const PageBackgroundStyle = styled(UitkSelect)`
  font-family: OptumSans, sans-serif;
  display: flex;
  width: 14.5rem;
  border-radius: 0.375rem;
  & > select {
    font-family: OptumSans, sans-serif;
    line-height: 1.25rem;
  }
`;

export interface PageBackground {
  style: string;
  color: string;
  gradient: string;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 350,
    fontSize: 14,
    border: '1px solid #dadde9',
    fontFamily: 'OptumSans !important',
    textAlign: 'center',
  },
}));

export function filterAndAdjustTemplate(
  template: PageContent,
  defaultPage: Page,
  footerYLocation: number
) {
  const deepClone = (obj: any) => {
    return JSON.parse(JSON.stringify(obj));
  };
  const copiedTemplateItems = deepClone(template);
  const defaultHeader = defaultPage.current.content[HEADER_SECTION_ID];
  const defaultFooter = defaultPage.current.content[FOOTER_SECTION_ID];
  let templateFooterYLocation =
    copiedTemplateItems[FOOTER_SECTION_ID].config.yLocation;
  const headerHeightDifference =
    defaultHeader.config.height -
    copiedTemplateItems[HEADER_SECTION_ID].config.height;
  const removeTemplateHeaderAndChildren = (widgetId: string) => {
    const widget = copiedTemplateItems[widgetId];
    copiedTemplateItems['root'].children.filter(id => id !== widgetId);
    delete copiedTemplateItems[widgetId];
    if (widget && widget.children.length > 0) {
      widget.children.forEach(childId => {
        removeTemplateHeaderAndChildren(childId);
      });
    }
    return copiedTemplateItems;
  };
  removeTemplateHeaderAndChildren(HEADER_SECTION_ID);
  removeTemplateHeaderAndChildren(FOOTER_SECTION_ID);
  const addDefaultWidgetsAndChildren = (widget: any) => {
    copiedTemplateItems[widget.id] = widget;
    widget.children.length > 0 &&
      widget.children.forEach(childId => {
        const child = defaultPage.current.content[childId];
        if (child) {
          addDefaultWidgetsAndChildren(child);
        }
      });
    return copiedTemplateItems;
  };
  addDefaultWidgetsAndChildren(defaultHeader);
  const headerAndChildrenIds = new Set<string>();
  const collectHeaderAndChildrenIds = (widget: any) => {
    headerAndChildrenIds.add(widget.id);
    widget.children.length > 0 &&
      widget.children.forEach(childId => {
        const child = defaultPage.current.content[childId];
        if (child) {
          collectHeaderAndChildrenIds(child);
        }
      });
  };
  collectHeaderAndChildrenIds(defaultHeader);
  Object.values(copiedTemplateItems).forEach((widget: Widget<any>) => {
    if (!headerAndChildrenIds.has(widget.id)) {
      widget.config.yLocation += headerHeightDifference;
    }
  });
  addDefaultWidgetsAndChildren(defaultFooter);
  copiedTemplateItems[FOOTER_SECTION_ID].config.yLocation =
    templateFooterYLocation + headerHeightDifference;
  copiedTemplateItems[FOOTER_SECTION_ID].children.forEach(childId => {
    const childYDifferenceInDefaultPageFooter =
      defaultPage.current.content[childId].config.yLocation - footerYLocation;
    const yLocationForFooterChildInTemplate =
      copiedTemplateItems[FOOTER_SECTION_ID].config.yLocation +
      childYDifferenceInDefaultPageFooter;
    return (copiedTemplateItems[
      childId
    ].config.yLocation = yLocationForFooterChildInTemplate);
  });
  const newRoot = copiedTemplateItems['root'];
  newRoot.children = Object.keys(copiedTemplateItems).filter(
    id => id !== 'root'
  );
  return copiedTemplateItems;
}

export function addHeaderFooterToTemplate(
  defaultPage: any,
  selectedTemplate: any
) {
  const templateCopy = _.cloneDeep(selectedTemplate);
  const headerSection = defaultPage.current.content[HEADER_SECTION_ID];
  const footerSection = defaultPage.current.content[FOOTER_SECTION_ID];
  const headerChildren = headerSection.children;
  const footerChildren = footerSection.children;
  const defaultFooterY =
    defaultPage.current.content[FOOTER_SECTION_ID].config.yLocation;
  const templateFooterY = templateCopy[FOOTER_PLACEHOLDER_ID].config.yLocation;
  const yLocationDifference = templateFooterY - defaultFooterY;
  // Update the footer section's yLocation
  footerSection.config.yLocation = templateFooterY;

  // Add header and footer sections to the templateCopy and their IDs to the root children array
  templateCopy[HEADER_SECTION_ID] = headerSection;
  templateCopy[FOOTER_SECTION_ID] = footerSection;
  templateCopy.root.children.push(HEADER_SECTION_ID, FOOTER_SECTION_ID);

  // Update the yLocation of each footer child using the calculated difference
  footerChildren.forEach((childId: string) => {
    const child = defaultPage.current.content[childId];
    child.config.yLocation += yLocationDifference;
    templateCopy[childId] = child;
  });

  // Add header children to the templateCopy
  headerChildren.forEach((childId: string) => {
    templateCopy[childId] = defaultPage.current.content[childId];
  });

  // Add header and footer children to the root children array in the original order
  templateCopy.root.children.push(...headerChildren, ...footerChildren);

  // Remove the placeholder header and footer from the templateCopy
  delete templateCopy[HEADER_PLACEHOLDER_ID];
  delete templateCopy[FOOTER_PLACEHOLDER_ID];
  templateCopy.root.children = templateCopy.root.children.filter(
    (childId: string) =>
      childId !== HEADER_PLACEHOLDER_ID && childId !== FOOTER_PLACEHOLDER_ID
  );

  return templateCopy;
}

export interface PagesPanelProps {
  pages: Array<Page>;
  siteDetails: Site;
  currentPage: PageVersion;
  createPage: (page: Partial<PageVersion>) => void;
  removePage: (pageId: number) => void;
  savePage: (pageId: number, page: PageVersion) => void;
  defaultPage: Page;
  handleActiveWidgetId?: (widgetId: string) => void;
  handleCurrentPageData?: (pageData: String) => void;
  handleNewPage?: (isNewPage: Boolean) => void;
  openPopup?: (open: boolean) => void;
  updateSite: (site: Site) => void;
  saveSite: () => void;
  handlePageBackgroundColor?: (data: string) => void;
  updateCurrentPage: (pageVersion: PageVersion) => void;
}

export const PagesPanel: React.FC<PagesPanelProps> = (
  pagesPanelProps: PagesPanelProps
) => {
  const {
    pages,
    siteDetails,
    currentPage,
    createPage,
    removePage,
    savePage,
    defaultPage,
    handleActiveWidgetId,
    handleCurrentPageData,
    handleNewPage,
    openPopup,
    updateSite,
    saveSite,
    updateCurrentPage,
  } = pagesPanelProps;
  const footerSectionYLocation =
    defaultPage?.current?.content[FOOTER_SECTION_ID]?.config.yLocation;
  const [isopen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [currentlyEditingPageId, setCurrentlyEditingPageId] = useState(null);
  const [
    currentlyEditingPage,
    setCurrentlyEditingPage,
  ] = useState<Page | null>();
  const [slugValue, setSlugValue] = useState('');
  const [pageName, setPageName] = useState('');
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const [settingsAnchorEl, setSettingsAnchorEl] = useState<SVGElement | null>(
    null
  );
  const [
    settingsTreeAnchorEl,
    setSettingsTreeAnchorEl,
  ] = useState<SVGElement | null>(null);
  const [openAdvancedSettings, setAdvancedSettingsFlag] = useState(false);
  const [isDeleteTriggered, setDeleteTriggered] = useState(false);
  const [isSettingsTreeOpen, setSettingsTreeOpen] = useState(false);
  // const [idps, setIdps] = useState<Array<IDP>>([]);
  const [selectedIdps, setSelectedIdps] = useState<IDropdownItem>(null);
  const [selectedCloneOption, setSelectedCloneOption] = useState<
    string | number
  >('None');
  const [blurred, setBlurred] = useState({
    slug: false,
    name: false,
    idp: false,
    cloneFrom: false,
  });
  const [pickerAnchorEl, setPickerAnchorEl] = useState<HTMLDivElement | null>(
    null
  );
  const initialPageBackground = { style: 'None', color: '', gradient: '' };
  const pickerOpen = Boolean(pickerAnchorEl);
  const pickerId = pickerOpen ? 'simple-popover' : undefined;
  const [authRequired, setAuthRequired] = useState(false);
  const [enableFooter, setEnableFooter] = useState(true);
  const [clonedPageContent, setClonedPageContent] = useState(null);
  const [pageValue, setPageValue] = React.useState('');
  const [pageData, setPageData] = useState('');
  const [newPage, setNewPage] = useState(false);
  const [pageBackground, setPageBackground] = useState(initialPageBackground);
  const slugRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
  const addNewPageOpen = Boolean(anchorEl);
  const id = addNewPageOpen ? 'simple-popover' : undefined;
  const settingsOpen = Boolean(settingsAnchorEl);
  const settingsId = settingsOpen ? 'settings-popover' : undefined;
  const settingsTreeOpen = Boolean(settingsTreeAnchorEl);
  const settingsTreeId = settingsTreeOpen ? 'settings-tree-popover' : undefined;

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const reset = () => {
    setAnchorEl(null);
    setSettingsAnchorEl(null);
    setSettingsTreeAnchorEl(null);
    setAdvancedSettingsFlag(false);
    setDeleteTriggered(false);
    setSettingsOpen(false);
    setSettingsTreeOpen(false);
    setDeleteTriggered(false);
    setBlurred({ slug: false, name: false, idp: false, cloneFrom: false });
    setSelectedIdps(null);
    setCurrentlyEditingPage(null);
    setCurrentlyEditingPageId(null);
    setAuthRequired(false);
    setEnableFooter(true);
    setSelectedCloneOption('None');
    setClonedPageContent(null);
  };

  //disable this for now as we are using default idp set

  // useEffect(() => {
  //   getIdps().then(idps => {
  //     setIdps(idps);
  //   });
  // }, []);
  const pageTemplates = useSelector((state: RootState) =>
    selectPageTemplatesByStatus(state, TEMPLATE_STATUS.PUBLISHED)
  );
  const userTenancy = useSelector((state: RootState) =>
    selectUserTenancy()(state)
  );
  const selectedPageTemplate = useSelector((state: RootState) =>
    selectSelectedPageTemplate()(state)
  );

  useEffect(() => {
    if (!userTenancy) {
      dispatch(fetchUserTenancy());
    }
  }, []);

  useEffect(() => {
    if (userTenancy && !pageTemplates.length) {
      const tenancies = [userTenancy];
      // Todo: Implement pagination TA25100230
      const limit = 100;
      const offset = 0;
      dispatch(fetchPageTemplates(tenancies, limit, offset));
    }
  }, [userTenancy]);

  useEffect(() => {
    if (selectedCloneOption) {
      if (selectedCloneOption === 'None') {
        setClonedPageContent(null);
      }
      if (
        selectedCloneOption === FORM_TEMPLATE_NAME ||
        selectedCloneOption === GENERAL_TEMPLATE_NAME
      ) {
        const selectedTemplate = templates.find(
          template => template.name === selectedCloneOption
        );
        setClonedPageContent(
          filterAndAdjustTemplate(
            selectedTemplate.widgets,
            defaultPage,
            footerSectionYLocation
          )
        );
      } else if (pageTemplates.some(t => t.title === selectedCloneOption)) {
        const templateId = pageTemplates.find(
          template => template.title === selectedCloneOption
        ).id;
        dispatch(fetchTemplateById(templateId));
        const selectedTemplate =
          selectedPageTemplate &&
          JSON.parse(selectedPageTemplate?.layoutDefinitions);
        if (selectedPageTemplate && selectedPageTemplate.layoutDefinitions) {
          setClonedPageContent(
            addHeaderFooterToTemplate(defaultPage, selectedTemplate)
          );
        }
      } else {
        const selectedPage = pages.find(
          page => page.current.pageId === selectedCloneOption
        );
        setClonedPageContent(selectedPage?.current?.content);
      }
    }
  }, [selectedCloneOption, selectedPageTemplate?.layoutDefinitions]);

  const getCloneDropdownOptions = () => {
    let i = 0;
    let options = [];
    options.push(
      <StyledDropdownOption disableRipple key={i++} value={'None'}>
        None
      </StyledDropdownOption>
    );
    options.push(<StyledSubHeader key={i++}>Templates</StyledSubHeader>);
    templates
      .filter(template => template.name !== 'Blank')
      .forEach((template: any) =>
        options.push(
          <StyledDropdownOption disableRipple key={i++} value={template.name}>
            {template.name}
          </StyledDropdownOption>
        )
      );
    pageTemplates.forEach((template: any) =>
      options.push(
        <StyledDropdownOption disableRipple key={i++} value={template.title}>
          {template.title}
        </StyledDropdownOption>
      )
    );
    options.push(<StyledSubHeader key={i++}>Pages</StyledSubHeader>);
    pages
      .filter(page => page.current.title !== 'Default Page')
      .forEach((page: Page) =>
        options.push(
          <StyledDropdownOption
            disableRipple
            key={i++}
            value={page.current.pageId}
          >
            {page.current.title}
          </StyledDropdownOption>
        )
      );
    return options;
  };

  const handleCloneChange = event => {
    const {
      target: { value },
    } = event;
    setSelectedCloneOption(value);
  };

  let idpDropdownOptions: IDropdownItem[] = [];
  let i = 0;
  IDP_VALUES_TO_USE.map(id => {
    if (siteDetails.idpHint !== '' && id.alias === siteDetails.idpHint) {
      idpDropdownOptions.push({
        id: i++,
        label: id.displayName ? id.displayName : id.alias,
        value: id.alias,
      });
    } else if (siteDetails.idpHint === '') {
      idpDropdownOptions.push({
        id: i++,
        label: id.displayName ? id.displayName : id.alias,
        value: id.alias,
      });
    }
  });

  useEffect(() => {
    if (currentlyEditingPageId !== null) {
      const page = pages.find(p => p.id === currentlyEditingPageId);
      setCurrentlyEditingPage(page);
      setPageName(page.current.title);
      setSlugValue(page.current.slug);
      setPageBackground(
        page?.current?.pageBackground
          ? page.current.pageBackground
          : initialPageBackground
      );
      setAuthRequired(page.current.authentication);
      setEnableFooter(page.current.footerEnabled);
      let selectedOptions: IDropdownItem = null;
      if (
        page?.current?.authentication === true &&
        !page?.current?.allowedIdps
      ) {
        selectedOptions = idpDropdownOptions.find(
          option => option.value === ''
        );
      } else {
        page?.current?.allowedIdps?.forEach(idp => {
          const matchingIdp = idpDropdownOptions.find(
            option => option.value === idp
          );
          selectedOptions = matchingIdp;
        });
      }
      setSelectedIdps(selectedOptions);
    }
  }, [currentlyEditingPageId]);

  useEffect(() => {
    pages.map(page => {
      if (page.current.title === pageValue) {
        history.push(`/sites/${siteDetails.id}/pages/${page.id}`);
        setPageValue('');
        handleCurrentPageData(pageData);
        handleNewPage(newPage);
      }
    });
  }, [pages]);

  const handleSettingsClick = (e: React.MouseEvent<any>, pageId) => {
    e.stopPropagation();
    e.preventDefault();
    setCurrentlyEditingPageId(pageId);
    setSettingsOpen(!isSettingsOpen);
    setSettingsAnchorEl(e.currentTarget);
    setNewPage(false);
    handleNewPage(false);
  };

  const debouncedHandlePageName = _.debounce((value: string) => {
    handleCurrentPageData(value);
  }, 300);

  const handlePageName = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setPageName(e.target.value);
    setPageData(e.target.value);
    debouncedHandlePageName(e.target.value);
  };

  const handlePageSlug = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSlugValue(e.target.value.toLowerCase().replace(/ +/g, '-'));
  };

  const pageExists = () => {
    return pages.find(
      page =>
        page.current.slug === slugValue &&
        page.current.pageId !== currentlyEditingPageId &&
        page.id !== currentlyEditingPageId
    );
  };

  const slugError = () => {
    if (!slugRegex.test(slugValue) && slugValue !== '') {
      return 'Invalid slug';
    } else if (pageExists() && pageExists()?.id !== currentlyEditingPageId) {
      return 'Slug already exists';
    } else if (slugValue === '' && blurred.slug) {
      return 'Slug required';
    } else {
      return '';
    }
  };

  const nameError = () => {
    if (pageName === '' && blurred.name) {
      return 'Name required';
    } else {
      return '';
    }
  };

  const dropdownError = () => {
    if (authRequired && !selectedIdps && blurred.idp) {
      return 'At least one selection required';
    } else {
      return '';
    }
  };

  const toggle = (isOpen: boolean) => {
    setIsOpen(isOpen);
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
    setPageName('');
    setSlugValue('');
    setPageBackground(initialPageBackground);
  };

  const handleClose = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    e.preventDefault();
    reset();
  };

  const handleColorEditClick = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    e.preventDefault();
    setPickerAnchorEl(e.currentTarget);
  };

  const handlePickerClose = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setPickerAnchorEl(null);
  };

  const handlePickerClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleInputClick = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
  };

  const handleColorChange = (newColor: string) => {
    if (pickerAnchorEl === document.querySelector('#background-color')) {
      setPageBackground(prevState => ({
        ...prevState,
        color: newColor,
      }));
    }
  };

  const handleDelete = () => {
    removePage(currentlyEditingPageId);
    handleActiveWidgetId('root');
    if (
      location.pathname.includes(currentPage?.pageId) ||
      location.pathname.includes(currentPage?.id)
    ) {
      const redirectPageId =
        currentlyEditingPageId === currentPage?.pageId
          ? siteDetails.horizonhomepageId
          : currentPage?.pageId;
      history.push(`/sites/${siteDetails.id}/pages/${redirectPageId}`);
    }
    setDeleteTriggered(false);
    reset();
  };

  const handleDeleteClick = () => {
    setDeleteTriggered(!isDeleteTriggered);
  };
  const handleEditPageClick = () => {
    history.push(`/sites/${siteDetails.id}/pages/${currentlyEditingPage?.id}`);
    toggle(false);
    reset();
  };
  const handleDuplicateClick = () => {
    const copiedPage: Partial<PageVersion> = {
      title: `${currentlyEditingPage?.current?.title} - Copy`,
      slug: `${currentlyEditingPage?.current?.slug}-copy`,
      headerEnabled: currentlyEditingPage?.current?.headerEnabled,
      footerEnabled: currentlyEditingPage?.current?.authentication,
      allowedIdps: currentlyEditingPage?.current?.allowedIdps,
      content: currentlyEditingPage?.current?.content,
      pageBackground: currentlyEditingPage?.current?.pageBackground,
    };
    createPage(copiedPage);
    reset();
  };

  const getDeletePopup = () => {
    return (
      <StyledPageDelete>
        <StyledCard onClick={handleInputClick} data-test-id="delete-styledcard">
          <Card
            header={<Label style={StyledTitle}>Confirm Deletion</Label>}
            data-test-id="confirm-delete-card"
          >
            <StyledTextNameDelete data-test-id="delete-label">
              Are you sure you want to delete{' '}
              {currentlyEditingPage?.current?.title}?
            </StyledTextNameDelete>
            <ButtonWrapperFolder>
              <StyledButtonUitkSave
                onClick={handleDelete}
                data-test-id="delete-confirm"
              >
                Delete
              </StyledButtonUitkSave>
              <StyledButtonUitkSave
                variant="tertiary"
                onClick={handleDeleteClick}
                data-test-id="delete-cancel-button"
              >
                Cancel
              </StyledButtonUitkSave>
            </ButtonWrapperFolder>
          </Card>
        </StyledCard>
      </StyledPageDelete>
    );
  };

  const closePanel = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    e.preventDefault();
    reset();
  };

  const addNewPage = () => {
    openPopup(false);
    const newPage: Partial<PageVersion> = {
      title: pageName,
      slug: slugValue,
      content: clonedPageContent
        ? clonedPageContent
        : defaultPage
        ? defaultPage.current.content
        : {
            root: {
              id: 'root',
              type: 'root',
              children: [],
              config: {},
              pageBackground: pageBackground,
            },
          },
      headerEnabled: true,
      footerEnabled: true,
      authentication: authRequired,
      allowedIdps: authRequired ? [selectedIdps.value.toString()] : [],
      pageBackground: pageBackground,
    };
    createPage(newPage);
    reset();
    setPageValue(pageName);
    setPageData(pageName);
    setNewPage(true);
  };

  const editPage = () => {
    const updatedPage: PageVersion = {
      ...currentlyEditingPage.current,
      title: pageName,
      slug: slugValue,
      pageBackground: pageBackground,
      authentication: authRequired,
      footerEnabled: enableFooter,
      allowedIdps: authRequired ? [selectedIdps.value.toString()] : [],
    };
    if (updatedPage.content[FOOTER_SECTION_ID]) {
      const footerSection = updatedPage.content[FOOTER_SECTION_ID];
      footerSection.config.visible = enableFooter;
      const footerChildren = footerSection.children;
      footerChildren.forEach(childId => {
        updatedPage.content[childId].config.visible = enableFooter;
      });
    }
    updateCurrentPage(updatedPage);
    savePage(currentlyEditingPageId, updatedPage);
    reset();
  };

  const handlePageBackgroundStyleChange = (
    e: ChangeEvent<HTMLSelectElement>
  ) => {
    setPageBackground(prevState => ({
      ...prevState,
      style: e.target.value,
    }));
  };

  return (
    <Menu data-test-id="pages-panel">
      <StyledMenuItem
        id="pages-panel-sidebar"
        icon={Pages}
        onClick={() => toggle(true)}
        rootStyles={{
          ['& > .' + menuClasses.button]: {
            color: theme.colors.aux.white,
            '&:hover': {
              backgroundColor: theme.colors.aux.white,
            },
            '&:focus': {
              backgroundColor: '#D9F6FA',
            },
          },
          ['.' + menuClasses.subMenuContent]: {
            backgroundColor: theme.colors.aux.white,
            width: isopen ? '264px' : '0px',
            margin: '-3px',
          },
          [`.${menuClasses.icon}`]: {
            '&:hover': {
              backgroundColor: theme.colors.aux.skyBlue,
              fill: theme.colors.aux.darkNavyBlue,
            },
          },
        }}
        title="Pages"
        data-test-id="pages-menu-icon"
      >
        <div
          style={{ height: '100vh', overflow: 'auto' }}
          onClick={handleInputClick}
        >
          <TopSection data-test-id="pages-heading-section">
            <StyledHeading>Pages</StyledHeading>
            <Icon
              icon="Collapse"
              onClick={() => toggle(false)}
              width="22px"
              style={{ marginLeft: '137px' }}
            />
          </TopSection>
          <div
            style={
              !siteDetails.access.edit
                ? { pointerEvents: 'none', opacity: '0.4' }
                : {}
            }
          >
            {pages.map(
              page =>
                page.current.slug !== 'defaultContentPage' && (
                  <List key={page.current?.pageId}>
                    <ListItem
                      style={{ marginTop: '6px' }}
                      data-test-id="page-list-icon"
                    >
                      <ListItemIcon data-test-id="list-page-icon">
                        {PageIcon}
                      </ListItemIcon>
                      <StyledListItem
                        id="switch-list-label-wifi"
                        primary={page?.current?.title}
                        data-test-id="home-list-item"
                      />
                      <StyledListIcon>
                        {page?.current?.pageId ===
                          siteDetails.horizonhomepageId && (
                          <ListItemIcon
                            data-test-id="home-list-icon"
                            className="home"
                          >
                            {Home}
                          </ListItemIcon>
                        )}
                        <ListItemIcon
                          data-test-id="settings-list-icon"
                          className="settings"
                        >
                          <Icon
                            data-test-id="settings-icon"
                            icon="MoreVertical"
                            height={18}
                            width={15}
                            onClick={e => handleSettingsClick(e, page.id)}
                          ></Icon>
                        </ListItemIcon>
                      </StyledListIcon>
                    </ListItem>
                    <Divider style={dividerStyles} />
                  </List>
                )
            )}

            <StyledPopover
              id={settingsId}
              open={settingsOpen}
              anchorEl={settingsAnchorEl}
              onClose={handleClose}
              anchorReference="anchorEl"
              anchorPosition={{ top: 0, left: 350 }}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              data-test-id="settings-item-popover"
              PaperProps={{
                style: {
                  left: '350px !important',
                },
              }}
            >
              {!openAdvancedSettings && !isDeleteTriggered && (
                <>
                  <StyledPage
                    style={StyledNewPage}
                    onClick={handleInputClick}
                    data-test-id="settings-page"
                  >
                    <StyledSettingsCard data-test-id="advanced-settings-card">
                      <StyledSettingName
                        data-test-id="advanced-settings-name"
                        onClick={() => {
                          setAdvancedSettingsFlag(true);
                        }}
                      >
                        <Icon icon="Edit" width={15} style={iconStyle}></Icon>
                        <StyledTextName>Advanced Settings</StyledTextName>
                      </StyledSettingName>
                      <Divider style={settingsDividerStyles}></Divider>
                      <StyledSettingName
                        data-test-id="edit-page-panel"
                        onClick={handleEditPageClick}
                      >
                        <Icon icon="Edit" width={15} style={iconStyle}></Icon>
                        <StyledTextName>Edit Page</StyledTextName>
                      </StyledSettingName>
                      <StyledSettingName
                        data-test-id="duplicate-page"
                        onClick={handleDuplicateClick}
                      >
                        <Icon icon="Edit" width={15} style={iconStyle}></Icon>
                        <StyledTextName> Duplicate Page</StyledTextName>
                      </StyledSettingName>
                      {currentlyEditingPageId !==
                        siteDetails.horizonhomepageId && (
                        <StyledSettingName
                          data-test-id="delete-page"
                          onClick={handleDeleteClick}
                        >
                          <Icon
                            icon="Delete"
                            width={15}
                            style={iconStyle}
                          ></Icon>
                          <StyledTextName>Delete Page</StyledTextName>
                        </StyledSettingName>
                      )}
                    </StyledSettingsCard>
                  </StyledPage>
                </>
              )}
              {openAdvancedSettings && (
                <StyledPage style={StyledNewPage} onClick={handleInputClick}>
                  <StyledCard
                    onClick={handleInputClick}
                    data-test-id="advanced-styledcard"
                  >
                    <Card
                      header={
                        <Label style={StyledTitle}>Advanced Settings</Label>
                      }
                      data-test-id="advanced-card"
                    >
                      <Label
                        style={labelStyles}
                        required
                        data-test-id="advanced-page-name-label"
                      >
                        Page Name
                      </Label>
                      <StyledFormControl
                        id={'edit-name-textbox'}
                        error={nameError()}
                      >
                        <TextInput
                          style={TextBoxStyles}
                          value={pageName}
                          onChange={handlePageName}
                          onBlur={() => setBlurred({ ...blurred, name: true })}
                          data-test-id="advanced-page-name-textbox"
                        />
                      </StyledFormControl>
                      <div>
                        <Label
                          style={labelStyles}
                          required
                          data-test-id="advanced-page-slug-label"
                        >
                          Page Slug
                        </Label>
                        <HtmlTooltip
                          title={pageSlugContent}
                          arrow
                          placement="bottom-start"
                          style={{ marginLeft: '8px' }}
                        >
                          <IconButton>
                            <InfoOutlinedIcon
                              fontSize="small"
                              style={{ color: 'darkblue' }}
                            />
                          </IconButton>
                        </HtmlTooltip>
                        <StyledFormControl
                          id={'edit-slug-textbox'}
                          error={slugError()}
                        >
                          <TextInput
                            style={TextBoxStyles}
                            value={slugValue}
                            onChange={handlePageSlug}
                            onBlur={() =>
                              setBlurred({ ...blurred, slug: true })
                            }
                            data-test-id="advanced-page-slug-textbox"
                          />
                        </StyledFormControl>
                      </div>
                      <StyledSlug data-test-id="advanced-page-slug-text">
                        {' '}
                        {siteDetails.domain}/{slugValue}
                      </StyledSlug>
                      <Label
                        style={labelStyles}
                        data-test-id="advanced-page-name-label"
                      >
                        Page Color
                      </Label>
                      <BackgroundStyleDropdowns>
                        <PageBackgroundStyle
                          onChange={handlePageBackgroundStyleChange}
                          value={pageBackground?.style}
                          name="style"
                        >
                          {pageBackgroundStyles.map((style, index) => (
                            <option key={index} value={style}>
                              {style}
                            </option>
                          ))}
                        </PageBackgroundStyle>
                        {pageBackground?.style === 'Color' && (
                          <>
                            <BackgroundColorWrapper>
                              <ColorButtonWrapper
                                id="background-color"
                                onClick={handleColorEditClick}
                              >
                                <ColorButton color={pageBackground?.color} />
                              </ColorButtonWrapper>
                              <StyledPickerPopover
                                data-test-id="widget-color-picker-popover"
                                id={pickerId}
                                open={pickerOpen}
                                onClick={handlePickerClick}
                                onClose={handlePickerClose}
                                anchorEl={pickerAnchorEl}
                                anchorOrigin={{
                                  vertical: 'center',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'center',
                                  horizontal: 'right',
                                }}
                              >
                                <StyledPickerWrapper>
                                  <StyledPickerHeader data-test-id="styled-picker-header">
                                    Color Selector
                                  </StyledPickerHeader>
                                  <StyledClose
                                    data-test-id="close-icon"
                                    onClick={handlePickerClose}
                                  />
                                </StyledPickerWrapper>
                                <GradientColorPicker
                                  data-test-id="background-color"
                                  value={undefined}
                                  handleColorChange={handleColorChange}
                                  onChange={handleColorChange}
                                  updateSite={updateSite}
                                  saveSite={saveSite}
                                  anchorEl={pickerAnchorEl}
                                  site={siteDetails}
                                />
                              </StyledPickerPopover>
                            </BackgroundColorWrapper>
                          </>
                        )}
                      </BackgroundStyleDropdowns>
                      <StyledCheckboxControl id="checkbox-controlled">
                        <Checkbox
                          checked={authRequired}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setAuthRequired(e.target.checked)
                          }
                        >
                          Authentication required?
                        </Checkbox>
                      </StyledCheckboxControl>
                      <StyledCheckboxControl id="checkbox-controlled-hide-footer">
                        <Checkbox
                          checked={enableFooter}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setEnableFooter(e.target.checked)
                          }
                        >
                          Enable Footer
                        </Checkbox>
                      </StyledCheckboxControl>
                      {authRequired && (
                        <>
                          <Label
                            style={labelStyles}
                            required
                            data-test-id="identity-provider-label"
                          >
                            Identity Provider Selection
                          </Label>
                          <StyledDropdownControl
                            id="dropdown-multi-controlled"
                            error={dropdownError()}
                          >
                            <StyledDropdown
                              type="single"
                              onChange={setSelectedIdps}
                              items={idpDropdownOptions}
                              placeholderText={'None'}
                              data-test-id="advanced-identity-provider-dropdown"
                              value={selectedIdps}
                              disableSelectAll
                              onBlur={() =>
                                setBlurred({ ...blurred, idp: true })
                              }
                            />
                          </StyledDropdownControl>
                        </>
                      )}
                      <ButtonWrapper1>
                        <StyledAlternativeButton
                          variant="secondary"
                          onClick={() => {
                            setAdvancedSettingsFlag(false);
                          }}
                          size="m"
                          data-test-id="advanced-previous-button"
                        >
                          Previous
                        </StyledAlternativeButton>
                        <Button
                          onPress={editPage}
                          data-test-id="advanced-save-button"
                          disabled={
                            pageName === '' ||
                            slugValue === '' ||
                            slugError() !== '' ||
                            nameError() !== '' ||
                            dropdownError() !== ''
                          }
                        >
                          Save
                        </Button>
                        <Button
                          variant="tertiary"
                          onClick={() => {
                            setAdvancedSettingsFlag(false);
                          }}
                          block
                          className={'mt-base'}
                          data-test-id="advanced-popover-cancel-button"
                        >
                          Cancel
                        </Button>
                      </ButtonWrapper1>
                    </Card>
                  </StyledCard>
                </StyledPage>
              )}
              {isDeleteTriggered && getDeletePopup()}
            </StyledPopover>
            {isSettingsTreeOpen && (
              <StyledPopover
                id={settingsTreeId}
                open={settingsTreeOpen}
                anchorEl={settingsTreeAnchorEl}
                onClose={handleClose}
                anchorReference="anchorEl"
                anchorPosition={{ top: 0, left: 350 }}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                data-test-id="settings-tree-item-popover"
              >
                {isDeleteTriggered && getDeletePopup()}
              </StyledPopover>
            )}

            <StyledAddItemButton
              variant="secondary"
              onClick={handleClick}
              icon={<Add />}
              data-test-id="add-item-button"
              disabled={!siteDetails.access.edit}
              iconLeft
            >
              Add Page
            </StyledAddItemButton>
            <StyledPopover
              id={id}
              open={addNewPageOpen}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorReference="anchorPosition"
              anchorPosition={{ top: 0, left: 350 }}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              data-test-id="add-item-popover"
            >
              {addNewPageOpen && (
                <StyledPage style={StyledNewPage} onClick={handleInputClick}>
                  <StyledCard
                    onClick={handleInputClick}
                    data-test-id="add-newpage-styledcard"
                  >
                    <Card
                      header={<Label style={StyledTitle}>Add New Page</Label>}
                      data-test-id="add-newpage-card"
                    >
                      <Label
                        style={labelStyles}
                        required
                        data-test-id="page-name-label"
                      >
                        Page Name
                      </Label>
                      <StyledFormControl
                        id={'new-name-textbox'}
                        error={nameError()}
                      >
                        <TextInput
                          style={TextBoxStyles}
                          value={pageName}
                          onChange={handlePageName}
                          data-test-id="page-name-textbox"
                        />
                      </StyledFormControl>
                      <Label
                        style={labelStyles}
                        required
                        data-test-id="page-slug-label"
                      >
                        Page Slug
                      </Label>
                      <HtmlTooltip
                        title={pageSlugContent}
                        arrow
                        placement="bottom-start"
                        style={{ marginLeft: '8px' }}
                      >
                        <IconButton>
                          <InfoOutlinedIcon
                            fontSize="small"
                            style={{ color: 'darkblue' }}
                          />
                        </IconButton>
                      </HtmlTooltip>
                      <StyledFormControl
                        id={'new-slug-textbox'}
                        error={slugError()}
                      >
                        <TextInput
                          style={TextBoxStyles}
                          value={slugValue}
                          onChange={handlePageSlug}
                          data-test-id="page-slug-textbox"
                        />
                      </StyledFormControl>
                      <StyledSlug data-test-id="page-slug-text">
                        {' '}
                        {siteDetails.domain}/{slugValue}
                      </StyledSlug>

                      <Label
                        style={labelStyles}
                        required
                        data-test-id="clone-label"
                      >
                        Clone From
                      </Label>
                      <SelectWrapper>
                        <StyledSelect
                          defaultValue={`None`}
                          onChange={handleCloneChange}
                          id={'clone-select'}
                          value={selectedCloneOption}
                          MenuProps={{
                            disablePortal: true,
                            onClick: e => {
                              e.preventDefault();
                            },
                          }}
                        >
                          {getCloneDropdownOptions()}
                        </StyledSelect>
                      </SelectWrapper>
                      <Label
                        style={labelStyles}
                        data-test-id="advanced-page-name-label"
                      >
                        Page Color
                      </Label>
                      <BackgroundStyleDropdowns>
                        <PageBackgroundStyle
                          onChange={handlePageBackgroundStyleChange}
                          value={pageBackground?.style}
                          name="style"
                        >
                          {pageBackgroundStyles.map((style, index) => (
                            <option key={index} value={style}>
                              {style}
                            </option>
                          ))}
                        </PageBackgroundStyle>
                        {pageBackground?.style === 'Color' && (
                          <>
                            <BackgroundColorWrapper>
                              <ColorButtonWrapper
                                id="background-color"
                                onClick={handleColorEditClick}
                              >
                                <ColorButton color={pageBackground?.color} />
                              </ColorButtonWrapper>
                              <StyledPickerPopover
                                data-test-id="widget-color-picker-popover"
                                id={pickerId}
                                open={pickerOpen}
                                onClick={handlePickerClick}
                                onClose={handlePickerClose}
                                anchorEl={pickerAnchorEl}
                                anchorOrigin={{
                                  vertical: 'center',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'center',
                                  horizontal: 'right',
                                }}
                              >
                                <StyledPickerWrapper>
                                  <StyledPickerHeader data-test-id="styled-picker-header">
                                    Color Selector
                                  </StyledPickerHeader>
                                  <StyledClose
                                    data-test-id="close-icon"
                                    onClick={handlePickerClose}
                                  />
                                </StyledPickerWrapper>
                                <GradientColorPicker
                                  data-test-id="background-color"
                                  value={undefined}
                                  handleColorChange={handleColorChange}
                                  onChange={handleColorChange}
                                  updateSite={updateSite}
                                  saveSite={saveSite}
                                  anchorEl={pickerAnchorEl}
                                  site={siteDetails}
                                />
                              </StyledPickerPopover>
                            </BackgroundColorWrapper>
                          </>
                        )}
                      </BackgroundStyleDropdowns>
                      {siteDetails.idpFlag === 'enable' && (
                        <StyledCheckboxControl id="checkbox-controlled">
                          <Checkbox
                            checked={authRequired}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setAuthRequired(e.target.checked)
                            }
                          >
                            Authentication required?
                          </Checkbox>
                        </StyledCheckboxControl>
                      )}
                      {authRequired && (
                        <>
                          <Label
                            style={labelStyles}
                            required
                            data-test-id="identity-provider-label"
                          >
                            Identity Provider Selection
                          </Label>
                          <StyledDropdownControl
                            id="dropdown-multi-controlled"
                            error={dropdownError()}
                          >
                            <StyledDropdown
                              disableSelectAll
                              type="single"
                              onChange={setSelectedIdps}
                              items={idpDropdownOptions}
                              placeholderText={'None'}
                              data-test-id="identity-provider-dropdown"
                              value={selectedIdps}
                              onBlur={() =>
                                setBlurred({ ...blurred, idp: true })
                              }
                            />
                          </StyledDropdownControl>
                        </>
                      )}
                      <ButtonWrapper1>
                        <StyledAlternativeButton
                          variant="secondary"
                          onClick={closePanel}
                          size="m"
                          data-test-id="previous-button"
                        >
                          Previous
                        </StyledAlternativeButton>
                        <Button
                          onPress={addNewPage}
                          data-test-id="save-button"
                          disabled={
                            pageName === '' ||
                            slugValue === '' ||
                            slugError() !== '' ||
                            nameError() !== '' ||
                            dropdownError() !== ''
                          }
                        >
                          Save
                        </Button>
                        <Button
                          variant="tertiary"
                          onClick={handleClose}
                          block
                          className={'mt-base'}
                          data-test-id="popover-cancel-button"
                          size="m"
                        >
                          Cancel
                        </Button>
                      </ButtonWrapper1>
                    </Card>
                  </StyledCard>
                </StyledPage>
              )}
            </StyledPopover>
          </div>
        </div>
      </StyledMenuItem>
    </Menu>
  );
};
