import { PureComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageContext from 'shared/util/PageContext';
import ThemeContext from 'shared/util/ThemeContext';
import WidgetConnectorNew from '../SiteBuilder/WidgetConnector';
import styled from 'styled-components';
import BuilderTopMenu from 'builder/components/BuilderTopMenu';
import Feedback from 'shared/components/Feedback/Feedback';
import { User } from 'shared/state/misc/oidc';
import { TEMPLATE_EDITOR_PAGE_ID } from '../PageTemplates/constants';

interface PreviewProps {
  site: Site;
  pages: Array<Page>;
  basePath: string;
  user: User;
}

const PreviewContainer = styled.div`
  position: relative;
  margin: 2px auto;
`;

const StyledPreview = styled.div``;

class Preview extends PureComponent<PreviewProps> {
  render() {
    const { site, pages, user, basePath } = this.props;
    if (!site) {
      return <Redirect to="/sites" />;
    }

    const renderContent = (page: Page) => (
      <PageContext.Provider
        value={{
          site,
          pages,
          page,
        }}
      >
        <BuilderTopMenu
          siteId={site.id}
          pageId={page.id}
          isPreviewMode={true}
        />
        <StyledPreview
          style={{
            backgroundColor: page.current.pageBackground?.color
              ? page.current.pageBackground?.color
              : '#FFFFFF',
          }}
        >
          <PreviewContainer>
            <WidgetConnectorNew
              currentPage={page.current}
              siteId={site.id}
              pageId={page.id}
              widgetId={''}
              page={page}
            />
            <Feedback
              value={site.header.feedback}
              siteId={site.id}
              siteName={site.name}
              user={user}
            />
          </PreviewContainer>
        </StyledPreview>
      </PageContext.Provider>
    );

    const siteRoutes = pages.map(page => (
      <Route
        key={page.id}
        path={`${basePath}/${page.current.slug}`}
        exact
        render={() => renderContent(page)}
      />
    ));

    return (
      <ThemeContext.Provider
        value={{
          theme: site.theme,
        }}
      >
        <Switch>
          {siteRoutes}
          <Route
            path="/page-templates/:templateId/preview"
            exact
            render={() => {
              const page = pages.find(p => p.id === TEMPLATE_EDITOR_PAGE_ID);
              return page ? (
                renderContent(page)
              ) : (
                <Redirect to="/page-templates" />
              );
            }}
          />
          <Route>
            <Redirect
              to={`${basePath}/${
                pages.find(page => page.id === site.horizonhomepageId)?.current
                  .slug
              }`}
            />
          </Route>
        </Switch>
      </ThemeContext.Provider>
    );
  }
}

export default Preview;
