import { Fragment, PureComponent } from 'react';
import { Provider } from 'react-redux';
import { loadUser, OidcProvider } from 'redux-oidc';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createStore } from 'shared/state/store';
import { State as SiteState } from 'shared/state/ducks/sites';
import { CurrentSite } from 'client/types';
import { getUserManager, initUserManager } from 'client/util/userManager';
import {
  setPostLoginPage,
  setPostLoginUrl,
} from 'shared/util/locationPersistence';
import SiteRoot from './SiteRoot';
import { Toolkit } from '@uitk/react';
import track from 'react-tracking';
import { linkDeveloperTheme } from 'link-ui-react';
import { optumUHCSansFontsStyles } from 'shared/util/fontsStyles';

import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { ThemeProvider as MUIThemeProvider } from '@mui/material';
import { muiTheme } from 'shared/muiThemeOverrides';
import { persistData } from 'shared/util/analyticsPersistence';

interface SiteAppProps {
  site: CurrentSite;
}

interface SiteAppState {
  store: any;
}

const GlobalFontStyles = createGlobalStyle`
 ${optumUHCSansFontsStyles};
`;

export class SiteApp extends PureComponent<SiteAppProps, SiteAppState> {
  private store: any;

  constructor(props: SiteAppProps) {
    super(props);

    /**
     * Transform the injected @type {CurrentSite} to a @type {SiteState} structure
     */
    const initialState: { sites: SiteState } = {
      sites: {
        meta: {},
        publishMeta: {},
        saveSiteMeta: {},
        data: [
          {
            ...props.site,
            pages: props.site.pages.reduce((acc, version) => {
              acc[version.pageId] = {
                id: version.pageId,
                current: version,
                versions: [],
              };
              return acc;
            }, {} as { [index: number]: Page }),
            meta: {},
            homepageId: props.site.horizonhomepageId,
            postLoginPageId: props.site.horizonpostLoginPageId,
          },
        ],
        activeWidgetId: 'root',
        createSiteMeta: {},
        newSiteId: undefined,
      },
    };
    const store = createStore({ ...initialState, published: true });
    this.store = store;
    props.site.postLoginType === 'url'
      ? setPostLoginUrl(props.site.postLoginUrl)
      : setPostLoginPage(
          props.site.pages.find(p => {
            return p.pageId === props.site.horizonpostLoginPageId;
          }) ||
            (props.site.pages.length > 0 && props.site.pages[0])
        );
    const setPostLogoutPage = (
      postLogoutPage: PostLogoutPage,
      pages: PageVersion[],
      domain: string
    ) => {
      if (postLogoutPage && postLogoutPage.type === 'page') {
        return `https://${domain}/${pages.find(
          p => p.pageId === props.site.horizonhomepageId
        ).slug || pages[0].slug}`;
      } else if (postLogoutPage && postLogoutPage.type === 'url') {
        return postLogoutPage.url;
      } else {
        return `${window.location.origin}`;
      }
    };

    initUserManager(
      props.site.clientId,
      props.site.idpHint,
      setPostLogoutPage(
        props.site.postLogoutPage,
        props.site.pages,
        props.site.domain
      )
    );
    loadUser(store, getUserManager());
  }

  render() {
    const { site } = this.props;
    const store = this.store;
    muiTheme.typography.fontFamily =
      site?.theme?.font?.family?.base?.value || muiTheme.typography.fontFamily;

    return (
      <Fragment>
        <ThemeProvider theme={linkDeveloperTheme}>
          <MUIThemeProvider theme={muiTheme}>
            <Toolkit appId="LINK_HORIZON_SELF_SERVICE">
              <GlobalFontStyles />
              <Provider store={store}>
                <OidcProvider store={store} userManager={getUserManager()}>
                  <SiteRoot siteId={site.id} basename="/" />
                </OidcProvider>
              </Provider>
              <ToastContainer
                position="bottom-right"
                hideProgressBar={true}
                autoClose={3000}
                closeButton={false}
              />
            </Toolkit>
          </MUIThemeProvider>
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default track(
  {},
  {
    dispatch: trackingData => persistData(trackingData),
  }
)(SiteApp);
