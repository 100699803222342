import ButtonWidget from './ButtonWidget';
import ButtonWidgetControl from './ButtonWidgetControl';
import { Config } from './types';
import {
  defaultSizeLocationData,
  defaultTypographyData,
} from 'builder/util/constants';

export default {
  type: 'Button',
  displayName: 'Button',
  icon: 'Button',
  site: '',
  initialConfig: {
    iconStyle: 'Without Icon',
    icon: {
      url: '',
      alt: '',
    },
    iconPlacement: 'Left',
    ...defaultSizeLocationData,
    width: 240,
    height: 80,
    minWidth: 75,
    minHeight: 85,
    ...defaultTypographyData,
    textColor: 'white',
    characterSpacing: 0,
    lineSpacing: 1,
    align: 'center',
    centerAlign: true,
    borderData: {
      thickness: 0,
      radius: 10,
    },
    paddingData: {
      top: 10,
      bottom: 10,
      left: 10,
      right: 10,
    },
    uniqueId: '123abc',
    lineColor: 'white',
    backgroundData: { style: 'Color', color: '#002667' },
    parentId: '',
    currentTab: 0,
    displayText: 'My Button Text',
    newTab: false,
    linkType: 'None',
    emailAddress: '',
    emailSubject: '',
    url: '',
    iconColor: '',
    hoverData: {
      iconStyle: 'Without Icon',
      icon: {
        url: '',
        alt: '',
      },
      iconPlacement: 'Left',
      ...defaultSizeLocationData,
      width: 240,
      height: 90,
      minWidth: 20,
      minHeight: 20,
      ...defaultTypographyData,
      textColor: 'white',
      align: 'center',
      centerAlign: true,
      borderData: {
        thickness: 0,
        radius: 10,
      },
      paddingData: {
        top: 10,
        bottom: 10,
        left: 10,
        right: 10,
      },
      uniqueId: '123abc',
      lineColor: 'white',
      backgroundData: { style: 'Color', color: '#002667' },
      parentId: '',
      site: null,
      displayText: '',
      uploadedFile: {
        name: '',
        url: '',
      },
    },
    visible: true,
  },
  components: {
    render: ButtonWidget,
    control: ButtonWidgetControl,
  },
  copyable: true,
} as HorizonWidgetDefinition<Config>;
