import TemplateHeaderFooterPlaceholder from './TemplateHeaderFooterPlaceholder';
import { BaseConfig } from '../shared/types';
import { widgetTypes } from '../../../builder/util/constants';

export default ({
  type: widgetTypes.TemplateHeaderFooterPlaceholder,
  displayName: 'TemplateHeaderFooterPlaceholder',
  icon: 'TemplateHeaderFooterPlaceholder',
  initialConfig: {
    spacing: {
      top: '',
      right: '',
      bottom: '',
      left: '',
    },
    xLocation: 0,
    yLocation: 0,
    isHorizonWidget: true,
    isCustomWidget: false,
    enableFullScreen: true,
  },
  components: {
    render: TemplateHeaderFooterPlaceholder,
    control: null,
  },
  copyable: false,
} as unknown) as HorizonWidgetDefinition<BaseConfig>;
