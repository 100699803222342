import { css } from 'styled-components';

export const optumUHCSansFontsStyles = css`
  @font-face {
    font-family: 'OptumSans';
    src: url('https://cdn.linkhealth.com/common/fonts/OptumSans/OptumSans-Regular.ttf');
    font-weight: 100;
    font-style: normal;
  }
  @font-face {
    font-family: 'OptumSans';
    src: url('https://cdn.linkhealth.com/common/fonts/OptumSans/OptumSans-RegularItalic.ttf');
    font-weight: 100;
    font-style: italic;
  }
  @font-face {
    font-family: 'OptumSans';
    src: url('https://cdn.linkhealth.com/common/fonts/OptumSans/OptumSans-Bold.ttf');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'OptumSans-XBold';
    src: url('https://cdn.linkhealth.com/common/fonts/OptumSans/OptumSans-XBold.ttf');
  }
  @font-face {
      font-family: 'Optum Sans';
      src: url('https://cdn.linkhealth.com/common/fonts/OptumSans/OptumSans-Regular.ttf');
      font-weight: 100;
      font-style: normal;
  }
  @font-face {
      font-family: 'Optum Sans';
      src: url('https://cdn.linkhealth.com/common/fonts/OptumSans/OptumSans-RegularItalic.ttf');
      font-weight: 100;
      font-style: italic;
  }
  @font-face {
      font-family: 'Optum Sans';
      src: url('https://cdn.linkhealth.com/common/fonts/OptumSans/OptumSans-Bold.ttf');
      font-weight: 700;
      font-style: normal;
  }
  @font-face {
    font-family: 'UHCSans';
    src: url('https://cdn.linkhealth.com/common/fonts/UHCSans/UHCSans-Medium.otf');
    font-weight: 100;
    font-style: normal;
  }
  @font-face {
    font-family: 'UHCSans';
    src: url('https://cdn.linkhealth.com/common/fonts/UHCSans/UHCSans-MediumItalic.otf');
    font-weight: 100;
    font-style: italic;
  }
  @font-face {
    font-family: 'UHCSans';
    src: url('https://cdn.linkhealth.com/common/fonts/UHCSans/UHCSans-SemiBold.otf');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'UHCSans';
    src: url('https://cdn.linkhealth.com/common/fonts/UHCSans/UHCSans-SemiBoldItalic.otf');
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: 'UHCSans';
    src: url('https://cdn.linkhealth.com/common/fonts/UHCSans/UHCSans-Bold.otf');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'UHCSans';
    src: url('https://cdn.linkhealth.com/common/fonts/UHCSans/UHCSans-BoldItalic.otf');
    font-weight: 700;
    font-style: italic;
  }
  @font-face {
    font-family: 'UHCSerifHeadline';
    src: url('https://cdn.linkhealth.com/common/fonts/UHCSans/UHCSerifHeadline-Semibold.otf');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'UHCSerifHeadline';
    src: url('https://cdn.linkhealth.com/common/fonts/UHCSans/UHCSerifHeadline-SemiboldItalic.otf');
    font-weight: 700;
    font-style: italic;
  },
`;
