import React, { useEffect, useState, ChangeEvent } from 'react';
import { Panel, Label } from '@uitk/react';
import { Arrow } from 'builder/scenes/SiteBuilder/SettingsPanel/SettingsPanel';
import {
  SizeLocationData,
  WidgetSizeLocation,
} from '../../../builder/components/WidgetSizeLocation';
import { Config } from './types';
import {
  Padding,
  PaddingData,
} from 'builder/components/WidgetConfigurations/Padding';
import {
  Border,
  BorderData,
} from 'builder/components/WidgetConfigurations/Border';
import { ImageUpload } from 'builder/components/WidgetConfigurations/Image/ImageUpload';
import EnableFullScreenWidth from 'builder/components/WidgetConfigurations/EnableFullScreenWidth';
import {
  SizeLocationPanel,
  StyledPanel,
  StyledPanelTitle,
} from '../Text/TextWidgetControl';
import styled from 'styled-components';
import { StyledLabel } from 'builder/components/WidgetConfigurations/EnableFullScreenWidth';
import { LinkOptionsWrapper } from '../Button/ButtonWidgetControl';
import { MainLabelStyles } from 'builder/components/WidgetConfigurations/Typography/Typography';
import {
  StyledCheckBox,
  StyledForm,
  StyledFormControl,
  StyledOuterDiv,
  StyledSelect,
} from '../Link/LinkWidgetControl';
import S3UploadFile from 'builder/components/S3UploadFile';
import { UploadedFile } from '../shared/types';
import {
  PDF_AND_WORD_REGEX,
  VALID_UPLOAD_FILES,
  defaultLinkOptions,
} from 'builder/util/constants';
import {
  StyledPickerPopover,
  StyledPickerWrapper,
  StyledPickerHeader,
  StyledClose,
} from 'builder/components/GradientColorPicker/GradientColorPicker';
import GradientColorPicker from 'builder/components/GradientColorPicker/GradientColorPicker';
import { toast } from 'react-toastify';
import { StyledTextInput } from 'builder/components/WidgetConfigurations/TextInput/TextInputPanel';

const AltText = styled.div`
  display: block;
  margin-top: 0.5rem;
`;

const ImageWidgetControl: React.FC<WidgetControlProps<Config>> = (
  props: WidgetControlProps<Config>
) => {
  const { value, onChange, widgetState, pages } = props;
  const [altText, setAltText] = useState(widgetState.config.altText);
  const [linkType, setLinkType] = useState(widgetState.config.linkType);
  const [pageValue, setPageValue] = useState(
    widgetState.config.selectedPage?.id.toString()
  );
  const [pickerAnchorEl, setPickerAnchorEl] = useState<HTMLDivElement | null>(
    null
  );
  const pickerOpen = Boolean(pickerAnchorEl);
  const pickerId = pickerOpen ? 'simple-popover' : undefined;

  useEffect(() => {
    if (linkType === 'None') {
      onChange({
        ...value,
        url: '',
        newTab: false,
        linkType: 'None',
        iconColor: 'black',
        selectedPage: null,
      });
      setPageValue('');
    }
  }, [linkType]);

  useEffect(() => {
    setAltText(widgetState.config.altText);
    setPageValue(value.selectedPage?.id.toString());
    setLinkType(widgetState.config.linkType);
  }, [widgetState.config]);

  const updateWidgetSizeLoction = (sizeLoc: SizeLocationData) => {
    onChange({
      ...value,
      width: sizeLoc.width,
      height: sizeLoc.height,
      xLocation: sizeLoc.xLocation,
      yLocation: sizeLoc.yLocation,
      uniqueId: sizeLoc.uniqueId,
    });
  };
  const handleBackgroundImage = (data: Image) => {
    onChange({
      ...value,
      backgroundImage: data,
    });
  };
  const updatePaddingData = (data: PaddingData) => {
    onChange({
      ...value,
      paddingData: data,
    });
  };

  const updateBorderData = (data: BorderData) => {
    onChange({
      ...value,
      borderData: data,
    });
  };
  const handleLineColor = data => {
    onChange({
      ...value,
      lineColor: data,
    });
  };
  const handleFullScreen = (data: boolean) => {
    onChange({
      ...value,
      enableFullScreen: data,
    });
  };
  const handleAltTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAltText(e.target.value);
    onChange({
      ...value,
      altText: e.target.value,
    });
  };

  const setValue = e => {
    setLinkType(e.target.value);
    onChange({
      ...value,
      url: '',
      selectedPage: null,
      linkType: e.target.value,
    });
  };

  const handleEmailSubject = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      emailSubject: e.target.value,
    });
  };

  const handleEmailAddress = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      emailAddress: e.target.value,
    });
  };

  const handlePage = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    if (
      e.target.value &&
      pages.find(page => page.id === parseInt(e.target.value))
    ) {
      onChange({
        ...value,
        selectedPage: pages.find(page => page.id === parseInt(e.target.value)),
        url: `https://${props.site.domain}/${
          pages.find(page => page.id === parseInt(e.target.value)).current.slug
        }`,
      });
    }
  };

  const handleUrl = (e: ChangeEvent<HTMLInputElement>) => {
    if (linkType === 'URL') {
      onChange({
        ...value,
        url: e.target.value,
      });
    }
  };

  const handleUpload = (file: UploadedFile) => {
    onChange({
      ...value,
      url: file.url,
      uploadedFile: file,
    });
  };

  const handleNewTabCheck = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      newTab: e.target.checked,
    });
  };

  const handlePickerClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handlePickerClose = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setPickerAnchorEl(null);
  };

  const handleIconColor = data => {
    onChange({
      ...value,
      iconColor: data,
    });
  };

  const handleColorChange = (newColor: string) => {
    if (newColor.startsWith('linear') || newColor.startsWith('radial')) {
      toast('Gradient cannot be used for icon color', {
        type: 'warning',
        theme: 'colored',
      });
    } else {
      handleIconColor(newColor);
    }
  };

  return (
    <>
      <Panel.Group>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>General</Label>}
            icon={Arrow}
            data-test-id="general-image-panel"
          >
            <ImageUpload
              backgroundImage={handleBackgroundImage}
              widgetState={props.widgetState}
              data-test-id="image-upload-component"
            />
            <EnableFullScreenWidth
              handleEnableFullScreen={handleFullScreen}
              enableFullScreenWidth={widgetState.config.enableFullScreen}
            />
            <AltText>
              <StyledLabel>Alt Text</StyledLabel>
              <StyledTextInput
                data-test-id="alt-text-input"
                value={altText ? altText : ''}
                onChange={handleAltTextChange}
              />
            </AltText>
            <LinkOptionsWrapper>
              <MainLabelStyles data-test-id="link-actions-label">
                What do you want to link to?
              </MainLabelStyles>
              <StyledSelect
                onChange={e => setValue(e)}
                value={linkType}
                data-test-id="link-options-dropdown"
                style={{ fontFamily: 'OptumSans' }}
              >
                {defaultLinkOptions.map(option => {
                  return (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  );
                })}
              </StyledSelect>
            </LinkOptionsWrapper>
            {linkType === 'Email' && (
              <>
                <StyledTextInput
                  name="emailAddress"
                  value={widgetState.config.emailAddress}
                  onChange={handleEmailAddress}
                  placeholder="Type email address here"
                  data-test-id="email-address-input"
                />

                <StyledTextInput
                  name="emailSubject"
                  value={widgetState.config.emailSubject}
                  onChange={handleEmailSubject}
                  placeholder="Type email subject line here"
                  style={{ marginTop: '10px' }}
                  data-test-id="email-subject-input"
                />
              </>
            )}
            {linkType === 'Page' && (
              <StyledFormControl id={'controlled-select'}>
                <StyledSelect
                  onChange={handlePage}
                  value={pageValue}
                  data-test-id="page-select-dropdown"
                  style={{ fontFamily: 'OptumSans' }}
                >
                  <option value="none">Select a Page</option>
                  {pages.map(
                    page =>
                      page.current.slug !== 'defaultContentPage' && (
                        <option key={page.id} value={page.id}>
                          {page.current.title}
                        </option>
                      )
                  )}
                </StyledSelect>
              </StyledFormControl>
            )}
            {linkType === 'URL' && (
              <StyledTextInput
                name="webaddress"
                value={widgetState.config.url}
                onChange={handleUrl}
                placeholder="http:// or https://"
                data-test-id="web-address-input"
              />
            )}
            {linkType === 'File' && (
              <S3UploadFile
                label={'File (.pdf, .doc, .docx format only)'}
                onUpload={handleUpload}
                file={widgetState.config.uploadedFile}
                accept={VALID_UPLOAD_FILES}
                icon={'Upload'}
                iconProps={{
                  width: '1.2em',
                  height: '1.3em',
                  viewBox: '0 0 24 24',
                }}
                fileFormatRegex={PDF_AND_WORD_REGEX}
                data-test-id={`file-uploader`}
              />
            )}
            {(linkType === 'Page' ||
              linkType === 'URL' ||
              linkType === 'File') && (
              <>
                <StyledOuterDiv>
                  <StyledForm id="webaddress-checkbox">
                    <StyledCheckBox
                      checked={widgetState.config.newTab}
                      onChange={handleNewTabCheck}
                    >
                      Open in new tab
                    </StyledCheckBox>
                  </StyledForm>
                  <StyledPickerPopover
                    data-test-id="widget-color-picker-popover"
                    id={pickerId}
                    open={pickerOpen}
                    onClick={handlePickerClick}
                    onClose={handlePickerClose}
                    anchorEl={pickerAnchorEl}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                  >
                    <StyledPickerWrapper>
                      <StyledPickerHeader data-test-id="styled-picker-header">
                        Color Selector
                      </StyledPickerHeader>
                      <StyledClose
                        data-test-id="close-icon"
                        onClick={handlePickerClose}
                      />
                    </StyledPickerWrapper>
                    <GradientColorPicker
                      value={widgetState.config.iconColor}
                      onChange={handleColorChange}
                      site={props.site}
                      handleColorChange={handleColorChange}
                      updateSite={props.updateSite}
                      saveSite={props.saveSite}
                      anchorEl={pickerAnchorEl}
                    />
                  </StyledPickerPopover>
                </StyledOuterDiv>
              </>
            )}
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <SizeLocationPanel
            title={<Label style={StyledPanelTitle}>Size and Location</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="sizeandlocation-panel"
          >
            <WidgetSizeLocation
              data-test-id="widget-size-location"
              sizeLocation={{
                xLocation: value.xLocation,
                yLocation: value.yLocation,
                width: value.width,
                height: value.height,
                uniqueId: value.uniqueId,
              }}
              updateWidgetSizeLoction={updateWidgetSizeLoction}
              activeWidgetId={widgetState.id}
            />
          </SizeLocationPanel>
        </StyledPanel>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>Border</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="border-panel"
          >
            <Border
              data-test-id="border-component"
              borderData={updateBorderData}
              handleLineColor={handleLineColor}
              widgetState={widgetState}
              pages={props.pages}
              site={props.site}
              updateSite={props.updateSite}
              saveSite={props.saveSite}
            />
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>Padding</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="padding-panel"
          >
            <Padding
              paddingData={updatePaddingData}
              widgetState={widgetState}
              data-test-id="padding-component"
            />
          </Panel>
        </StyledPanel>
      </Panel.Group>
    </>
  );
};

export default ImageWidgetControl;
