import { useEffect, useState } from 'react';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { ClickableLinkPlugin } from '@lexical/react/LexicalClickableLinkPlugin';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import { useLexicalEditable } from '@lexical/react/useLexicalEditable';

import { CAN_USE_DOM } from './utils/canUseDOM';

import { useSharedHistoryContext } from './context/SharedHistoryContext';
import ActionsPlugin from './plugins/ActionsPlugin';
import ComponentPickerPlugin from './plugins/ComponentPickerPlugin';
import CustomOnChangePlugin from './plugins/CustomOnChangePlugin';
import DragDropPaste from './plugins/DragDropPastePlugin';
import DraggableBlockPlugin from './plugins/DraggableBlockPlugin';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
import FloatingTextFormatToolbarPlugin from './plugins/FloatingTextFormatToolbarPlugin';
// Disabling Image and Inline Image insertion for v1 of Rich Text EditorImplementation
// import ImagesPlugin from './plugins/ImagesPlugin';
// import InlineImagePlugin from './plugins/InlineImagePlugin';
// Disabling Layout Columns for v1 of Rich Text Editor Implementation
// import { LayoutPlugin } from './plugins/LayoutPlugin/LayoutPlugin';
import LinkPlugin from './plugins/LinkPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import MarkdownShortcutPlugin from './plugins/MarkdownShortcutPlugin';
import TabFocusPlugin from './plugins/TabFocusPlugin';
import TableCellActionMenuPlugin from './plugins/TableActionMenuPlugin';
import TableCellResizer from './plugins/TableCellResizer';
import TableHoverActionsPlugin from './plugins/TableHoverActionsPlugin';
import TableOfContentsPlugin from './plugins/TableOfContentsPlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import AdmonitionPlugin from './plugins/AdmonitionPlugin';
import ContentEditable from './ui/ContentEditable';

import './index.css';
import { useSettings } from './context/SettingsContext';

export default function EditorUI({
  value,
  onChange,
  editable = true,
  handleEditingRichText,
  isEditingRichText = true,
}): JSX.Element {
  const { historyState } = useSharedHistoryContext();
  const {
    settings: {
      hasLinkAttributes,
      isRichText,
      showTableOfContents,
      shouldPreserveNewLinesInMarkdown,
      tableCellMerge,
      tableCellBackgroundColor,
    },
  } = useSettings();
  const isEditable = useLexicalEditable();
  const placeholder = isEditingRichText
    ? 'Enter text...'
    : 'Click the edit icon below to enter text...';

  const [
    floatingAnchorElem,
    setFloatingAnchorElem,
  ] = useState<HTMLDivElement | null>(null);
  const [isSmallWidthViewport, setIsSmallWidthViewport] = useState<boolean>(
    false
  );
  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  useEffect(() => {
    const updateViewPortWidth = () => {
      const isNextSmallWidthViewport =
        CAN_USE_DOM && window.matchMedia('(max-width: 1025px)').matches;

      if (isNextSmallWidthViewport !== isSmallWidthViewport) {
        setIsSmallWidthViewport(isNextSmallWidthViewport);
      }
    };
    updateViewPortWidth();
    window.addEventListener('resize', updateViewPortWidth);

    return () => {
      window.removeEventListener('resize', updateViewPortWidth);
    };
  }, [isSmallWidthViewport]);

  return (
    <>
      {isEditingRichText && editable && (
        <span data-test-id="rich-text-toolbar">
          <ToolbarPlugin setIsLinkEditMode={setIsLinkEditMode} />
        </span>
      )}
      <div className="editor-container">
        <DragDropPaste />
        <AutoFocusPlugin />
        <ClearEditorPlugin />
        <ComponentPickerPlugin />
        <>
          <HistoryPlugin externalHistoryState={historyState} />
          <RichTextPlugin
            contentEditable={
              <div
                className="editor-scroller"
                style={{ resize: editable ? 'vertical' : 'none' }}
              >
                <div className="editor" ref={onRef}>
                  <ContentEditable placeholder={placeholder} />
                </div>
              </div>
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <MarkdownShortcutPlugin />
          <ListPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <TablePlugin
            hasCellMerge={tableCellMerge}
            hasCellBackgroundColor={tableCellBackgroundColor}
          />
          <TableCellResizer />
          {/* Todo: Disabling Image and Inline Image insertion for v1 of Rich Text Editor Implementation*/}
          {/*<ImagesPlugin />*/}
          {/*<InlineImagePlugin />*/}
          <LinkPlugin hasLinkAttributes={hasLinkAttributes} />
          <ClickableLinkPlugin disabled={isEditable} />
          <HorizontalRulePlugin />
          <TabFocusPlugin />
          <TabIndentationPlugin />
          {/* Todo: Disabling Layout Columns for v1 of Rich Text Editor Implementation*/}
          {/*<LayoutPlugin />*/}
          <AdmonitionPlugin />
          {/*CustomOnChangePlugin serializes the Lexical editor state to an HTML string.*/}
          <CustomOnChangePlugin value={value} onChange={onChange} />
          {floatingAnchorElem && !isSmallWidthViewport && (
            <>
              <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
              <FloatingLinkEditorPlugin
                anchorElem={floatingAnchorElem}
                isLinkEditMode={isLinkEditMode}
                setIsLinkEditMode={setIsLinkEditMode}
              />
              <TableCellActionMenuPlugin
                anchorElem={floatingAnchorElem}
                cellMerge={true}
              />
              <TableHoverActionsPlugin anchorElem={floatingAnchorElem} />
              <FloatingTextFormatToolbarPlugin
                anchorElem={floatingAnchorElem}
                setIsLinkEditMode={setIsLinkEditMode}
              />
            </>
          )}
        </>
        <div>{showTableOfContents && <TableOfContentsPlugin />}</div>
        {editable && (
          <ActionsPlugin
            isRichText={isRichText}
            shouldPreserveNewLinesInMarkdown={shouldPreserveNewLinesInMarkdown}
            handleEditingRichText={handleEditingRichText}
            isEditingRichText={isEditingRichText}
          />
        )}
      </div>
    </>
  );
}
