/*
The MyAppsControl.tsx file is a React component that provides a control panel for managing application settings in a user interface. It uses various UI components such as Panels, Labels, TextAreas, Checkboxes, TextInputs, and Selects from the '@uitk/react' library, and styled-components for CSS in JS styling.
The component, MyAppsControlDummy, receives a props object of type WidgetControlProps<Config>. It uses React's useState and useEffect hooks for managing and updating state based on the props received.
The state variables include errorText, error, and cardStyle. The useEffect hook is used to update the cardStyle state whenever widgetState.config changes.
The component defines several handler functions for updating different aspects of the widget's configuration, such as updateWidgetSizeLoction, updatePaddingData, updateBorderData, handleLineColor, and others. These functions are used to handle changes in the UI and update the widget's configuration accordingly.
The component also includes a handleManageAppsClick function which opens a new window with a specific URL when the "Manage Applications" link is clicked.
The return statement of the component renders a UI with various controls for managing application settings, including a Select for choosing the card style, a TextArea for entering a message to be displayed when no apps are provisioned, a Checkbox for enabling the app environment, and a TextInput for entering app environments.
*/

import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Panel,
  Label,
  TextArea,
  Checkbox,
  TextInput,
  Select,
} from '@uitk/react';
import { Arrow } from 'builder/scenes/SiteBuilder/SettingsPanel/SettingsPanel';
import Scrollbars from 'react-custom-scrollbars';
import {
  SizeLocationData,
  WidgetSizeLocation,
} from '../../../builder/components/WidgetSizeLocation';
import { Config } from './types';

import {
  Padding,
  PaddingData,
} from 'builder/components/WidgetConfigurations/Padding';
import {
  Border,
  BorderData,
} from 'builder/components/WidgetConfigurations/Border';
import { StyledLabel } from 'builder/components/WidgetConfigurations/TextInput/TextInputPanel';
import { StyledFormControl } from '../Form/TextInput/TextInputWidget';
import { cardStyleOptions } from 'builder/util/constants';
import { Link } from 'link-ui-react';
import {
  StelliumData,
  stelliumRedirectionUrl,
} from 'shared/util/stelliumRedirection';

const StyledPanelTitle = {
  'font-family': 'OptumSans',
  'font-size': '14px',
  color: '#1d1929',
  'font-weight': '700',
  'margin-left': '0.8em',
};

const PanelTop = styled.div`
  .cGdfIs {
    padding: 0rem 2rem 3.5rem 1.5rem;
  }
`;
const labelStyles = {
  color: '#1d1929',
  'font-size': '12px',
  'font-family': 'OptumSans',
  'padding-top': '20px',
  'font-weight': '500',
};

const StyledPanel = styled.div`
  & button[class^='Panelcomponent__HeaderSection'] {
    height: 2.5em;
    background: rgba(141, 145, 160, 0.07);
    border-radius: 0;
    &: hover,&: focus {
      box-shadow: none;
    }
  }
  & span[class^='Panelcomponent__HeaderMainContent'] {
    height: 2.5em;
  }
  & div[class^='uitk-panel Panelcomponent__Panel'] {
    margin-bottom: 0rem;
    border: none;
  }
  & div[class^='Panelcomponent__Content'] {
    padding-top: 0rem;
  }
`;

const SizeLocationPanel = styled(Panel)`
  > div {
    padding: 1rem;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 1.25rem;
  margin-left: -0.55rem;
`;

const CustomLink = styled(Link)`
  font-family: OptumSans, sans-serif;
  margin: 1rem 0rem -1rem -0.25rem;
  font-size: 2rem;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px #888888;
  color: ${p => p.theme.colors.aux.darkBlue};
  &:hover {
    color: ${p => p.theme.colors.aux.darkBlue};
  }
`;

const StyledTextArea = styled(TextArea)`
  color: #797979;
  height: 5em;
  font-family: OptumSans, sans-serif;
`;

const StyledTextInput = styled(TextInput)`
  width: 100%;
  font-family: OptumSans, sans-serif;
`;

const MyAppsControlDummy: React.FC<WidgetControlProps<Config>> = (
  props: WidgetControlProps<Config>
) => {
  const { value, onChange, widgetState, site } = props;
  const [errorText, setErrorText] = useState(' ');
  const [error, setError] = useState(false);
  const [cardStyle, setCardStyle] = useState(
    widgetState.config.cardStyle ? widgetState.config.cardStyle : 'Spacious'
  );

  useEffect(() => {
    setCardStyle(widgetState.config.cardStyle);
  }, [widgetState.config]);

  const updateWidgetSizeLoction = (sizeLoc: SizeLocationData) => {
    onChange({
      ...value,
      width: sizeLoc.width,
      height: sizeLoc.height,
      xLocation: sizeLoc.xLocation,
      yLocation: sizeLoc.yLocation,
      uniqueId: sizeLoc.uniqueId,
    });
  };
  const updatePaddingData = (data: PaddingData) => {
    onChange({
      ...value,
      paddingData: data,
    });
  };

  const updateBorderData = (data: BorderData) => {
    onChange({
      ...value,
      borderData: data,
    });
  };
  const handleLineColor = data => {
    onChange({
      ...value,
      lineColor: data,
    });
  };
  let noAppsMessage = props.widgetState.config.noAppsMessage;
  let enableAppEnvironment = props.widgetState.config.enableAppEnvironment;
  let enableRegister = props.widgetState.config.enableRegister;
  const handleNoAppsMessageChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    noAppsMessage = e.target.value;
  };
  const setEnableAppEnvironment = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, onChange } = props;
    onChange({
      ...value,
      enableAppEnvironment: e.currentTarget.checked,
    });
  };
  const setAppsEnvironmentList = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, onChange } = props;
    const regex = /^[\w\s]+(,[\w\s]+)*$/;
    if (regex.test(e.target.value)) {
      setErrorText('');
      setError(false);
    } else {
      e.target.value.length === 0
        ? setErrorText('Value is required')
        : setErrorText('Invalid format');
      setError(true);
    }
    onChange({
      ...value,
      appsEnvironmentList: e.target.value,
    });
  };
  const setEnableRegister = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, onChange } = props;
    onChange({
      ...value,
      enableRegister: e.currentTarget.checked,
    });
  };
  const setBusinessKey = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, onChange } = props;
    onChange({
      ...value,
      appRegistration: {
        ...value.appRegistration,
        businessKey: e.target.value,
      },
    });
  };
  const setResumeRegisterMessage = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, onChange } = props;
    onChange({
      ...value,
      appRegistration: {
        ...value.appRegistration,
        resumeRegisterMessage: e.target.value,
      },
    });
  };
  const setRegisterMessage = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, onChange } = props;
    onChange({
      ...value,
      appRegistration: {
        ...value.appRegistration,
        registerMessage: e.target.value,
      },
    });
  };
  const setResumeRegisterUrl = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, onChange } = props;
    onChange({
      ...value,
      appRegistration: {
        ...value.appRegistration,
        resumeRegisterUrl: e.target.value,
      },
    });
  };
  const setRegisterUrl = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, onChange } = props;
    onChange({
      ...value,
      appRegistration: {
        ...value.appRegistration,
        registerUrl: e.target.value,
      },
    });
  };

  const handleCardStyleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value, onChange } = props;
    onChange({
      ...value,
      cardStyle: e.target.value,
    });
  };

  const handleManageAppsClick = () => {
    const stelliumSessionData: StelliumData = {
      siteIds: [site.id],
      tenantId: site.tenantId,
      askId: site.aideId,
    };
    window.open(stelliumRedirectionUrl(stelliumSessionData), '_blank');
  };

  return (
    <div>
      <Scrollbars autoHeight autoHeightMax={800}>
        <Panel.Group>
          <StyledPanel>
            <Panel
              title={<Label style={StyledPanelTitle}>General</Label>}
              icon={Arrow}
              data-test-id="background-panel"
            >
              <CustomLink underline onClick={handleManageAppsClick}>
                Manage Applications
              </CustomLink>
              <PanelTop>
                <Label style={labelStyles}>Card Style</Label>
                <Select
                  data-test-id="card-style"
                  value={cardStyle}
                  onChange={handleCardStyleChange}
                  style={{ fontFamily: 'OptumSans' }}
                >
                  {cardStyleOptions.map(b => {
                    return (
                      <option value={b} key={b}>
                        {b}
                      </option>
                    );
                  })}
                </Select>
              </PanelTop>
              <PanelTop>
                <Label style={labelStyles}>No Apps Provisioned Message</Label>
              </PanelTop>
              <StyledTextArea
                data-test-id="tooltip"
                placeholder={
                  'You are not authorized to access applications on this site. Please contact your administrator to get access.'
                }
                value={noAppsMessage}
                onChange={handleNoAppsMessageChange}
              />
              <StyledFormControl id="button-checkbox">
                <StyledLabel style={labelStyles}>
                  Enable App Environment
                </StyledLabel>
                <StyledCheckbox
                  data-test-id="divider-check"
                  checked={enableAppEnvironment}
                  onChange={setEnableAppEnvironment}
                />
              </StyledFormControl>
              {enableAppEnvironment && (
                <StyledFormControl id="app-environment">
                  <StyledLabel style={labelStyles}>
                    App Environments (add comma-separated values)
                  </StyledLabel>
                  <StyledTextInput
                    placeholder="Environments go here"
                    value={props.widgetState.config.appsEnvironmentList}
                    onChange={setAppsEnvironmentList}
                    error={error}
                    helperText={errorText}
                  />
                </StyledFormControl>
              )}
              <StyledFormControl id="enable-self">
                <StyledLabel style={labelStyles}>
                  Enable Self Registration
                </StyledLabel>
                <StyledCheckbox
                  data-test-id="divider-check-reg"
                  checked={enableRegister}
                  onChange={setEnableRegister}
                />
              </StyledFormControl>
              {enableRegister && (
                <>
                  <StyledLabel style={labelStyles}>
                    Self Registration Application Business Key
                  </StyledLabel>
                  <StyledTextInput
                    data-test-id="environments"
                    placeholder="Business key goes here"
                    value={props.appRegistration?.businessKey}
                    onChange={setBusinessKey}
                  />
                  <StyledLabel style={labelStyles}>
                    Registration URL
                  </StyledLabel>
                  <StyledTextInput
                    data-test-id="environments"
                    placeholder="URL goes here"
                    value={props.appRegistration?.registerUrl}
                    onChange={setRegisterUrl}
                  />
                  <StyledLabel style={labelStyles}>
                    Register Now Message
                  </StyledLabel>
                  <StyledTextInput
                    data-test-id="registerMessage"
                    placeholder="Message goes here"
                    value={props.appRegistration?.registerMessage}
                    onChange={setRegisterMessage}
                  />
                  <StyledLabel style={labelStyles}>
                    Resume Registration URL
                  </StyledLabel>
                  <StyledTextInput
                    data-test-id="ResumeregisterMessage"
                    placeholder="URL goes here"
                    value={props.appRegistration?.resumeRegisterUrl}
                    onChange={setResumeRegisterUrl}
                  />
                  <StyledLabel style={labelStyles}>
                    Resume Registration Message
                  </StyledLabel>
                  <StyledTextInput
                    data-test-id="ResumeregisterMessage"
                    placeholder="Message goes here"
                    value={props.appRegistration?.resumeRegisterMessage}
                    onChange={setResumeRegisterMessage}
                  />
                </>
              )}
            </Panel>
          </StyledPanel>
          <StyledPanel>
            <SizeLocationPanel
              title={<Label style={StyledPanelTitle}>Size and Location</Label>}
              initiallyClosed
              icon={Arrow}
              data-test-id="sizeandlocation-panel"
            >
              <WidgetSizeLocation
                data-test-id="widget-size-location"
                sizeLocation={{
                  xLocation: value.xLocation,
                  yLocation: value.yLocation,
                  width: value.width,
                  height: value.height,
                  uniqueId: value.uniqueId,
                }}
                updateWidgetSizeLoction={updateWidgetSizeLoction}
                activeWidgetId={widgetState.id}
              />
            </SizeLocationPanel>
          </StyledPanel>
          <StyledPanel>
            <Panel
              title={<Label style={StyledPanelTitle}>Border</Label>}
              initiallyClosed
              icon={Arrow}
              data-test-id="border-panel"
            >
              <Border
                data-test-id="border-component"
                borderData={updateBorderData}
                handleLineColor={handleLineColor}
                widgetState={widgetState}
                pages={props.pages}
                site={props.site}
                updateSite={props.updateSite}
                saveSite={props.saveSite}
              />
            </Panel>
          </StyledPanel>
          <StyledPanel>
            <Panel
              title={<Label style={StyledPanelTitle}>Padding</Label>}
              initiallyClosed
              icon={Arrow}
              data-test-id="padding-panel"
            >
              <Padding
                paddingData={updatePaddingData}
                widgetState={widgetState}
                data-test-id="padding-component"
              />
            </Panel>
          </StyledPanel>
        </Panel.Group>
      </Scrollbars>
    </div>
  );
};

export default MyAppsControlDummy;
